import React from "react";
import { connect } from 'react-redux';
import { api_url } from '../config';
import cookie from 'react-cookies';
import { Link } from "react-router-dom";
import '../css/stamp_page.css';
//import { Container, Row, Col } from 'react-bootstrap';
//import Button from 'react-bootstrap/Button';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Modal from 'react-modal';
import Menu from '../components/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy,faAward } from '@fortawesome/free-solid-svg-icons';

class PasscodeCookie extends React.Component {

	constructor(props) {
		
		const loginmode_sess = localStorage.getItem('loginmode');
		var loginmode;
		if(loginmode_sess === null){
			loginmode = 'no';
			localStorage.setItem('loginmode','no');
		}else{
			loginmode = loginmode_sess;
		}	
		
		super(props);
		console.log(props);
		const store_id = this.props.match.params.store_id;
		const prod_id = this.props.match.params.prod_id;
		//const storename = this.props.match.params.storename;
		this.props.updateRetarget(store_id, 'reward');
		//this.props.logincheck('islogin');
		
		this.state = {
			store_id: store_id,
			prod_id: prod_id,
			stamp_select_cnt: '',
			tcolor: '#ccc',
			total_points: '',
			stamp_end_cnt: '',
			earned_pts: 0,
			pageview: 'login',
			loginmode:loginmode,
			showAnim: '',
			smsg: '',
			cont_loader: 0,
			active_menu: 'home',
			
			fields: {
				name: '',
				email: '',
				phone: '',
				gdpr_check_mode: '',
				loginemail: '',
				loginpassword: '',
				regispassword: '',
				regisconfpassword: '',
				forgotemail: '',
				reset_email: '',
				reset_password: '',
				reset_confpassword: '',
			},
			errors: {
				name: '',
				email: '',
				phone: '',
				gdpr_check_mode: '',
				loginemail: '',
				loginpassword: '',
				regispassword: '',
				regisconfpassword: '',
				forgotemail: '',
				reset_email: '',
				reset_password: '',
				reset_confpassword: '',
			},
			stampdata_display: '',
			store_det: '',
			modalIsOpen: false,
			setIsOpen: false,
			modaltext: '',
			modaltitle: '',
			gdpr_status: 0,
			gdpr_validity: 0,
			error_msg: '',
			referlink: '',
			viral_share: 0,

		}
		
		//alert(this.state.loginmode)
		this.loadStampPage = this.loadStampPage.bind(this);
		this.phoneChange = this.phoneChange.bind(this);
		this.copyToClipboard = this.copyToClipboard.bind(this);
		this.inputChangedHandler = this.inputChangedHandler.bind(this);
		
		//  alert(this.props.match.params.store_id);

	}
	phoneChange(phone_number) {
		let fields = { ...this.state.fields };
		fields['phone'] = phone_number;
		this.setState({ fields: fields });
	}
	handleChange1(field, e) {
		
		let fields = { ...this.state.fields };
		let errors = this.state.errors;
		//console.log(e.target.checked);
		if(field === 'gdpr_check_mode'){
			let chkval = e.target.checked
			if(chkval){
				fields[field] = e.target.value;
				errors[field] = '';
			}else{
				fields[field] = '';
				errors[field] = '';
			}
			
		}else{
			fields[field] = e.target.value;
			errors[field] = '';
		}
		
		this.setState({ fields: fields });
		this.setState({ errors });

	}
	inputChangedHandler(e) {
		
	}
	copyToClipboard(e){
		this.textArea.select();
		document.execCommand('copy');
		e.target.focus();
		//this.setState({ copySuccess: 'Copied!' });
    }
	forgotValidation() {
		let fields = { ...this.state.fields };
		let errors = {};
		let formIsValid = true;
		if (!fields["forgotemail"]) {
			formIsValid = false;

		}
		if (typeof fields["forgotemail"] !== "undefined") {
			let lastAtPos = fields["forgotemail"].lastIndexOf('@');
			let lastDotPos = fields["forgotemail"].lastIndexOf('.');

			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["forgotemail"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["forgotemail"].length - lastDotPos) > 2)) {
				formIsValid = false;
				errors["forgotemail"] = 1;
			}
		}
		this.setState({ errors: errors });
		return formIsValid;

	}
	loginValidation() {
		let fields = { ...this.state.fields };
		let errors = {};
		let formIsValid = true;
		if (!fields["loginemail"]) {
			formIsValid = false;

		}
		if (typeof fields["loginemail"] !== "undefined") {
			let lastAtPos = fields["loginemail"].lastIndexOf('@');
			let lastDotPos = fields["loginemail"].lastIndexOf('.');

			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["loginemail"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["loginemail"].length - lastDotPos) > 2)) {
				formIsValid = false;
				errors["loginemail"] = 1;
			}
		}
		
		if (fields["loginpassword"] === '' || typeof fields["loginpassword"] === "undefined") {
			formIsValid = false;
			errors["loginpassword"] = 1;
		} 
		console.log(errors);
		this.setState({ errors: errors });
		return formIsValid;

	}
	resetpwdValidation() {
		let fields = { ...this.state.fields };
		let errors = {};
		let formIsValid = true;
		//console.log(fields["email"]);
		if (!fields["reset_email"]) {
			formIsValid = false;

		}
		
		if (typeof fields["reset_email"] !== "undefined") {
			let lastAtPos = fields["reset_email"].lastIndexOf('@');
			let lastDotPos = fields["reset_email"].lastIndexOf('.');

			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["reset_email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["reset_email"].length - lastDotPos) > 2)) {
				formIsValid = false;
				//errors["email"] = "Email is not valid";
				errors["reset_email"] = 1;
			}
		}
		
		
		if (fields["reset_password"] === '' || typeof fields["reset_password"] === "undefined") {
				formIsValid = false;
				errors["reset_password"] = 1;
		} 
		if (fields["reset_confpassword"] === '' || typeof fields["reset_confpassword"] === "undefined") {
				formIsValid = false;
				errors["reset_confpassword"] = 1;
		}
		if (fields["reset_password"] !== '' &&  fields["reset_confpassword"] !== '') {
			   if(fields["reset_password"] !== fields["reset_confpassword"]){
					formIsValid = false;
					errors["reset_confpassword"] = 1;
			   }
		}

		

		this.setState({ errors: errors });
		return formIsValid;

	}
	handleValidation() {
		let fields = { ...this.state.fields };
		let errors = {};
		let formIsValid = true;
		//console.log(fields["email"]);
		if (!fields["email"]) {
			formIsValid = false;

		}
		if (fields["name"] === '' || typeof fields["name"] === "undefined") {
			formIsValid = false;
			errors["name"] = 1;
		}
		if (typeof fields["email"] !== "undefined") {
			let lastAtPos = fields["email"].lastIndexOf('@');
			let lastDotPos = fields["email"].lastIndexOf('.');

			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
				formIsValid = false;
				//errors["email"] = "Email is not valid";
				errors["email"] = 1;
			}
		}
		if(this.state.store_det.phone_mandatory==='1'){
			if (fields["phone"] === '' || typeof fields["phone"] === "undefined") {
				formIsValid = false;
				errors["phone"] = 1;
			} 
		}
		
		if (fields["regispassword"] === '' || typeof fields["regispassword"] === "undefined") {
				formIsValid = false;
				errors["regispassword"] = 1;
		} 
		if (fields["regisconfpassword"] === '' || typeof fields["regisconfpassword"] === "undefined") {
				formIsValid = false;
				errors["regisconfpassword"] = 1;
		}
		if (fields["regispassword"] !== '' &&  fields["regisconfpassword"] !== '') {
			   if(fields["regispassword"] !== fields["regisconfpassword"]){
					formIsValid = false;
					errors["regisconfpassword"] = 1;
			   }
		}

		
		if(this.state.store_det.gdpr_validity===1){
			if (fields["gdpr_check_mode"] === '' || typeof fields["gdpr_check_mode"] === "undefined") {
				formIsValid = false;
				errors["gdpr_check_mode"] = 1;
			} 
		}
		this.setState({ errors: errors });
		return formIsValid;

	}
	componentDidMount() {
		const query = new URLSearchParams(this.props.location.search);
		const token = query.get('token');

		if(token !== null){
			this.setState({ pageview: 'resetpwd'});
		}else{
			localStorage.setItem("login_url", window.location.href);
		}
		
		const store_id = this.props.match.params.store_id;
		const prod_id = this.props.match.params.prod_id;
		const rkey = this.props.match.params.rkey;
		const memberCookie = cookie.load('memberCookie_' + store_id);
		
		localStorage.setItem('store_id', store_id);
		localStorage.setItem('prod_id', prod_id);
		if (memberCookie !== '' && typeof memberCookie !== 'undefined' && this.state.loginmode === 'yes') {
			
			this.props.CookieUpdate('memberCookie', memberCookie);
			const postdata = new FormData();
			postdata.append("fk_member_id", memberCookie);
			postdata.append("fk_store_id", store_id);
			postdata.append("prod_id", prod_id);
			postdata.append("rkey", rkey);
			
			
			fetch(api_url + "/api/auto_pts_update", {
				method: "POST",
				body: postdata
			})
				.then(res => res.json())
				.then(
					(result) => {
						const fk_member_id = result.fk_member_id;
						localStorage.setItem("fk_member_id", fk_member_id);
						if (result.earned_pts !== 0) {
							this.showanimation(result.earned_pts);
						}

						this.CookieSet('memberCookie_' + store_id, fk_member_id, 'memberCookie', 8760);
						this.loadStampPage(fk_member_id);
						localStorage.setItem('fk_member_id', fk_member_id);

					},
					(error) => { }

				)



		} else {
			this.loadStampPage('');
			//document.body.style.backgroundColor = "#0000007F";
		}
		
	}
	loadStampPage(fk_member_id) {
		/* this.displayData.push(<div  id="display-data"><span>qwrr</span></div>);
		this.displayData.push(<div  id="display-data"><span>qwrr</span></div>);
		this.setState({
		   showdata : this.displayData,
		}); */
	

		var login_url = localStorage.getItem("login_url");
		const store_id = this.props.match.params.store_id;
		const prod_id = this.props.match.params.prod_id;
		let visitCookierew = cookie.load('visitCookierew_' + store_id);
		if (visitCookierew === '' || typeof visitCookierew === 'undefined') {
			this.CookieSet('visitCookierew_' + store_id, 1, 'visitCookierew', 24);
			visitCookierew = 1;
		} else {
			visitCookierew = '';
		}


		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("fk_member_id", fk_member_id);
		postdata.append("prod_id", prod_id);
		postdata.append("visitcookierew", visitCookierew);
		postdata.append("login_url", login_url);
		fetch(api_url + "/api/loadstamppage", {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
						
					//this.stampdisplayData.push(<div  id="display-data"><span>qwrr</span></div>);
					//this.setState({ stampdata : this.stampdisplayData });
					const themecolor = this.props.themecolor;
					if(result.status === 200){
						if(this.state.loginmode === 'yes'){
							//const mid_hash = result.mid_hash;
							const ref_link = result.ref_link;
							const referlink = ref_link;
							this.setState({ 
									pageview: 'earnpoints',
									referlink: referlink,
									viral_share: result.viral_share,
							});
							
							document.body.style.backgroundColor = themecolor.body_color
						}else{
							document.body.style.backgroundColor = themecolor.login_body_color
						}
						if (fk_member_id !== '' && typeof fk_member_id !== 'undefined') {
						
							//const stamp_end_cnt = result.stamp_end_cnt;
							const stamp_select_cnt = result.stamp_select_cnt;
							const total_points = result.total_points;
							const thumbs_icon_color = themecolor.thumbs_icon_color;
							const box_bg_col = themecolor.box_bg_col;
							const row_cnt = result.row_cnt;
							var t = '';
							var k = 1;
							t += '<div style="background:' + box_bg_col + '" class="div-points-box">';
							for (var i = 1; i <= row_cnt; i++) {
								t += '<div class="df">';
								for (var j = 1; j <= 4; j++) {

									if (k <= stamp_select_cnt) {
										t += '<div style="background:' + themecolor.thumbs_icon_bg + '" class="div-box active"><i style="color:' + thumbs_icon_color + '" class="fa fa-thumbs-up"></i></div>';
									} else {
										t += '<div style="background:' + themecolor.thumbs_icon_bg+ '" class="div-box"><i style="color:' + thumbs_icon_color + ';opacity:0.3" class="fa fa-thumbs-up"></i></div>';
									}

									k++;
								}
								t += '</div>';
							}
							t += '</div>';
							this.setState({ total_points: total_points, stampdata_display: t, store_det: result });
						} else {
						
							this.setState({ store_det: result});
							if(result.gdpr_status === 1){
								this.setState({ gdpr_status: 1 });
							}	

						}
					}else if(result.status === 401){
						 this.setState({ loginmode: 'no' });
						 localStorage.setItem('loginmode','no');
						 window.top.location.href=login_url;
						 
					}
					
					//alert(this.state.themecolor);
					



					/* for(var i=1;i<=stamp_end_cnt;i++){
						
						if(i <= stamp_select_cnt){
							
							this.stampdisplayData.push(<div key={i} className="div-box active"><i className="fa fa-check"></i></div>);
						}else{
							this.stampdisplayData.push(<div key={i} className="div-box"><i className="fa fa-check"></i></div>);
						}	
					
					}	 */

					//this.stampdisplayData.push(<div><i className="fa fa-check"></i></div>);




				},
				(error) => {

				}

			)

	}


	CookieSet(cookiename, cookievalue, action, exp) {

		const expires = new Date();
		const exp1 = 1000 * 60 * 60 * exp;
		const maxAge = 60 * 60 * exp;
		expires.setDate(Date.now() + exp1);
		cookie.save(cookiename, cookievalue, { path: '/', expires, maxAge: maxAge });
		this.props.CookieUpdate(action, cookievalue);


	}
	showanimation(earn_points) {
		this.setState({ showAnim: 1 });
		
		setTimeout(() => {
			this.setState({ showAnim: '' });
		}, 6000);

	}
	openModal(type) {
		 let mtext;
		 let mtitle;
		 if(type === 'privacy'){
			 mtext =this.state.store_det.privacy_content;
			 mtitle =this.state.store_det.privacy_title;
		 }else if(type === 'terms'){
			 mtext =this.state.store_det.terms_content;
			 mtitle =this.state.store_det.terms_title;
		 }	 
		 this.setState({ 
			modalIsOpen: true,
			modaltext :mtext,
			modaltitle :mtitle
			
		 });
	}
	closeModal() {
		this.setState({ 
			modalIsOpen: false,
			modaltext :''
			
		 });

	}
	loginfn(e){
		e.preventDefault();
		const store_id = this.props.match.params.store_id;
		const prod_id = this.props.match.params.prod_id;
		const rkey = this.props.match.params.rkey;
		if (this.loginValidation()) {
			
			const postdata = new FormData();
			postdata.append("loginemail", this.state.fields.loginemail);
			postdata.append("loginpassword", this.state.fields.loginpassword);
			postdata.append("store_id", this.props.match.params.store_id);
			postdata.append("prod_id", prod_id);
			postdata.append("rkey", rkey);
			this.setState({ cont_loader: 1 });

			fetch(api_url + "/api/logincheck", {
				method: "POST",
				body: postdata
			})
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({ cont_loader: 0 });
					if(result.status === 200){
						const fk_member_id = result.fk_member_id;
						if (result.earned_pts !== 0) {
							
							this.showanimation(result.earned_pts);
						}
						this.CookieSet('memberCookie_'+store_id, fk_member_id, 'memberCookie', 8760);
						localStorage.setItem('loginmode','yes');
						localStorage.setItem("store_id", store_id);
						localStorage.setItem("prod_id", prod_id);
						localStorage.setItem("prod_id", prod_id);
						localStorage.setItem("fk_member_id", fk_member_id);
						
						this.setState({ loginmode: 'yes' });
						
						this.loadStampPage(fk_member_id);
						this.setState({ pageview: 'earnpoints' });
					}else{
						this.setState({ error_msg: result.error_msg });
						
						setTimeout(
							function() {
								this.setState({ error_msg: '' })
							}
							.bind(this),
							3000
						);
					}


				},
				(error) => {
					this.setState({

					});
				}
			)
			
		}	
		
	}
	showpageview(page){
		
		this.setState({ pageview: page });
	}
	resetpassword(e){
		const query = new URLSearchParams(this.props.location.search);
		const token = query.get('token');
		
		e.preventDefault();
		const store_id = this.props.match.params.store_id;
		const postdata = new FormData();
		postdata.append("reset_email", this.state.fields.reset_email);
		postdata.append("reset_password", this.state.fields.reset_password);
		postdata.append("store_id", store_id);
		postdata.append("prod_id", this.props.match.params.prod_id);
		postdata.append("token", token);
		if (this.resetpwdValidation()) {
			this.setState({ cont_loader: 1 });

			fetch(api_url+"/api/resetpassword", {
				method: "POST",
				body: postdata
			})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({ cont_loader: 0 });
						if(result.status === 200){
							this.setState({ cont_loader: 0 });
							this.setState({ smsg: result.smsg });
							
							setTimeout(
								function() {
									this.setState({ smsg: '' });
									 var login_url = localStorage.getItem("login_url");
									 window.top.location.href=login_url;
								}
								.bind(this),
								2000
							);
							
						}else{
							
							this.setState({ error_msg: result.error_msg });
							setTimeout(
								function() {
									this.setState({ error_msg: '' })
									
								}
								.bind(this),
								3000
							);
						}


					},
					(error) => {
						this.setState({

						});
					}
				)
		}
	}
	forgotpassword(e) {
		e.preventDefault();
		const store_id = this.props.match.params.store_id;
		const postdata = new FormData();
		postdata.append("forgotemail", this.state.fields.forgotemail);
		postdata.append("store_id", store_id);
		postdata.append("prod_id", this.props.match.params.prod_id);
		if (this.forgotValidation()) {
			this.setState({ cont_loader: 1 });

			fetch(api_url+"/api/forgot", {
				method: "POST",
				body: postdata
			})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({ cont_loader: 0 });
						if(result.status === 200){
							this.setState({ cont_loader: 0 });
							this.setState({ smsg: result.smsg });
						}else{
							
							this.setState({ error_msg: result.error_msg });
							setTimeout(
								function() {
									this.setState({ error_msg: '' })
								}
								.bind(this),
								5000
							);
						}


					},
					(error) => {
						this.setState({

						});
					}
				)
		}	
	}	
	register(e) {
		e.preventDefault();
		const store_id = this.props.match.params.store_id;
		const prod_id = this.props.match.params.prod_id;
		const rkey = this.props.match.params.rkey;
		const query = new URLSearchParams(this.props.location.search);
		const ref = query.get('ref');
		
		if (this.handleValidation()) {
			const postdata = new FormData();
			postdata.append("email", this.state.fields.email);
			postdata.append("name", this.state.fields.name);
			postdata.append("phone", this.state.fields.phone);
			postdata.append("regispassword", this.state.fields.regispassword);
			postdata.append("store_id", this.props.match.params.store_id);
			postdata.append("prod_id", prod_id);
			postdata.append("rkey", rkey);
			if (ref !== '' && ref !== "undefined" && ref !== null) {
				postdata.append("ref",ref);
			}
			
			if(this.state.gdpr_status === 1){
				let gdpr_check_mode = this.state.fields.gdpr_check_mode;
				postdata.append("gdpr_status", 1);
				if(typeof gdpr_check_mode === 'undefined' || gdpr_check_mode === '') {
					gdpr_check_mode = 0;
				}else{
					gdpr_check_mode = 1;
				}
				postdata.append("gdpr_check_mode", gdpr_check_mode);	
			}	
			
			
			this.setState({ cont_loader: 1 });

			fetch(api_url + "/api/register", {
				method: "POST",
				body: postdata
			})
				.then(res => res.json())
				.then(
					(result) => {
						this.setState({ cont_loader: 0 });
						if(result.status === 200){
							const fk_member_id = result.fk_member_id;
							if (result.earned_pts !== 0) {
								
								this.showanimation(result.earned_pts);
							}
							this.CookieSet('memberCookie_' + store_id, fk_member_id, 'memberCookie', 8760);
							localStorage.setItem("loginmode", 'yes');
							localStorage.setItem("store_id", store_id);
							localStorage.setItem("prod_id", prod_id);
							localStorage.setItem("prod_id", prod_id);
							localStorage.setItem("fk_member_id", fk_member_id);
							
							this.setState({ cont_loader: 0 });
							this.setState({ loginmode: 'yes' });
							this.loadStampPage(fk_member_id);
						}else{
							
							this.setState({ error_msg: result.error_msg });
							setTimeout(
								function() {
									this.setState({ error_msg: '' })
								}
								.bind(this),
								5000
							);
						}


					},
					(error) => {
						this.setState({

						});
					}
				)

		}
	}

	render() {
		const themecolor = this.props.themecolor;
		const langvj = JSON.parse(localStorage.getItem('langv'));
		let langv;
		if(langvj === null || langvj === '' || typeof langvj === 'undefined'){
			langv = this.props.langv;
		}else{
		   langv = langvj;
		}
		const customStyles = {
		  content : {
			top                   : '35%',
			left                  : '50%',
			right                 : 'auto',
			bottom                : 'auto',
			marginRight           : '-50%',
			transform             : 'translate(-50%, -50%)',
			maxWidth             : '850px',
			maxHeight            : '450px'
		  }
		};
		const styles = {
			btncontinueb: {
				padding: '8px 12px',
				color: '#fff',
				transition: '0.6s',
				border: "1px solid " + this.state.store_det.header_bg_color,
				backgroundColor: this.state.store_det.header_bg_color + '33',
			},
			viewrewards: {

				border: "1px solid " + this.state.store_det.header_bg_color,
				backgroundColor: this.state.store_det.header_bg_color + '33',
				color: this.state.store_det.header_bg_color,
			},
			pts_style: {

				color: themecolor.text_color,
			},
			privacy_style: {
                cursor:'pointer',
				color: themecolor.login_text_color,
			},
			
			containerow: {
				width: '100%',
				paddingRight: '15px',
				paddingLeft: '15px',
				marginRight: 'auto',
				marginLeft: 'auto',
			},
			btn_rewardmob: {
				width: '100%',
				border: '1px solid #E5E5E5',
				color: this.state.tcolor,
				marginTop: '15px',
			},
			img_logo: {
				maxWidth: '150px'
			},
			mtitle: {
				textAlign: 'center',
				marginBottom: '2%',
			}
			
		};
		
		
		const stylesv2 = {
			login_box_bg: {
				backgroundColor: themecolor.login_box_color
			},
			login_btn: {
				backgroundColor: themecolor.login_btn_bg_color,
				color: themecolor.login_btn_text_color,
				border: "1px solid " + themecolor.login_btn_bg_color,
			},
			login_text_col: {
				color: themecolor.login_text_color,
			},
			ms_btn: {
				backgroundColor: themecolor.btn_bg_color,
				color: themecolor.btn_text_color,
				border: "1px solid " + themecolor.btn_bg_color,
			},
			ms_textcol: {
				color: themecolor.text_color,
			},
			trophy_icon: {
				color: themecolor.thumbs_icon_color,
				
				textAlign:'center',
				fontSize:'230px'
			},
			trophy_circle_bor: {
				border: '2px solid '+ themecolor.thumbs_icon_color,
				backgroundColor: themecolor.thumbs_icon_bg,
			
			},
			re_box_bg: {
				backgroundColor: themecolor.box_bg_col
			},
			
		};	
		
		
		return (
			<div>
				
				{this.state.loginmode === 'yes' &&
					
					<div>
						<Menu themecolor={this.props.themecolor} active_menu ={this.state.active_menu} logincheck={this.props.logincheck} />
					
					</div>
				}
				<Modal
				  isOpen={this.state.modalIsOpen}
				  Class="modalpop"
				  onRequestClose={this.state.closeModal}
				  style={customStyles}
				>
				 <button onClick={(e) => this.closeModal()} type="button" className="close_modal"><span aria-hidden="true">×</span></button>
				 <div className="modal-body">
					<h4 style={styles.mtitle} dangerouslySetInnerHTML={{ __html: this.state.modaltitle }}></h4>
					<div dangerouslySetInnerHTML={{ __html: this.state.modaltext }}></div>
				 </div>
				</Modal>
				{(this.state.store_det.status === 200) ? (
					<div style={styles.containerow} className='container'>


						{(this.state.loginmode === 'no') ? (
							<div>
							
							{this.state.pageview === 'login' &&
							
							<form name="loginform" className="loginform" onSubmit={this.loginfn.bind(this)}>
								
								<div className="row">
									<div className="col-md-4"></div>
									<div className="col-md-4">
										<div style={stylesv2.login_box_bg} className='div-popup-box'>
											{this.state.store_det.store_logo &&
										
											<div className="col-md-12 div-reward-mob-logo ptb20 qw">
												<img style={styles.img_logo} alt="logo" src={this.state.store_det.store_logo}></img>
											</div>										
											}
											<h4 style={stylesv2.login_text_col} className="txt-center" dangerouslySetInnerHTML={{ __html: '<i class="fa fa-user-circle-o"></i> '+langv.sign_in }}></h4>
											{this.state.error_msg !== ''  &&
											<div className="alert alert-danger fs14" role="alert">
											  {this.state.error_msg}
											</div>
											}
											<div className="form-group">
												<label style={stylesv2.login_text_col} htmlFor="txt-loginemail" dangerouslySetInnerHTML={{ __html: langv.email_ls }}></label>
												<input autoComplete="nope" id="txt-loginemail" type="email" className={"form-control " + (this.state.errors['loginemail'] === 1 ? 'err_bor' : '')} onChange={this.handleChange1.bind(this, "loginemail")}  placeholder="" />
											</div>
											<div className="form-group">
												<label style={stylesv2.login_text_col} htmlFor="txt-password"  dangerouslySetInnerHTML={{ __html: langv.password }}></label>
												<input autoComplete="nope" id="txt-password" type="password" className={"form-control " + (this.state.errors['loginpassword'] === 1 ? 'err_bor' : '')} onChange={this.handleChange1.bind(this, "loginpassword")}  placeholder="" />
											</div>
											<div className="txt-right m_bot1">
													<span className='cpointer'  style={stylesv2.login_text_col}  onClick={()=>{this.showpageview("forgot")}}><i className="fa fa-unlock"></i>  {langv.forgot_password}</span>
											</div>
											<div className="form-group txt-center">
												<button style={stylesv2.login_btn} className='btn btn-reward-mob-continue' type="submit" id="submit" value="Login"><i className="fa fa-sign-in"></i> {langv.login} {this.state.cont_loader === 1 && <i className="fa fa-spinner fa-spin"></i>}</button>
												
												
											</div>
											<div className="form-group txt-center">
												<div className="txt-center">
													
													 <span className='cpointer' style={stylesv2.login_text_col}  onClick={()=>{this.showpageview("register")}} >{langv.sign_up}</span>
		
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
							}
							
							
							{this.state.pageview === 'register' &&
							<form name="contactform" className="contactform" onSubmit={this.register.bind(this)}>
								
								<div className="row">
									<div className="col-md-4"></div>
									<div className="col-md-4">
										<div  style={stylesv2.login_box_bg} className="div-popup-box">
											{this.state.store_det.store_logo &&
											
											<div className="col-md-12 div-reward-mob-logo ptb20 qw">
												<img style={styles.img_logo} alt="logo" src={this.state.store_det.store_logo}></img>
											</div>										
											}
											<h4 style={stylesv2.login_text_col} className="colorwhite txt-center"><i className="fa fa-user"></i> {langv.sign_up}</h4>
											{this.state.error_msg !== ''  &&
											<div className="alert alert-danger fs14" role="alert">
											  {this.state.error_msg}
											</div>
											}
											
											<div className="form-group">
												<label htmlFor="txt-emailq" style={stylesv2.login_text_col} dangerouslySetInnerHTML={{ __html: langv.name_ls }}></label>
												<input autoComplete="nope" id="txt-emailq" type="text" className={"form-control " + (this.state.errors['name'] === 1 ? 'err_bor' : '')} name="name" onChange={this.handleChange1.bind(this, "name")} value={this.state.fields["name"]} placeholder="" />
											</div>
											<div className="form-group">
												<label htmlFor="txt-emailq" style={stylesv2.login_text_col} dangerouslySetInnerHTML={{ __html: langv.email_ls }}></label>
												<input autoComplete="nope" id="txt-emailq" type="email" className={"form-control " + (this.state.errors['email'] === 1 ? 'err_bor' : '')} name="email" onChange={this.handleChange1.bind(this, "email")} value={this.state.fields["email"]} placeholder="" />
											</div>
											<div className="form-group">
												<label style={stylesv2.login_text_col} htmlFor="txt-emailq">{langv.phone_ls}{this.state.store_det.phone_mandatory===0 && <span style={{ fontSize: '12px' }}> ({this.state.store_det.optional})</span>}</label>

												{/*<input id="txt-emailq" type="phone" className={"form-control " + (this.state.errors['phone'] === 1 ? 'err_bor' : '')} name="phone" onChange={this.handleChange1.bind(this, "phone")} value={this.state.fields["phone"]} placeholder="" />*/}

												<PhoneInput
													international
													countryCallingCodeEditable={false}
													defaultCountry={this.state.store_det.def_phone_country}
													onChange={this.phoneChange}
													className={"form-control " + (this.state.errors['phone'] === 1 ? 'err_bor' : '')}
													autoComplete="nope"
												/>

											</div>
											
											<div className="form-group">
												<label style={stylesv2.login_text_col} htmlFor="txt-regispassword">{langv.password}</label>
												<input autoComplete="nope" id="txt-regispassword" type="password" className={"form-control " + (this.state.errors['regispassword'] === 1 ? 'err_bor' : '')} name="regispassword" onChange={this.handleChange1.bind(this, "regispassword")} value={this.state.fields["regispassword"]} placeholder="" />
											</div>
											<div className="form-group">
												<label style={stylesv2.login_text_col} htmlFor="txt-emailq">{langv.confirm_password}</label>
												<input autoComplete="nope" id="txt-emailq" type="password" className={"form-control " + (this.state.errors['regisconfpassword'] === 1 ? 'err_bor' : '')} name="regisconfpassword" onChange={this.handleChange1.bind(this, "regisconfpassword")} value={this.state.fields["regisconfpassword"]} placeholder="" />
											</div>
											
											
											<div className="form-group txt-center">
												<button style={stylesv2.login_btn} className='btn btn-reward-mob-continue' type="submit" id="submit" value="Submit">{langv.sign_up} {this.state.cont_loader === 1 && <i className="fa fa-spinner fa-spin"></i>}</button>
											</div>
											{this.state.store_det.gdpr_status === 1 &&
											<div className="form-check">
												  <input onChange={this.handleChange1.bind(this, "gdpr_check_mode")}  className={"form-check-input " + (this.state.errors['gdpr_check_mode'] === 1 ? 'err_bor_ot' : '')} type="checkbox" value="1" name="gdpr_check_mode" id="gdpr_check_mode" />
												  <label style={stylesv2.login_text_col}  className="form-check-label" htmlFor="gdpr_check_mode">
													{this.state.store_det.gdpr_title}
												  </label>	
												
											</div>
											
											}
											<div className="form-group txt-center m_top1">
												{this.state.store_det.privacy_status === 1 &&
													<span onClick={(e) => this.openModal('privacy')} style={styles.privacy_style}>{this.state.store_det.privacy_title}</span>
												}
												{this.state.store_det.terms_status === 1 &&
													<span>
													{this.state.store_det.privacy_status === 1 && <span style={styles.privacy_style}> | </span> }
													<span  onClick={(e) => this.openModal('terms')} style={styles.privacy_style}>{this.state.store_det.terms_title}</span>
													</span>
												}
											</div>
											<div className="txt-right m_bot2">	
												<span className='cpointer'  style={stylesv2.login_text_col} onClick={()=>{this.showpageview("login")}}>{langv.sign_in}</span>
												
											</div>
										</div>
									</div>
								</div>
							</form>
							}
							
							{this.state.pageview === 'forgot' &&
							<form name="forgotform" className="forgotform" onSubmit={this.forgotpassword.bind(this)}>
								
								<div className="row">
									<div className="col-md-4"></div>
									<div className="col-md-4">
										<div  style={stylesv2.login_box_bg} className="div-popup-box">
											{this.state.store_det.store_logo &&
											
											<div className="col-md-12 div-reward-mob-logo ptb20 qw">
												<img style={styles.img_logo} alt="logo" src={this.state.store_det.store_logo}></img>
											</div>										
											}
											<h4 style={stylesv2.login_text_col} className="colorwhite txt-center" dangerouslySetInnerHTML={{ __html: '<i class="fa fa-key" aria-hidden="true"></i> '+this.state.store_det.password_recovery }}></h4>
											{this.state.error_msg !== ''  &&
											<div className="alert alert-danger fs14" role="alert">
											  {this.state.error_msg}
											</div>
											}
											{this.state.smsg !== ''  &&
											<div className="alert alert-success fs14" role="alert">
											  {this.state.smsg}
											</div>
											}
											<div className="form-group">
												<label htmlFor="txt-emailq"></label>
												<input autoComplete="nope" id="txt-forgotemail" type="email" className={"form-control " + (this.state.errors['forgotemail'] === 1 ? 'err_bor' : '')} name="forgotemail" onChange={this.handleChange1.bind(this, "forgotemail")} value={this.state.fields["forgotemail"]} placeholder={langv.email_ls} />
											</div>
											<div className="form-group txt-center">
												<button style={stylesv2.login_btn} className='btn btn-reward-mob-continue' type="submit" id="forgotbtn" value="Submit"><i className="fa fa-paper-plane" aria-hidden="true"></i> {langv.send_pwd_reset_link} {this.state.cont_loader === 1 && <i class="fa fa-spinner fa-spin"></i>}</button>
											</div>
											<span className='cpointer' style={stylesv2.login_text_col} onClick={()=>{this.showpageview("login")}}> <i class="fa fa-arrow-left"></i> {langv.back_to_login}</span>
										
										</div>
									</div>
								</div>
							</form>
							}
							
							{this.state.pageview === 'resetpwd' &&
							<form name="recoverform" className="recoverform" onSubmit={this.resetpassword.bind(this)}>
								
								<div className="row">
									<div className="col-md-4"></div>
									<div className="col-md-4">
										<div  style={stylesv2.login_box_bg} className="div-popup-box">
												{this.state.store_det.store_logo &&
												
												<div className="col-md-12 div-reward-mob-logo ptb20 qw">
													<img style={styles.img_logo} alt="logo" src={this.state.store_det.store_logo}></img>
												</div>										
												}
											<h4 style={stylesv2.login_text_col} className="colorwhite txt-center" dangerouslySetInnerHTML={{ __html: langv.reset_password }}></h4>
											{this.state.error_msg !== ''  &&
											<div className="alert alert-danger fs14" role="alert">
											  {this.state.error_msg}
											</div>
											}
											{this.state.smsg !== ''  &&
											<div className="alert alert-success fs14" role="alert">
											  {this.state.smsg}
											</div>
											}
											<div className="form-group">
												<label htmlFor="txt-emailq" style={stylesv2.login_text_col} dangerouslySetInnerHTML={{ __html: this.state.store_det.email_ls }}></label>
												<input autoComplete="nope" id="txt-reset_email" type="email" className={"form-control " + (this.state.errors['reset_email'] === 1 ? 'err_bor' : '')} name="reset_email" onChange={this.handleChange1.bind(this, "reset_email")} value={this.state.fields["reset_email"]} placeholder={langv.email_ls} />
											</div>
											<div className="form-group">
												<label htmlFor="txt-reset_password" style={stylesv2.login_text_col}>{langv.password}</label>
												<input autoComplete="nope" id="txt-reset_password" type="password" className={"form-control " + (this.state.errors['reset_password'] === 1 ? 'err_bor' : '')} name="reset_password" onChange={this.handleChange1.bind(this, "reset_password")} value={this.state.fields["reset_password"]} placeholder='' />
											</div>
											<div className="form-group">
												<label htmlFor="txt-reset_confpassword" style={stylesv2.login_text_col}>{langv.confirm_password}</label>
												<input autoComplete="nope" id="txt-reset_confpassword" type="password" className={"form-control " + (this.state.errors['reset_confpassword'] === 1 ? 'err_bor' : '')} name="reset_confpassword" onChange={this.handleChange1.bind(this, "reset_confpassword")} value={this.state.fields["reset_confpassword"]} placeholder='' />
											</div>
											<div className="form-group txt-center">
												<button style={stylesv2.login_btn} className="btn btn-reward-mob-continue" type="submit" id="reset_confpassword" value="Submit">{langv.submit} {this.state.cont_loader === 1 && <i className="fa fa-spinner fa-spin"></i>}</button>
											</div>
											<span className='cpointer' style={stylesv2.login_text_col} onClick={()=>{this.showpageview("login")}}>{langv.back_to_login}</span>
										
										</div>
									</div>
								</div>
							</form>
							}
							
							
							
							</div>
						) : (
							<div>
							
								{this.state.pageview === 'earnpoints' &&
								<div>
								{this.state.showAnim === '' ? (
									<div>
										
										<div className="row">
											<div style={stylesv2.trophy_icon} className={"col-md-12 " + (!this.state.store_det.store_logo ? 'ptb20' : '')}>
											    <div style={stylesv2.trophy_circle_bor} className="trophy_circle">
													<FontAwesomeIcon icon={faTrophy} />
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 div-points">
												<p className="earn_pts" >
												<strong style={styles.pts_style} dangerouslySetInnerHTML={{ __html: langv.active_points_ls }}>
												</strong>
												<strong style={styles.pts_style}> : {this.state.store_det.active_points}</strong>
												</p>
												<p className="earn_pts">
												<strong style={styles.pts_style} dangerouslySetInnerHTML={{ __html:langv.earned_points_ls }}></strong>
												<strong style={styles.pts_style}> : {this.state.store_det.earned_points}</strong>
												</p>
											</div>
										</div>
										<div className="row">
											<div  className="col-md-12 ">
												<div  className="row">
													<div className="col-md-3 offset-md-3 col-12">
													<Link style={stylesv2.ms_btn} className="btn-ow btn-reward-mob" to={{ pathname: process.env.PUBLIC_URL + "/viewrewards/" + this.state.store_id + "/" + this.state.prod_id, state: { store_id1: this.state.store_id, body_color: this.state.store_det.body_color } }}><FontAwesomeIcon icon={faAward} /> {langv.view_rewards_ls}</Link>
													</div>
													<div className="col-md-3 col-12">
													<Link style={stylesv2.ms_btn} className="btn-ow btn-reward-mob" to={{ pathname: process.env.PUBLIC_URL + "/category/" + this.state.store_id + "/" + this.state.prod_id }}><i className="fa fa-shopping-cart"></i> {langv.view_products_ls}</Link>
													</div>
												</div>
											</div>
											
										</div>
										
										<div className="row">
											<div className="col-md-12 div-visit-store">
												<p className="visit_store" style={styles.pts_style} dangerouslySetInnerHTML={{ __html: langv.visit_store_earn_ls }}></p>
											</div>
										</div>
										
										{this.state.viral_share !== 0 &&
										<div className="row">
											<div style ={stylesv2.re_box_bg} className="col-md-6 div-popup-box-ref offset-md-3">
												<label style={stylesv2.ms_textcol} className="form-label">{langv.referral_link}</label>
												<div className="input-group">
												  <input type="text" className="form-control"  onChange={(e)=>this.inputChangedHandler(e)} ref={(textarea) => this.textArea = textarea} value={this.state.referlink} />
												  <div className="input-group-append">
													<span style={stylesv2.ms_btn}  onClick={this.copyToClipboard} className="input-group-text cpointer" id="basic-addon2">{langv.copy}</span>
												  </div>
												</div>
												<div className="mt-1">
													<span style={stylesv2.ms_textcol} className="admin_bg">{this.state.store_det.points_ref_txt}</span>
												</div>	
											</div>
										</div>
										}
									</div>
								) : (

									<div className="row ">
										<div className="col-md-12 div-reward-ani m_top25per">
											<h3 style={styles.pts_style}>{langv.congrag_ls}</h3>
										</div>
										<div className="col-md-12 div-reward-ani">
											<img alt="logo" src="https://rewardsly.io/members/img/v1_Dark-Points.gif"></img>
										</div>
										<div className="col-md-12 div-reward-ani">
											<span style={styles.pts_style}>{this.state.store_det.you_have_earned_ls}</span>
										</div>
									</div>
								)}
								</div>
								}


							</div>

						)}
						
						

					</div>

				) : (this.state.store_det.status === 202) ? (

					<div>
						<span className="curr_unavail">Sorry! This page is currently unavailable.</span>
						<center><img alt="noimage" style={{ width: '80%' }} src={api_url + "/public/img/empty.png"} /></center>

					</div>

				) : (
					<div></div>
				)}
			</div>

		);
	}
}
const mapStateToProps = (state) => {
	return {
		memberCookie: state.passcode.memberCookie,
		rewardCookie: state.passcode.rewardCookie
	}

}
const mapDispatchToProps = (dispatch) => ({
	CookieUpdate: (action, val) => {
		//alert("cookieupdate"+val);
		dispatch({
			type: action,
			payval: val
		});
	}
})
export default connect(mapStateToProps, mapDispatchToProps)(PasscodeCookie);
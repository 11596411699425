import React from "react";
class Template1 extends React.Component {
	constructor(props) {

		super(props);
		console.log(props);
		this.state = {

			showinfov: ''

		}


		//this.load_rewards = this.load_rewards.bind(this);
	}
	showinfo(id) {

		this.setState({ showinfov: id })

	}
	closeinfo() {

		this.setState({ showinfov: '' })

	}
	render() {
		const rew = this.props.reward;
		const styletmp = {
			bg_box: {
				backgroundImage: "url(" + rew.image + ")"
			},
			rew_name_col: {

				color: rew.title_color
			},
			bg_col1: {

				backgroundColor: rew.bg_col1
			},
			bg_col1AF: {

				backgroundColor: rew.bg_col1 + "AF"
			},
			bg_col2: {

				backgroundColor: rew.bg_col2
			},
			rew_pts_col: {

				color: rew.pts_txt_color
			},
			btn_sec1: {

				backgroundColor: rew.btn_bg_color,
				color: rew.btn_txt_color,
				border: "1px solid " + rew.btn_txt_color
			},

			bor_top_bot_100: {
				borderTop: "100px solid " + rew.bg_col1,
				borderBottom: "100px solid " + rew.bg_col1
			},
			bor_top_120: {
				borderTop: "120px solid " + rew.bg_col1
			},
			bor_top_200: {
				borderTop: "200px solid " + rew.bg_col1
			},
			bor_left_8: {
				borderLeft: "8px solid " + rew.bg_col1
			},
			bor_left_30: {
				borderLeft: "30px solid " + rew.bg_col1
			},
			bor_left_150: {
				borderLeft: "150px solid " + rew.bg_col1
			},
			btn_bg_col: {
				color: rew.bg_col1
			},
			btn_bg_col_alt: {
				color: rew.btn_bg_color
			},
			curve_110: {
				boxShadow: "0px 0px 0px 110px" + rew.bg_col1
			}

		};

		return (

		<div key ={rew.keygen} className="col-xl-4 col-md-4 col-sm-4 col-12 m_bot2">
			{(rew.template_id === 1) ? (

				
					<div className={"div-template-one " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div style={styletmp.bg_col1} className="div-template-header">
							<span style={styletmp.rew_pts_col}>{rew.points} {this.props.rew_ls.points_ls}</span>
							{rew.short_description &&
								<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
							}
						</div>
						<div style={styletmp.bg_col2} className="div-template-footer">

							{(rew.redeem === 1) ? (
								<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} >{rew.redeem_text}</button>
							) : (
								<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
							)}

							<span style={styletmp.rew_name_col}>{rew.name}</span>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 2) ? (

				
					<div className={"div-template-two " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						{rew.short_description &&
							<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-footer" style={styletmp.bg_col1}>
							<div className="row">
								<div className="col-xl-6 col-md-6 col-sm-6 col-6">
									<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span><br />
									<span className="item-points" style={styletmp.rew_pts_col}>{rew.points} {this.props.rew_ls.points_ls}</span>
								</div>
								<div className="col-xl-6 col-md-6 col-sm-6 col-6">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 3) ? (
				
					<div className={"div-template-three " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div className="triangle-right" style={styletmp.bor_top_bot_100}></div>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div className="div-re-points">
									<span className="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not div-nt-eng" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 4) ? (

				
					<div className={"div-template-four " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
								<div className="triangle-right" style={styletmp.bor_left_8}></div>
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>


			) : (rew.template_id === 5) ? (
				
					<div className={"div-template-five " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
							<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
								<div className="half-circle" style={styletmp.bg_col1}></div>
								<div className="div-points">
									<p className="item-points"><strong style={styletmp.rew_pts_col}>{rew.points}</strong></p>
									<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
								</div>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close btn-fiveclose"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 6) ? (

				
					<div className={"div-template-six " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_col1}>
						<div className="div-template-child">
							{rew.short_description &&
								<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
							}
							<div>
								<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								<p className="item-points" style={styletmp.rew_pts_col}>{rew.points} points</p>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 7) ? (

				
					<div className={"div-template-seven " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="div-template-footer" style={styletmp.bg_col1}>
							<div className="row">
								<div className="col-xl-6 col-md-6 col-sm-6 col-6">
									<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span><br />
									<span className="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
								</div>
								<div className="col-xl-6 col-md-6 col-sm-6 col-6">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 8) ? (

				
					<div className={"div-template-eight " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div style={styletmp.bg_col1} className="col-xl-5 col-md-5 col-sm-5 col-5 pt10">
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 9) ? (

				
					<div className={"div-template-nine " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_col1}>
						<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
						<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
						<span className="item-points-text" style={styletmp.rew_pts_col}>points</span>
						{(rew.redeem === 1) ? (
							<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
						) : (
							<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
						)}
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 10) ? (

				
					<div className={"div-template-ten " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="div-template-footer" style={styletmp.bg_col1}>
							<div className="row">
								<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
									<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
									<span style={styletmp.rew_pts_col} className="item-points"><strong>{rew.points}</strong> points</span>
								</div>
								<div className="col-xl-5 col-md-5 col-sm-5 col-5">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 11) ? (

				
					<div className={"div-template-eleven " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0">
								<div style={styletmp.bor_top_200} className="div-left"></div>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div className="div-points-btn">
									<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 12) ? (

				
					<div className={"div-template-twelve " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
								<div className="div-name-btn" style={styletmp.bg_col1}>
									<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div className="div-right" style={styletmp.bor_top_120}></div>
								<div className="div-points">
									<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 13) ? (

				
					<div className={"div-template-thriteen " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-4 col-md-4 col-sm-4 col-4">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
								<div id="overlay" style={styletmp.bg_col1AF}></div>
								<div className="row div-overall">
									<div className="col-xl-7 col-md-7 col-sm-7 col-7">
										<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										{/* <p className="item-name-sub">SUPPLIES</p> */}
										<div className="div-bor-right"></div>
									</div>
									<div className="col-xl-5 col-md-5 col-sm-5 col-5">
										<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
										<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
									</div>
									<div className="col-xl-4 col-md-4 col-sm-4 col-4">
										{(rew.redeem === 1) ? (
											<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
										) : (
											<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 14) ? (

				
					<div className={"div-template-fourteen " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<div className="div-name-btn">
									<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
								<div className="div-points">
									<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 15) ? (

				
					<div className={"div-template-fifteen " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
								<div className="div-left" style={styletmp.bor_top_200}></div>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div className="div-points-btn">
									<p className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 16) ? (

				
					<div className={"div-template-sixteen " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pt10 pr0" style={styletmp.bg_col1}>
								<div className="div-name-btn">
									<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								</div>
								<div className="div-points">
									<div>
										<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
										<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
									</div>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 17) ? (

				
					<div className={"div-template-seventeen " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-7 col-md-7 col-sm-7 col-7">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div className="row">
									<div className="col-xl-8 col-md-8 col-sm-8 col-8 pr0" style={styletmp.bg_col1}>
										<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
										<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
										<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
										{(rew.redeem === 1) ? (
											<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
										) : (
											<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 18) ? (

				
					<div className={"div-template-eighteen " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-6 col-md-6 col-sm-6 col-6 pt10" style={styletmp.bg_col1}>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 19) ? (

				
					<div className={"div-template-ninteen " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 20) ? (

				
					<div className={"div-template-twenty " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="div-parent" style={styletmp.bg_col1}>
							<div className="row m0">
								<div className="col-xl-6 col-md-6 col-sm-6 col-6 p18per">
									<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								</div>
								<div className="col-xl-6 col-md-6 col-sm-6 col-6 p7per">
									<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 21) ? (

				
					<div className={"div-template-twentyone " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
								<div className="div-name-btn" style={styletmp.bg_col1}>
									<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div className="div-points" style={styletmp.bg_col1}>
								<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
								<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				
			) : (rew.template_id === 22) ? (

				
					<div className={"div-template-twentytwo " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
							<div className="col-xl-5 col-md-5 col-sm-5 col-5" style={styletmp.bg_col1}>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p className="item-points" style={styletmp.rew_pts_col}>{rew.points} <span>points</span></p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 23) ? (

				
					<div className={"div-template-twentythree " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="div-circle" style={styletmp.bg_col1}>
						</div>
						<div className="div-content">
							<p className="item-points" style={styletmp.rew_pts_col}>{rew.points} <span>points</span></p>
							<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
							<div className="mt10">
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 24) ? (

				
					<div className={"div-template-twentyfour " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
							<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
								<div className="div-right" style={styletmp.bor_top_200}></div>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div className="div-points-btn">
									<p className="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p className="item-points-text" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 25) ? (

				
					<div className={"div-template-twentyfive " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-footer" style={styletmp.bg_col1}>
							<div className="row">
								<div className="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
									<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span><br />
									<span className="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
								</div>
								<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 26) ? (

				
					<div className={"div-template-twentysix " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0">
								<div className="triangle-left" style={styletmp.bor_top_bot_100}></div>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div className="div-re-points">
									<span className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 27) ? (

				
					<div className={"div-template-twentyseven " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-7 col-md-7 col-sm-7 col-7">
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
							<div className="col-xl-5 col-md-5 col-sm-5 col-5" style={styletmp.bg_col1}>
								<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								<div className="div-re-points">
									<span className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 28) ? (

				
					<div className={"div-template-twentyeight " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-4 col-md-4 col-sm-4 col-4 pr0" style={styletmp.bg_col1}>
								<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								<div className="div-re-points">
									<span className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div className="col-xl-1 col-md-1 col-sm-1 col-1 pl0">
								<div className="triangle-left" style={styletmp.bor_left_30}></div>
							</div>
							<div className="col-xl-7 col-md-7 col-sm-7 col-7">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 29) ? (

				
					<div className={"div-template-twentynine " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						{rew.short_description &&
							<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="row">
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 div-twentynine-content">
								<div className="row">
									<div className="col-xl-8 col-md-8 col-sm-8 col-8" style={styletmp.bg_col1}>
										<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<div className="div-re-points">
											<span className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> points</span>
											{(rew.redeem === 1) ? (
												<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
											) : (
												<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
											)}
										</div>
									</div>
									<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
										<div className="semicircle-left semicircle-left-curve" style={styletmp.curve_110}></div>
									</div>
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 30) ? (

				
					<div className={"div-template-thrity " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0">
								<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								<div className="div-re-points">
									<span className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div className="col-xl-7 col-md-7 col-sm-7 col-7">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 31) ? (

				
					<div className={"div-template-thrityone " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-footer" style={styletmp.bg_col1}>
							<div className="row">
								<div className="col-xl-8 col-md-8 col-sm-8 col-8">
									<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span><br />
									<span className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> {this.props.rew_ls.points_ls}</span>
								</div>
								<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 32) ? (

				
					<div className={"div-template-thritytwo " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="div-template-header" style={styletmp.bg_col1}>
							<span style={styletmp.rew_name_col}>{rew.name}</span>
							{rew.short_description &&
								<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
							}
						</div>
						<div className="div-template-footer" style={styletmp.bg_col2}>
							{(rew.redeem === 1) ? (
								<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
							) : (
								<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
							)}
							<span style={styletmp.rew_pts_col}>{rew.points} {this.props.rew_ls.points_ls}</span>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 33) ? (

				
					<div className={"div-template-thritythree " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div className="row">
									<div className="col-xl-4 col-md-4 col-sm-4 col-4">
									</div>
									<div className="col-xl-8 col-md-8 col-sm-8 col-8 pr0" style={styletmp.bg_col1}>
										<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<p className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> {this.props.rew_ls.points_ls}</p>
										{(rew.redeem === 1) ? (
											<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
										) : (
											<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
										)}
									</div>
								</div>
							</div>
							<div className="col-xl-7 col-md-7 col-sm-7 col-7">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 34) ? (

				
					<div className={"div-template-thrityfour " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
								<div className="div-left" style={styletmp.bor_top_200}></div>
								<span className="item-name"><strong style={styletmp.rew_name_col}>{rew.name}</strong></span>
								<div className="div-points-btn">
									<p className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> {this.props.rew_ls.points_ls}</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
								{rew.short_description &&
									<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 35) ? (

				
					<div className={"div-template-thrityfive " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-footer" style={styletmp.bg_col1}>
							<div className="row">
								<div className="col-xl-4 col-md-4 col-sm-4 col-4 pr0">
									<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
									<div className="mt10"><span className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> {this.props.rew_ls.points_ls}</span></div>
									<div>
										{(rew.redeem === 1) ? (
											<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
										) : (
											<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 36) ? (

				
					<div className={"div-template-thritysix " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
								<div className="triangle-left" style={styletmp.bor_left_150}></div>
								<span className="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div className="div-re-points">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
									<p className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> {this.props.rew_ls.points_ls}</p>
								</div>
							</div>
							<div className="col-xl-6 col-md-6 col-sm-6 col-6">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>


			) : (rew.template_id === 37) ? (

				
					<div className={"div-template-thrityseven " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-11 col-md-11 col-sm-11 col-11">
								<div className="row">
									<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0" style={styletmp.bg_col1}>
										<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<div className="div-re-points">
											{(rew.redeem === 1) ? (
												<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
											) : (
												<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
											)}
											<p className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> {this.props.rew_ls.points_ls}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>

				

			) : (rew.template_id === 38) ? (

				
					<div className={"div-template-thrityeight " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						{rew.short_description &&
							<button type="button" className="btn-info" onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="row">
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
								<div style={styletmp.bg_col1}>
									<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								</div>
							</div>
							<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div style={styletmp.bg_col1}>
									<p className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> {this.props.rew_ls.points_ls}</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 39) ? (

				
					<div className={"div-template-thritynine " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
							<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0" style={styletmp.bg_col1}>
								<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								<div className="div-re-points">
									<p className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> {this.props.rew_ls.points_ls}</p>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>

				

			) : (rew.template_id === 40) ? (

				
					<div className={"div-template-forty " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="row">
							<div className="col-xl-5 col-md-5 col-sm-5 col-5">
								{rew.short_description &&
									<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
								}
							</div>
							<div className="col-xl-7 col-md-7 col-sm-7 col-7 div-forty-content">
								<div className="row">
									<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
										<div className="semicircle-right semicircle-right-curve" style={styletmp.curve_110}></div>
									</div>
									<div className="col-xl-8 col-md-8 col-sm-8 col-8">
										<p className="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<div className="div-re-points">
											<span className="item-points" style={styletmp.rew_pts_col}><strong style={styletmp.rew_pts_col}>{rew.points}</strong> {this.props.rew_ls.points_ls}</span>
											{(rew.redeem === 1) ? (
												<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
											) : (
												<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				

			) : (rew.template_id === 41) ? (

				
					<div className={"div-template-fortyone " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="div-template-footer" style={styletmp.bg_col1}>
							<div className="row">
								<div className="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
									<p className="item-name rew_name_col" style={styletmp.rew_name_col}>{rew.name}</p>
									<span className="item-points rew_pts_col" style={styletmp.rew_pts_col}>{rew.points}  points</span>
								</div>
								<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						<div className="div-template-redeemed">
							<i className="fa fa-check-circle"></i>
						</div>
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 42) ? (

				
					<div className={"div-template-fortytwo " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div className="div-template-footer" style={styletmp.bg_col1}>
							<div className="row">
								<div className="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
									<p className="item-name rew_name_col" style={styletmp.rew_name_col}>{rew.name}</p>
									<span className="item-points rew_pts_col" style={styletmp.rew_pts_col}>{rew.points} points</span>
								</div>
								<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 43) ? (

				
					<div class={"div-template-fortythree " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<span class="item-name rew_name_col" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p class="item-points rew_pts_col" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p class="item-points-text rew_pts_col" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 44) ? (

				
					<div class={"div-template-fortyfour " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div class="triangle-right" style={styletmp.bor_top_bot_100}></div>
								<span class="item-name rew_name_col" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-re-points">
									<span class="item-points rew_pts_col" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 45) ? (

				
					<div class={"div-template-fortyfive " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<div class="div-name-btn">
									<span class="item-name rew_name_col" style={styletmp.rew_name_col}>{rew.name}</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
								<div class="div-points">
									<p class="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p class="item-points-text" style={styletmp.rew_pts_col}>points</p>
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 46) ? (

				
					<div class={"div-template-fortysix " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-4 col-md-4 col-sm-4 col-4 pr0" style={styletmp.bg_col1}>
								<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								<div class="div-re-points">
									<span class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-1 col-md-1 col-sm-1 col-1 pl0">
								<div class="triangle-left" style={styletmp.bor_left_30}></div>
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 47) ? (

				
					<div class={"div-template-fortyseven " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
							</div>
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
								<div class="half-circle" style={styletmp.bg_col1}></div>
								<div class="div-points">
									<p class="item-points"><strong style={styletmp.rew_pts_col}>{rew.points}</strong></p>
									<p class="item-points-text" style={styletmp.rew_pts_col}>points</p>
								</div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 48) ? (

				
					<div class={"div-template-fortyeight " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pt10 pr0" style={styletmp.bg_col1}>
								<div class="div-name-btn">
									<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								</div>
								<div class="div-points">
									<div>
										<p class="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
										<p class="item-points-text" style={styletmp.rew_pts_col}>points</p>
									</div>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 49) ? (

				
					<div class={"div-template-fortynine " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="div-diamond" style={styletmp.bg_col1}></div>
							<div class="div-items text-center">
								<div class="div-item-name">
									<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								</div>
								<div class="div-points">
									<div>
										<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									</div>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 50) ? (

				
					<div class={"div-template-fifty " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pr0" style={styletmp.bg_col1}>
								<p class="item-name rew_name_col" style={styletmp.rew_name_col}>{rew.name}</p>
								<div class="div-re-points">
									<span class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-1 col-md-1 col-sm-1 col-1 pl0">
								<div class="triangle-left" style={styletmp.bor_left_30}></div>
							</div>
							<div class="col-xl-6 col-md-6 col-sm-6 col-6">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 51) ? (

				
					<div class={"div-template-fiftyone " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pr0">
								<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								<div class="div-re-points">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
									<p class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</p>
								</div>
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 52) ? (

				
					<div class={"div-template-fiftytwo " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
								<div style={styletmp.bg_col1}>
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								</div>
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div style={styletmp.bg_col1}>
									<p class="item-points" style={styletmp.rew_pts_col}><span>{rew.points}</span> points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 53) ? (

				
					<div class={"div-template-fiftythree " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-template-header" style={styletmp.bg_col1}>
							<span class="rew_name_col" style={styletmp.rew_name_col}>{rew.name}</span>
						</div>
						<div class="div-template-footer" style={styletmp.bg_col2}>
							{(rew.redeem === 1) ? (
								<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
							) : (
								<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
							)}
							<span class="" style={styletmp.rew_pts_col}>{rew.points} Points</span>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.rew_name_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 54) ? (

				
					<div class={"div-template-fiftyfour " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div class="row">
									<div class="col-xl-4 col-md-4 col-sm-4 col-4">
									</div>
									<div class="col-xl-8 col-md-8 col-sm-8 col-8 pr0" style={styletmp.bg_col1}>
										<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<p class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> Points</p>
										{(rew.redeem === 1) ? (
											<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
										) : (
											<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
										)}
									</div>
								</div>
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 55) ? (

				
					<div class={"div-template-fiftyfive " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-template-footer" style={styletmp.bg_col1}>
							<div class="row">
								<div class="col-xl-4 col-md-4 col-sm-4 col-4 pr0">
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
									<div class="mt10"><span class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</span></div>
									<div>
										{(rew.redeem === 1) ? (
											<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
										) : (
											<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
										)}
									</div>
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 56) ? (

				
					<div class={"div-template-fiftysix " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
								<div class="div-left" style={styletmp.bor_top_200}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-points-btn">
									<p class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 57) ? (

				
					<div class={"div-template-fiftyseven " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-11 col-md-11 col-sm-11 col-11">
								<div class="row">
									<div class="col-xl-6 col-md-6 col-sm-6 col-6 pr0" style={styletmp.bg_col1}>
										<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<div class="div-re-points">
											{(rew.redeem === 1) ? (
												<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
											) : (
												<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
											)}
											<p class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 58) ? (

				
					<div class={"div-template-fiftyeight " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
								<div class="" style={styletmp.bg_col1}>
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								</div>
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div class="" style={styletmp.bg_col1}>
									<p class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 59) ? (

				
					<div class={"div-template-fiftynine " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
								<div class="" style={styletmp.bg_col1}>
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								</div>
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div class="" style={styletmp.bg_col1}>
									<p class="item-points" style={styletmp.rew_pts_col}><span style={styletmp.rew_pts_col}>{rew.points}</span> points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 60) ? (

				
					<div class={"div-template-sixty " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 div-sixty-content">
								<div class="row">
									<div class="col-xl-8 col-md-8 col-sm-8 col-8" style={styletmp.bg_col1}>
										<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<div class="div-re-points">
											<span class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</span>
											{(rew.redeem === 1) ? (
												<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
											) : (
												<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
											)}
										</div>
									</div>
									<div class="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
										<div class="semicircle-left semicircle-left-curve box_shadow_left-curve"></div>
									</div>
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 61) ? (

				
					<div class={"div-template-sixtyone " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
							</div>
							<div class="col-xl-4 col-md-4 col-sm-4 col-4 pl0" style={styletmp.bg_col1}>
								<p class="item-name" style={styletmp.rew_name_col}><strong>{rew.name}</strong></p>
								<div class="div-re-points">
									<p class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</p>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 62) ? (

				
					<div class={"div-template-sixtytwo " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5">
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 div-forty-content">
								<div class="row">
									<div class="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
										<div class="semicircle-right semicircle-right-curve box_shadow_left-curve" style={styletmp.curve_110}></div>
									</div>
									<div class="col-xl-8 col-md-8 col-sm-8 col-8 bg_col1">
										<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<div class="div-re-points">
											<span class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</span>
											{(rew.redeem === 1) ? (
												<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
											) : (
												<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 63) ? (

				
					<div class={"div-template-sixtythree " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7">
								<div class="triangle-right br_right-8"></div>
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p class="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p class="item-points-text rew_pts_col" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 64) ? (

				
					<div class={"div-template-sixtyfour " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
							</div>
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
								<div class="div-right br_top_200" style={styletmp.bor_top_200}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-points-btn">
									<p class="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p class="item-points-text rew_pts_col" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>




			) : (rew.template_id === 65) ? (

				
					<div class={"div-template-sixtyfive " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7">
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div class="row">
									<div class="col-xl-8 col-md-8 col-sm-8 col-8 pr0" style={styletmp.bg_col1}>
										<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
										<p class="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
										<p class="item-points-text" style={styletmp.rew_pts_col}>points</p>
										{(rew.redeem === 1) ? (
											<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
										) : (
											<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
										)}
									</div>
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 66) ? (

				
					<div class={"div-template-sixtysix " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
								<div class="triangle-left br_left-150" style={styletmp.bor_left_150}></div>
								<span class="item-name" style={styletmp.rew_name_col}><strong>{rew.name}</strong></span>
								<div class="div-re-points">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
									<p class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</p>
								</div>
							</div>
							<div class="col-xl-6 col-md-6 col-sm-6 col-6">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 67) ? (

				
					<div class={"div-template-sixtyseven " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-circle" style={styletmp.bg_col1}>
						</div>
						<div class="div-content">
							<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
							<p class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</p>
							<div class="mt10">
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 68) ? (

				
					<div class={"div-template-sixtyeight " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-4 col-md-4 col-sm-4 col-4 pr0">
							</div>
							<div class="col-xl-8 col-md-8 col-sm-8 col-8 pl0">
								<div class="div-left br_top_200" style={styletmp.bor_top_200}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-points-btn">
									<p class="item-points" style={styletmp.rew_pts_col}><strong>{rew.points}</strong> points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 69) ? (

				
					<div class={"div-template-sixtynine " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-circle" style={styletmp.bg_col1}>
						</div>
						<div class="div-content">
							<p class="item-points" style={styletmp.rew_pts_col}>{rew.points} <span>points</span></p>
							<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
							<div class="mt10">
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 70) ? (

				
					<div class={"div-template-seventy " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5">
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 div-forty-content">
								<div class="row">
									<div class="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
										<div class="semicircle-right semicircle-right-curve box_shadow_left-curve" style={styletmp.curve_110}></div>
									</div>
									<div class="col-xl-8 col-md-8 col-sm-8 col-8 bg_col1">
										<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<div class="div-re-points">
											<span class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
											{(rew.redeem === 1) ? (
												<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
											) : (
												<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 71) ? (

				
					<div class={"div-template-seventytwo " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5" style={styletmp.bg_col1}>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p class="item-points" style={styletmp.rew_pts_col}>{rew.points} <span>points</span></p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 72) ? (

				
					<div class={"div-template-seventyone " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-template-header" style={styletmp.bg_col1}>
							<span class="" style={styletmp.rew_pts_col}>{rew.points} points</span>
						</div>
						<div class="div-template-footer" style={styletmp.bg_col2}>
							{(rew.redeem === 1) ? (
								<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
							) : (
								<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
							)}
							<span class="rew_name_col" style={styletmp.rew_name_col}>{rew.name}</span>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 73) ? (

				
					<div class={"div-template-seventythree " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pr0">
								<div class="div-left br_top_200" style={styletmp.bor_top_200}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-points-btn">
									<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 74) ? (

				
					<div class={"div-template-seventyfour " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
							</div>
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
								<div class="half-circle" style={styletmp.bg_col1}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-points">
									<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 75) ? (

				
					<div class={"div-template-seventyfive " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
								<div class="half-circle" style={styletmp.bg_col1}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-points">
									<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 76) ? (

				
					<div class={"div-template-seventysix " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7">
								<div class="triangle-right" style={styletmp.bor_left_8}></div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 77) ? (

				
					<div class={"div-template-seventyseven " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7">
								<div class="triangle-right br-right-8"></div>
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 78) ? (

				
					<div class={"div-template-seventyeight " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 79) ? (

				
					<div class={"div-template-seventynine " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 80) ? (

				
					<div class={"div-template-eighty " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-template-footer" style={styletmp.bg_col1}>
							<div class="row">
								<div class="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								</div>
								<div class="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
									<span class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 81) ? (

				
					<div class={"div-template-eightyone " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-template-footer" style={styletmp.bg_col1}>
							<div class="row">
								<div class="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								</div>
								<div class="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
									<span class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 82) ? (

				
					<div class={"div-template-eightytwo " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-8 col-md-8 col-sm-8 col-8 pl0">
								<div class="div-left" style={styletmp.br_top_200}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-points-btn">
									<p class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-4 col-md-4 col-sm-4 col-4 pr0">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 83) ? (

				
					<div class={"div-template-eightythree " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-4 col-md-4 col-sm-4 col-4 pr0">
							</div>
							<div class="col-xl-8 col-md-8 col-sm-8 col-8 pl0">
								<div class="div-left" style={styletmp.br_top_200}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-points-btn">
									<p class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 84) ? (

				
					<div class={"div-template-eightyfour " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-circle" style={styletmp.bg_col1}>
						</div>
						<div class="div-content">
							<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
							<p class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</p>
							<div class="mt10">
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 85) ? (

				
					<div class={"div-template-eightyfive " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-circle" style={styletmp.bg_col1}>
						</div>
						<div class="div-content">
							<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
							<p class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</p>
							<div class="">
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 86) ? (

				
					<div class={"div-template-eightysix " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
								<div class="triangle-right" style={styletmp.bor_top_bot_100}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-re-points">
									<span class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 87) ? (

				
					<div class={"div-template-eightyseven " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
							</div>
							<div class="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
								<div class="triangle-right" style={styletmp.bor_top_bot_100}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-re-points">
									<span class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 88) ? (

				
					<div class={"div-template-eightyeight " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5">
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 div-forty-content">
								<div class="row">
									<div class="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
										<div class="semicircle-right semicircle-right-curve" style={styletmp.curve_110}></div>
									</div>
									<div class="col-xl-8 col-md-8 col-sm-8 col-8" style={styletmp.bg_col1}>
										<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<div class="div-re-points">
											<span class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
											{(rew.redeem === 1) ? (
												<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
											) : (
												<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 89) ? (

				
					<div class={"div-template-eightynine " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 div-forty-content">
								<div class="row">
									<div class="col-xl-8 col-md-8 col-sm-8 col-8" style={styletmp.bg_col1}>
										<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
										<div class="div-re-points">
											<span class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
											{(rew.redeem === 1) ? (
												<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
											) : (
												<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
											)}
										</div>
									</div>
									<div class="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
										<div class="semicircle-right semicircle-right-curve" style={styletmp.curve_110}></div>
									</div>
								</div>
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 90) ? (

				
					<div class={"div-template-ninty " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="div-diamond" style={styletmp.bg_col1}></div>
							<div class="div-items text-center">
								<div class="div-item-name">
									<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								</div>
								<div class="div-points">
									<div>
										<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									</div>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 91) ? (

				
					<div class={"div-template-nintyone " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="div-diamond" style={styletmp.bg_col1}></div>
							<div class="div-items text-center">
								<div class="div-item-name">
									<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								</div>
								<div class="div-points">
									<div>
										<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									</div>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 92) ? (

				
					<div class={"div-template-nintytwo " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 93) ? (

				
					<div class={"div-template-nintythree " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7">
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.bg_col1}>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div>
									<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				


			) : (rew.template_id === 94) ? (

				
					<div class={"div-template-nintyfour " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="div-diamond" style={styletmp.bg_col1}></div>
							<div class="div-items text-center">
								<div class="div-item-name">
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								</div>
								<div class="div-points">
									<div>
										<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									</div>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 95) ? (

				
					<div class={"div-template-nintyfive " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pr0">
								<div class="div-left" style={styletmp.br_top_200}></div>
								<span class="item-name" style={styletmp.rew_name_col}>{rew.name}</span>
								<div class="div-points-btn">
									<p class="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p class="item-points-text" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 96) ? (

				
					<div class={"div-template-nintysix " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
								<div class="div-name-btn" style={styletmp.bg_col1}>
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
								<div class="div-right br_top_120" style={styletmp.bor_top_120}></div>
								<div class="div-points">
									<p class="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p class="item-points-text" style={styletmp.rew_pts_col}>points</p>
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 97) ? (

				
					<div class={"div-template-nintyseven " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
								<div class="div-name-btn" style={styletmp.bg_col1}>
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
							<div class="div-points" style={styletmp.bg_col1}>
								<p class="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
								<p class="item-points-text" style={styletmp.rew_pts_col}>points</p>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 98) ? (

				
					<div class={"div-template-nintyeight " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-parent" style={styletmp.bg_col1}>
							<div class="row m0">
								<div class="col-xl-6 col-md-6 col-sm-6 col-6 p18per">
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								</div>
								<div class="col-xl-6 col-md-6 col-sm-6 col-6 p7per">
									<p class="item-points" style={styletmp.rew_pts_col}>{rew.points}</p>
									<p class="item-points-text" style={styletmp.rew_pts_col}>points</p>
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 99) ? (

				
					<div class={"div-template-nintynine " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="div-template-footer" style={styletmp.bg_col1}>
							<div class="row">
								<div class="col-xl-8 col-md-8 col-sm-8 col-8 pr0">
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
									<span class="item-points" style={styletmp.rew_pts_col}>{rew.points} points</span>
								</div>
								<div class="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
									{(rew.redeem === 1) ? (
										<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
									) : (
										<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
									)}
								</div>
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 100) ? (

				
					<div class={"div-template-hundred " + (rew.redeemed_status ? 'redeemed' : '')} style={styletmp.bg_box}>
						<div class="row">
							<div class="div-diamond" style={styletmp.bg_col1}></div>
							<div class="div-items text-center">
								<div class="div-item-name">
									<p class="item-name" style={styletmp.rew_name_col}>{rew.name}</p>
								</div>
								<div class="div-points">
									<div>
										<p class="item-points-text" style={styletmp.rew_pts_col}>{rew.points} points</p>
									</div>
								</div>
								{(rew.redeem === 1) ? (
									<button style={styletmp.btn_sec1} className="btn" type="button" onClick={(e) => this.props.redeem(rew.id)} ><i className="fa fa-check"></i> {rew.redeem_text}</button>
								) : (
									<button style={styletmp.btn_sec1} className="btn btn_not" type="button" >{rew.redeem_text}</button>
								)}
							</div>
						</div>
						{rew.short_description &&
							<button type="button" className="btn-info" style={styletmp.btn_bg_col_alt} onClick={() => this.showinfo(rew.id)}><i className="fa fa-info-circle"></i></button>
						}
						{this.state.showinfov === rew.id &&
							<div className="div-template-description">
								<button onClick={() => this.closeinfo()} type="button" className="btn-pop-close"><i className="fa fa-times"></i></button>
								<p className="div-pop-desc">
									{rew.short_description}
								</p>
							</div>
						}
					</div>
				



			) : (rew.template_id === 101) ? (

				

					<div></div>



			) : (



				<div></div>


			)}
			</div>
			
			

		);
	}
}


export default Template1;
import React from "react";
import { api_url } from '../config';
import '../css/stamp_page.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Menu from '../components/Menu';
class MyProfile extends React.Component {
	constructor(props) {

		super(props);
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		this.props.updateRetarget(store_id, 'reward');
		const loginmode_sess = localStorage.getItem('loginmode');
		var loginmode;
		if(loginmode_sess === null){
			loginmode = 'no';
		}else{
			loginmode = loginmode_sess;
		}
		
		this.props.logincheck('islogin');
		this.state = {
			store_id: store_id,
			prod_id: prod_id,
			pageview: 'login',
			loginmode:loginmode,
			showAnim: '',
			smsg: '',
			cont_loader: 0,
			active_menu:'myprofile',
			fields: {
				name: '',
				email: '',
				phone: '',
				currentpassword: '',
				newpassword: '',
				confpassword: '',
				
			},
			errors: {
				name: '',
				email: '',
				phone: '',
				currentpassword: '',
				newpassword: '',
				confpassword:'',
				
			},
			store_det: '',
			error_msg: '',
			member_det: '',
			lang: '',
			isDisabled: true,
			changepwd: 0

		}
		this.phoneChange = this.phoneChange.bind(this);
		this.changePwdfn = this.changePwdfn.bind(this);

	}


	componentDidMount() {
	
	
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const fk_member_id = localStorage.getItem("fk_member_id");

		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("prod_id", prod_id);
		postdata.append("fk_member_id", fk_member_id);
		//console.log(langv);
		let api_url1;
		api_url1 = api_url + "/api/myprofile";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (result.status === 200) {
						//console.log(result.member_det.name);
						this.setState({ 
							member_det: result.member_det,
							header_bg_color: result.header_bg_color,
							lang:result.ls,
						});
						
						let fields = { ...this.state.fields };
						fields['name'] = result.member_det.name;
						fields['email'] = result.member_det.email;
						fields['phone'] = result.member_det.phone;
						this.setState({ fields: fields });

						
					} else {
						
					}


				},
				(error) => {

				}

			)

	}
	handleChange1(field, e) {
		let fields = { ...this.state.fields };
		let errors = this.state.errors;
		fields[field] = e.target.value;
		errors[field] = '';
		this.setState({ fields: fields });
		this.setState({ errors });

	}
	phoneChange(phone_number) {
		let fields = { ...this.state.fields };
		fields['phone'] = phone_number;
		this.setState({ fields: fields });
	}
	profileValidation() {
		let fields = { ...this.state.fields };
		let errors = {};
		let formIsValid = true;
		//console.log(fields["email"]);
		if (!fields["email"]) {
			formIsValid = false;

		}
		if (fields["name"] === '' || typeof fields["name"] === "undefined") {
			formIsValid = false;
			errors["name"] = 1;
		}
		if (typeof fields["email"] !== "undefined") {
			let lastAtPos = fields["email"].lastIndexOf('@');
			let lastDotPos = fields["email"].lastIndexOf('.');

			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
				formIsValid = false;
				//errors["email"] = "Email is not valid";
				errors["email"] = 1;
			}
		}
		if(this.state.changepwd === 1){
			
			if (fields["currentpassword"] === '' || typeof fields["currentpassword"] === "undefined") {
				formIsValid = false;
				errors["currentpassword"] = 1;
			} 
			if (fields["newpassword"] === '' || typeof fields["newpassword"] === "undefined") {
					formIsValid = false;
					errors["newpassword"] = 1;
			}
			if (fields["newpassword"] !== '' &&  fields["confpassword"] !== '') {
				   if(fields["newpassword"] !== fields["confpassword"]){
						formIsValid = false;
						errors["confpassword"] = 1;
				   }
			}
			
		}
		
		
		this.setState({ errors: errors });
		return formIsValid;

	}
	changePwdfn(){
		this.setState({ changepwd: 1 });
		
	}
	profileupdate(e){
		e.preventDefault();
		
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		if (this.profileValidation()) {
			const postdata = new FormData();
			postdata.append("name", this.state.fields.name);
			if (this.state.fields.phone !== '' && typeof this.state.fields.phone !== "undefined") {
				postdata.append("phone", this.state.fields.phone);
			}
			postdata.append("fk_store_id", store_id);
			postdata.append("fk_member_id", fk_member_id);
			postdata.append("changepwd", this.state.changepwd);
			postdata.append("prod_id", prod_id);
			if(this.state.changepwd === 1){
				postdata.append("currentpassword", this.state.fields.currentpassword);
				postdata.append("newpassword", this.state.fields.newpassword);
			}
			this.setState({ cont_loader: 1 });

			fetch(api_url + "/api/profileupdate", {
				method: "POST",
				body: postdata
			})
			.then(res => res.json())
			.then(
				(result) => {
					this.setState({ cont_loader: 0 });
					if(result.status === 200){
						
						this.setState({ smsg: result.smsg });
						setTimeout(
							function() {
								this.setState({ smsg: '' })
							}
							.bind(this),
							3000
						);
					}else{
						this.setState({ error_msg: result.error_msg });
						
						setTimeout(
							function() {
								this.setState({ error_msg: '' })
							}
							.bind(this),
							3000
						);
					}


				},
				(error) => {
					this.setState({

					});
				}
			)
			
		}	
		
	}	
	render() {

		const themecolor = this.props.themecolor;	
		const langv = JSON.parse(localStorage.getItem('langv'));
		
		const stylesv2 = {
			box_bg: {
				backgroundColor: themecolor.box_bg_col,
				marginTop:'12%',
			},
			ms_btn: {
				backgroundColor: themecolor.btn_bg_color,
				color: themecolor.btn_text_color,
				border: "1px solid " + themecolor.btn_bg_color,
			},
			ms_textcol: {
				color: themecolor.text_color,
			},
			re_box_bg: {
				backgroundColor: themecolor.box_bg_col
			},
		};
		

		return (
			<div>
				
					
					<div>
						<Menu themecolor={this.props.themecolor} active_menu ={this.state.active_menu} logincheck={this.props.logincheck} />
					
					</div>
				
				<form name="contactform" className="contactform" onSubmit={this.profileupdate.bind(this)}>
								
					<div className="row">
						<div className="col-md-4"></div>
						<div className="col-md-4">
							<div style={stylesv2.box_bg} className="div-popup-box">
								<h4 style={stylesv2.ms_textcol} className="colorwhite txt-center"><i className="fa fa-user"></i> {langv.my_profile}</h4>
								{this.state.error_msg !== ''  &&
								<div className="alert alert-danger fs14" role="alert">
								  {this.state.error_msg}
								</div>
								}
								{this.state.smsg !== ''  &&
								<div className="alert alert-success fs14" role="alert">
								  {this.state.smsg}
								</div>
								}
								
								
								
								<div className="form-group">
									<label htmlFor="txt-emailq" style={stylesv2.ms_textcol} dangerouslySetInnerHTML={{ __html: langv.name_ls }}></label>
									<input autoComplete="nope" id="txt-emailq" type="text" className={"form-control " + (this.state.errors['name'] === 1 ? 'err_bor' : '')} name="name" onChange={this.handleChange1.bind(this, "name")} value={this.state.fields["name"]} placeholder="" />
								</div>
								<div className="form-group">
									<label htmlFor="txt-emailq" style={stylesv2.ms_textcol} dangerouslySetInnerHTML={{ __html: langv.email_ls }}></label>
									<input autoComplete="nope"  readOnly={true}  id="txt-emailq" type="email" className={"form-control " + (this.state.errors['email'] === 1 ? 'err_bor' : '')} name="email" onChange={this.handleChange1.bind(this, "email")} value={this.state.fields["email"]} placeholder="" />
								</div>
								<div className="form-group">
									<label style={stylesv2.ms_textcol}>{langv.phone_ls}</label>
									<PhoneInput
										international
										countryCallingCodeEditable={false}
										defaultCountry='US'
										value={(this.state.fields["phone"] !== '' ? this.state.fields["phone"] : '')}
										onChange={this.phoneChange}
										className={"form-control " + (this.state.errors['phone'] === 1 ? 'err_bor' : '')}
										autoComplete="nope"
									/>

								</div>
								{this.state.changepwd === 0   &&
								<div  style={{ marginTop:3+'%'}} className="col-12 form-group txt-left pr-0 pl-0">
									<button style={stylesv2.ms_btn} onClick={this.changePwdfn} className='btn btn-reward-mob-continue' type="button" value="Change Password" id="cPassword"><i className="fa fa-lock"></i>&nbsp;&nbsp;{langv.change_password}</button>
								</div>
								}
								{this.state.changepwd === 1   &&
								<div>
									<div className="form-group">
										<label style={stylesv2.ms_textcol} htmlFor="txt-currentpassword" dangerouslySetInnerHTML={{ __html: langv.current_password }}></label>
										<input autoComplete="nope" id="txt-currentpassword" type="password" className={"form-control " + (this.state.errors['currentpassword'] === 1 ? 'err_bor' : '')} name="currentpassword" onChange={this.handleChange1.bind(this, "currentpassword")} value={this.state.fields["currentpassword"]} placeholder=""  />
									</div>
									<div className="form-group">
										<label style={stylesv2.ms_textcol} htmlFor="txt-newpassword" dangerouslySetInnerHTML={{ __html: langv.new_password }}></label>
										<input autoComplete="nope" id="txt-newpassword" type="password" className={"form-control " + (this.state.errors['newpassword'] === 1 ? 'err_bor' : '')} name="newpassword" onChange={this.handleChange1.bind(this, "newpassword")} value={this.state.fields["newpassword"]} placeholder=""  />
									</div>
									<div className="form-group">
										<label style={stylesv2.ms_textcol} htmlFor="txt-confpassword" dangerouslySetInnerHTML={{ __html: langv.confirm_password }}></label>
										<input autoComplete="nope" id="txt-confpassword" type="password" className={"form-control " + (this.state.errors['confpassword'] === 1 ? 'err_bor' : '')} name="confpassword" onChange={this.handleChange1.bind(this, "confpassword")} value={this.state.fields["confpassword"]} placeholder=""  />
									</div>
								</div>
								}
								
								
								<div  className="form-group txt-center">
									<button style={stylesv2.ms_btn} className='btn btn-reward-mob-continue' type="submit" id="submit" value="Submit"> <i class="fa fa-save"></i> {langv.update}  {this.state.cont_loader === 1 && <i className="fa fa-spinner fa-spin"></i>}</button>
								</div>
								
								
								
							</div>
						</div>
					</div>
				</form>

			</div>



		);
	}
}



export default MyProfile;

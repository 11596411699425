import React from "react";
import { api_url } from '../config';
import '../css/gifttemplate.css';
import Menu from '../components/Menu';
class GiftList extends React.Component {
	constructor(props) {
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		super(props);
		this.props.updateRetarget(store_id, 'reward');
		this.props.logincheck('islogin');
		this.state = {
			store_det: '',
			store_id: store_id,
			prod_id: prod_id,
	

		}
		
		

	}
	

	
	
	
	componentDidMount() {
		const postdata = new FormData();
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		postdata.append("fk_store_id", store_id);
		postdata.append("prod_id", prod_id);
		postdata.append("fk_member_id", fk_member_id);
		let api_url1;
		api_url1 = api_url + "/api/giftlist";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (result.status === 200) {

						this.setState({ store_det: result });
			
					}


				},
				(error) => {

				}

			)


	}




	render() {
		  
		const themecolor = this.props.themecolor;
		const langv = JSON.parse(localStorage.getItem('langv'));
	
		const styles = {
			barbg: {
				border: "1px solid " + themecolor.menu_bg_color,
				backgroundColor: themecolor.menu_bg_color,
				color: themecolor.menu_text_color
			},
			menutxcol: {
				color: themecolor.menu_text_color,
				'&:hover': {
					backgroundColor: themecolor.menu_text_color,
				},
			},
			
			menutxcol_active: {
				backgroundColor: themecolor.menu_text_color,
				color:themecolor.menu_bg_color,
				padding: '15px 10px',
				borderRadius: '5px 5px 0 0',
				
			}
		};
		
		console.log("AAA"+themecolor);
  //library.add(faCoffee)
		return (
				<div>
					<div>
							<Menu themecolor={this.props.themecolor}  logincheck={this.props.logincheck} />
					</div>
					<div className="container">
					
					</div>
				</div>


		);
	}
}


export default GiftList;

import React from "react";
import { api_url } from '../config';
import '../css/stamp_page.css';
import 'react-phone-number-input/style.css';
import Menu from '../components/Menu';
class RewardCash extends React.Component {
	constructor(props) {

		super(props);
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		this.props.updateRetarget(store_id, 'reward');
		
		
		this.props.logincheck('islogin');
		this.state = {
			store_id: store_id,
			prod_id: prod_id,
			reward_cash:[],
			points:0,
			convamtbtn:'',
			loyalty_points:'',
			loyalty_amt:[],
			active_menu: 'rewardcash',

		}
		
	}


	componentDidMount() {
	
	
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const fk_member_id = localStorage.getItem("fk_member_id");

		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("prod_id", prod_id);
		postdata.append("fk_member_id", fk_member_id);
		//console.log(langv);
		let api_url1;
		api_url1 = api_url + "/api/rewardcash";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (result.status === 200) {
						
						this.setState({
							 reward_cash: result.reward_cash,
							 points: result.points,
							 loyalty_points: result.loyalty_points,
							 loyalty_amt: result.loyalty_amt,
							 convamtbtn: result.convamtbtn,
						})  

						
					} else {
						
					}


				},
				(error) => {

				}

			)

	}
	convertcash(){
		
		const store_id = localStorage.getItem("store_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("fk_member_id", fk_member_id);
	
		let api_url1;
		console.log(1);
		api_url1 = api_url + "/api/convertcash";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
		.then(res => res.json())
		.then(
			(result) => {
				if (result.status === 200) {
					
					this.setState({
						reward_cash: result.reward_cash,
						points: result.points,
						convamtbtn: result.convamtbtn,
					})  

					  
				}else{
					
				}
			},
			(error) => {

			}

		) 
	}
	
	render() {

		const themecolor = this.props.themecolor;	
		const langv = JSON.parse(localStorage.getItem('langv'));
		
		const stylesv2 = {
			box_bg: {
				backgroundColor: themecolor.box_bg_col,
				marginTop:'12%',
			},
			pts_bg: {
				backgroundColor: themecolor.thumbs_icon_color,
				color: themecolor.thumbs_icon_bg,
			},
			ms_btn: {
				backgroundColor: themecolor.btn_bg_color,
				color: themecolor.btn_text_color,
				border: "1px solid " + themecolor.btn_bg_color,
			},
			ms_textcol: {
				color: themecolor.text_color,
			},
			re_box_bg: {
				backgroundColor: themecolor.box_bg_col
			},
			trophy_icon: {
				color: themecolor.thumbs_icon_color,
				textAlign:'center',
				fontSize:'230px'
			},
			trophy_circle_bor: {
				border: '2px solid '+ themecolor.thumbs_icon_color,
			
			},
			creditdesc:{
				color: themecolor.text_color,
				border: "1px dashed  " + themecolor.text_color,
				
			},
		};
		

		return (
			<div>
				
					
				<div>
					<Menu themecolor={this.props.themecolor} active_menu ={this.state.active_menu} logincheck={this.props.logincheck} />
				
				</div>
				 <div className="container">
				 <div className="row">
					<div  className="col-md-12 div-points">
					  <p className="earn_pts"><strong style={stylesv2.ms_textcol}>{langv.reward_points}</strong></p>	
					  <div className="reward-points-con">
						 
						<div style={stylesv2.pts_bg} className="reward-points">
						  {this.state.points}
						</div>
					  </div>
					  {this.state.loyalty_points !== '' &&
					  <div  className="credit-desc-con">
						<div style={stylesv2.creditdesc} className="credit-desc">{this.state.loyalty_points} {langv.reward_points} = {this.state.loyalty_amt.amt_symbol}</div>
					  </div>
					  }
					</div>
				  </div>
				<div className="row">	
					<div className="row">
						<div className="col-md-12 div-points">
							<p className="earn_pts"><strong style={stylesv2.ms_textcol}> {langv.reward_cash}: {this.state.reward_cash.amt_symbol}</strong></p>
						</div>
						
					</div>
				</div>
				
				<div className="row m_bot3">
					{this.state.convamtbtn !== '' &&
					 <div className="col-md-4 offset-md-4">
						<button className="btn-payment"  onClick={()=>{this.convertcash()}} style={stylesv2.ms_btn} type="button"><i className="fa fa-money"></i> {langv.convert_reward_cash}</button>
					</div>
					}
				    <div className="col-md-12 div-points">
						<p style={stylesv2.ms_textcol} className="reward_cash_txt">{langv.ex_reward_pts}</p>
					</div>
				</div>
				</div>
				
			</div>



		);
	}
}



export default RewardCash;

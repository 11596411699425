import React from "react";
import { api_url } from '../config';
import cookie from 'react-cookies';
import Template1 from './template/Template1';
import '../css/template.css';
import Menu from '../components/Menu';
class ViewRewards extends React.Component {

	constructor(props) {

		super(props);
		console.log(props);
		const loginmode_sess = localStorage.getItem('loginmode');
		const store_id = this.props.match.params.store_id;
		var loginmode;
		if(loginmode_sess === null){
			loginmode = 'no';
		}else{
			loginmode = loginmode_sess;
		}
		this.props.logincheck('islogin');
		this.props.updateRetarget(store_id, 'reward');
		this.state = {
			rewards: [],
			rew_ls: '',
			store_url: '',
			email_pop: 0,
			rew_pass_mode: 'all',
			header_bg_color: '#009688',
			header_text_color: '#ffffff',
			loginmode: loginmode,
			active_menu:'myrewards'
		}
		this.redeem = this.redeem.bind(this);
		//this.showinfo = this.showinfo.bind(this);
		//const store_id = this.props.match.params.store_id;
		//console.log();
		//console.log(this.props);
		//this.load_rewards = this.load_rewards.bind(this);
	}

	componentDidMount() {
		/* if(this.props.location.state.body_color !== ''){
		document.body.style.backgroundColor = this.props.location.state.body_color;	
		} */

		this.load_rewards('all');
	}
	backrewardpage() {
		window.location.href = this.state.store_url;

	}
	close_email_pop() {

		this.setState({
			email_pop: 0
		})
	}
	load_rewards(pass_mode = null) {

		const store_id = this.props.match.params.store_id;
		const prod_id = this.props.match.params.prod_id;

		//const fk_member_id = cookie.load('memberCookie_' + store_id);
		const fk_member_id = localStorage.getItem('fk_member_id');

		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("prod_id", prod_id);
		postdata.append("fk_member_id", fk_member_id);
		postdata.append("pass_mode", pass_mode);
		let api_url1;
		api_url1 = api_url + "/api/viewrewards";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (result.status === 200) {
						this.setState({
							rewards: result.re,
							rew_ls: result.ls,
							rew_suc_staus: result.status,
							store_url: result.store_url,
							rew_pass_mode: pass_mode,
							header_bg_color: result.header_bg_color

						})
						if (pass_mode === 'redeemed') {

							this.setState({
								email_pop: 1
							})
						}

						//document.body.style.backgroundColor = result.body_color;
					} else {
						this.setState({
							rew_ls: result.ls,
							rew_suc_staus: result.status
						})

					}


				},
				(error) => {

				}

			)




	}


	redeem(fk_reward_id) {
		const fk_store_id = this.props.match.params.store_id;
		const prod_id = this.props.match.params.prod_id;
		const fk_member_id = cookie.load('memberCookie_' + fk_store_id);
		const postdata = new FormData();
		postdata.append("fk_store_id", fk_store_id);
		postdata.append("fk_member_id", fk_member_id);
		postdata.append("fk_reward_id", fk_reward_id);
		postdata.append("prod_id", prod_id);
		fetch(api_url + "/api/redeem", {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {

					this.load_rewards('redeemed');

				},
				(error) => {

				}

			)

	}






	render() {
		const themecolor = this.props.themecolor;
		
		const stylesv2 = {
			
			ms_btn: {
				backgroundColor: themecolor.btn_bg_color,
				color: themecolor.btn_text_color,
				border: "1px solid " + themecolor.btn_bg_color,
				fontWeight: '400'
			},
			ms_btn_active: {
				backgroundColor: themecolor.btn_bg_color,
				color: themecolor.btn_text_color,
				border: "1px solid " + themecolor.btn_bg_color,
				fontWeight:"bold"
			},
			ms_textcol: {
				color: themecolor.text_color,
			}
			
		};	
		return (
			<div>
				<div>
						<Menu themecolor={this.props.themecolor} active_menu ={this.state.active_menu} logincheck={this.props.logincheck} />
					
				</div>
				<div className="container">
				
				{
					this.state.rew_suc_staus === '' ? (
						<div className="row m0">
							<div className="col-md-12 div-all-rewards">
								<h5 dangerouslySetInnerHTML={{ __html: this.state.rew_ls.loadibg_ls }}></h5>

							</div>
						</div>

					) : (this.state.rew_suc_staus === 200) ? (
						<div className="">
							<div style ={{display:'none' }} className="row m0">
								<div className="col-md-12 div-all-rewards">
									<h5 style={{ display: "none" }} dangerouslySetInnerHTML={{ __html: this.state.rew_ls.all_rewards_ls }}></h5>
									<button onClick={() => this.backrewardpage()} className="btn btn-back-arrow">
										<i className="fa fa-arrow-circle-o-left"></i>
									</button>
								</div>
							</div>

							
							<div className="row m0 m_bot2">
								<div className="col-md-6"></div>
								<div className="col-md-3 col-6">
									<button style={((this.state.rew_pass_mode === 'all' || this.state.rew_pass_mode === 'redeemed') ? stylesv2.ms_btn_active : stylesv2.ms_btn)} onClick={() => this.load_rewards('all')} className="btn-ow btn-reward-mob">{this.state.rew_ls.all_rewards_ls}</button>
								</div>
								<div className="col-md-3 col-6">
									<button style={(this.state.rew_pass_mode === 'earned' ? stylesv2.ms_btn_active : stylesv2.ms_btn)} onClick={() => this.load_rewards('earned')} className="btn-ow btn-reward-mob">{this.state.rew_ls.myrewards}</button>
								</div>
							</div>
							{this.state.email_pop === 1 &&
								<div class="div-template-visit-popup">
									<div className="div-template-popup">
										<button onClick={(e) => this.close_email_pop()} type="button" class="btn-pop-close"><i class="fa fa-times"></i></button><p class="div-pop-desc">{this.state.rew_ls.visit_email_det}</p>
									</div>
								</div>
							}
							<div className="row m0">
							{this.state.rewards.map(reward => (
									
									<Template1 reward={reward} redeem={this.redeem} rew_ls={this.state.rew_ls} />
									
								
							))}
							</div>
							<div style ={{display:'none' }} className="row m0">
								<div className="col-md-12 m_bot2">
									<button style={stylesv2.ms_btn_active} onClick={() => this.backrewardpage()} className="btn-ow btn-reward-mob">
										<i className="fa fa-arrow-circle-o-left"></i> {this.state.rew_ls.back_to_dashboard}
									</button>
								</div>
							</div>
						</div>
					) : (this.state.rew_suc_staus === 201) ? (

						<div className="row m0">
							<div className="col-md-12 div-all-rewards">
								<h5 style={stylesv2.ms_textcol}>{this.state.rew_ls.no_reward_found}</h5>
							</div>
						</div>

					) : (this.state.rew_suc_staus === 301) ? (

						<div className="row m0">
							<div className="col-md-4"></div>
							<div className="col-md-4 div-all-rewards">
								<h5 style={stylesv2.ms_textcol}>{this.state.rew_ls.no_reward_found}</h5>
								<button style={stylesv2.ms_btn_active} onClick={() => this.load_rewards('all')} className="btn-ow btn-reward-mob">
									<i className="fa fa-arrow-circle-o-left"></i> {this.state.rew_ls.back}
								</button>
							</div>
						</div>

					) : (

						<div></div>

					)
					
				}
				</div>
				
				
			</div>

		);
	}
}

export default ViewRewards;
import React from "react";
//import { connect } from 'react-redux';
import { api_url } from '../config';
import '../css/prodtemplate.css';
import '../css/category.css';

import Menu from '../components/Menu';
import MyProductSub from '../components/MyProductSub';
import { Link } from "react-router-dom";
class Category extends React.Component {
	constructor(props) {
		super(props);
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const loginmode_sess = localStorage.getItem('loginmode');
		var loginmode;
		if (loginmode_sess === null) {
			loginmode = 'no';
		} else {
			loginmode = loginmode_sess;
		}
		this.props.updateRetarget(store_id, 'reward');
		this.props.logincheck('islogin');
		this.state = {
			store_id: store_id,
			prod_id: prod_id,
			loginmode: loginmode,
			categories: [],
			products: [],
			rstatus: '',
			active_menu: 'myproducts',
			category_id: '',
		}
		this.goBack = this.goBack.bind(this);
		this.loadcategory = this.loadcategory.bind(this);
		

	}
	goBack() {
		this.props.history.goBack();
	} 
	componentDidMount() {
			const category_id = this.props.match.params.category_id;
			
			if (category_id !== null && typeof category_id !== 'undefined') {
				this.loadcategory(category_id);
			}else{
				this.loadcategory();
			}

	}
	
	componentWillReceiveProps(nextProps) {
		
		if (nextProps.match.params.category_id !== this.props.match.params.category_id) {
		    const category_id = nextProps.match.params.category_id;
		    if (category_id !== null && typeof category_id !== 'undefined') {
				this.loadcategory(category_id);
			}else{
				this.loadcategory();
			}
		}
    }
	 
	loadcategory(category_id=null){
		
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		//const category_id = this.props.match.params.category_id;

		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("prod_id", prod_id);
		postdata.append("fk_member_id", fk_member_id);
		if (category_id !== null && typeof category_id !== 'undefined') {
			postdata.append("category_id", category_id);
			this.setState({
				category_id: category_id,
			})
		}
		let api_url1;
		api_url1 = api_url + "/api/mycategory";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (result.status === 200) {
						this.setState({
							categories: result.category,
							products: result.products,
							rstatus: result.status,
						})
						//document.body.style.backgroundColor = result.body_color;
					} else {



					}


				},
				(error) => {

				}

			)
		
		
	}



	render() {
		const langv = JSON.parse(localStorage.getItem('langv'));
		const themecolor = this.props.themecolor;
		const stylesv2 = {
			category_bg: {
				backgroundColor: themecolor.category_bg_color
			},
			category_txt_col: {
				color: themecolor.category_text_color
			},
			ms_textcol: {
				color: themecolor.text_color,
			},
			noresult_cls: {
				color: themecolor.text_color,
				fontSize: "23px",
				textAlign: 'Center',
				marginTop: '15%'
			}
		};

		return (
			<div>
				
				<div>
					<Menu themecolor={this.props.themecolor} active_menu ={this.state.active_menu} logincheck={this.props.logincheck} />

				</div>
				<div className="container">
					{this.state.category_id !== '' &&
					<span onClick={this.goBack} style={stylesv2.ms_textcol} className="back_cls prod_theme"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp; {langv.back}</span>
					}
					
					{(this.state.categories.length === 0 && this.state.products.length === 0 && this.state.rstatus === 200) &&

						<p style={stylesv2.noresult_cls}>{langv.no_data_found}</p>

					}
					{this.state.rstatus === 200 &&
					<div>
						{this.state.categories.length > 0 &&
							<div className="row m_top2">
								{this.state.categories.map(category => (

									<div className="col-md-4 col-12" key={category.keygen}>
										{category.target === 1 &&
											<a  target='_blank'   rel="noreferrer" href={category.page_link} className="catlink">
												<div style={stylesv2.category_bg} className="div-cat-box">
													<img src={category.image} alt="noimage" />
													<p style={stylesv2.category_txt_col} className="prod_theme category_title">{category.name}</p>
												</div>
											</a>
										}
										{category.target === 0 &&
											<Link to={category.page_link}  className="catlink">
												<div style={stylesv2.category_bg} className="div-cat-box">
													<img src={category.image} alt="noimage" />
													<p style={stylesv2.category_txt_col} className="prod_theme category_title">{category.name}</p>
												</div>
											</Link>
										}
										
									</div>

								))}
							</div>
						}


						{(this.state.categories.length > 0 || this.state.products.length > 0) &&
							<MyProductSub products={this.state.products} themecolor={this.props.themecolor} />
						}
					</div>
					}
				</div>

			</div>

		);
	}

}


export default Category;

import React from "react";
import Template1 from './template_gift/Template1';
import { api_url } from '../config';
import '../css/gifttemplate.css';
import 'font-awesome/css/font-awesome.min.css';
import cookie from 'react-cookies';

class Giftpage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			giftdet: '',
			lang: {},
			template_id: '',
			res_status: '',
			coupon_id: ''
		}


	}
	/* iframeResize() {
	var body = document.body;
    var html = document.documentElement;

	 var height = Math.max( body.scrollHeight, body.offsetHeight, 
                       html.clientHeight, html.scrollHeight, html.offsetHeight );
					   
	window.parent.postMessage("resize::"+height,"*");
	} */

	componentDidMount() {
		
		const gift_id = this.props.match.params.gift_id;
		this.props.updateRetarget(gift_id, 'gift');

		let visitCookiegift = cookie.load('visitCookiegift_' + gift_id);
		if (visitCookiegift === '' || typeof visitCookiegift === 'undefined') {
			this.CookieSet('visitCookiegift_' + gift_id, 1, 'visitCookiegift', 24);
			visitCookiegift = 1;
		} else {
			visitCookiegift = '';
		}


		const postdata = new FormData();
		postdata.append("gift_id", gift_id);
		postdata.append("visitcookiegift", visitCookiegift);
		fetch(api_url + "/api/fetchgiftdet", {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					
					if (result.status === 200) {

						this.setState({
							giftdet: result,
							template_id: result.template_id,
							gift_id: gift_id,
							res_status: result.status,
							lang: JSON.parse(result.language)

						});
						//localStorage.setItem('mrk', 1);
					} else {

						this.setState({
							res_status: result.status,
							lang: JSON.parse(result.language)
						});
						

					}
					if(result.body_bg_color !== ''){
						document.body.style.backgroundColor = result.body_bg_color;
					}	


				},

				(error) => {
					this.setState({

					});
				}
			)
			//setInterval(this.iframeResize(), 1000);
			
	}
	CookieSet(cookiename, cookievalue, action, exp) {

		const expires = new Date();
		const exp1 = 1000 * 60 * 60 * exp;
		const maxAge = 60 * 60 * exp;
		expires.setDate(Date.now() + exp1);
		cookie.save(cookiename, cookievalue, { path: '/', expires, maxAge: maxAge });


	}



	render() {
		const styleg = this.state.giftdet;
		const styletmp = {
			bg_box: {
				backgroundImage: "url(" + styleg.gift_image + ")"
			},
			top_bg_col2: {

				backgroundColor: styleg.top_bg_col2
			},
			top_bg_col1: {

				backgroundColor: styleg.top_bg_col1
			},
			top_bg_col199: {
				backgroundColor: styleg.top_bg_col1 + '66'
			},
			top_text_col: {
				color: styleg.top_text_col
			},
			mid_bg_col: {
				/* backgroundColor: styleg.mid_bg_col + "66" */
				backgroundColor: styleg.mid_bg_col
			},
			mid_text_col: {
				color: styleg.mid_text_col
			},
			mid_btn: {
				backgroundColor: styleg.mid_btn_bg_col,
				color: styleg.mid_btn_text_col,
				border: '1px solid ' + styleg.mid_btn_text_col
			},
			mid_btn_bg: {
				backgroundColor: styleg.mid_btn_bg_col,
				color: styleg.mid_btn_text_col,

			},
			mid_btn_txt_col: {

				color: styleg.mid_btn_text_col

			},
			bot_bg_col: {
				backgroundColor: styleg.bot_bg_col
			},
			bot_text_col: {
				color: styleg.bot_text_col
			},
			bot_btn: {
				backgroundColor: styleg.bot_btn_bg_col,
				color: styleg.bot_btn_text_col,
				border: '1px solid ' + styleg.bot_btn_text_col
			},
			br_top_bot_100: {
				borderTop: '140px solid ' + styleg.top_bg_col1,
				borderBottom: '137px solid ' + styleg.top_bg_col1
			},
			br_left_8: {
				borderLeft: '8px solid ' + styleg.top_bg_col1
			},
			br_left_150: {
				borderLeft: '150px solid ' + styleg.top_bg_col1
			},
			br_top_170: {
				borderTop: '170px solid ' + styleg.top_bg_col1
			},
			br_top_275: {
				borderTop: '275px solid ' + styleg.top_bg_col1
			}
		};


		return (
			<div>
				{(this.state.res_status === 200) ? (
					<div>
						{
							(this.state.template_id === 1) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-one mid_bg_col">
												<div style={styletmp.bg_box} className="div-gift-template m_bot5">
													<div style={styletmp.top_bg_col1} className="div-template-header top_bg_col1">
														<img id="gift_logo" src={this.state.giftdet.gift_logo} alt="logo"></img>
													</div>

													<div style={styletmp.top_bg_col2} className="div-template-footer top_bg_col2">
														<span style={styletmp.top_text_col} className="top_text_col">{this.state.giftdet.name}</span>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col} className="mid_text_col">{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 2) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col}  className="div-gift-template-two div-template-gift-check br15px">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div style={styletmp.top_bg_col1} className="div-template-footer">
														<div className="row">
															<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="col-md-6 text-right">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 3) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-three div-template-gift-check br15px">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
															<div className="triangle-right" style={styletmp.br_top_bot_100}></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 4) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-four">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-5 col-md-5 col-sm-5 col-5" style={styletmp.top_bg_col1}>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
															<div className="triangle-right" style={styletmp.br_left_8}></div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 5) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-five">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
															<div className="half-circle" style={styletmp.top_bg_col1}></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 6) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-six">
												<div className="div-gift-template m_bot5" style={styletmp.top_bg_col1}>
													<div className="div-template-child">
														<div className="div-logo">
															<img src={this.state.giftdet.gift_logo} alt="logo"></img>
														</div>
														<div className="div-card-header">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 7) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seven">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-template-footer" style={styletmp.top_bg_col1}>
														<div className="row">
															<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="col-md-6 text-right">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 8) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-eight">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0 pl0" style={styletmp.top_bg_col1}>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 9) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-nine">
												<div className="div-gift-template m_bot5">
													<div className="div-template-child" style={styletmp.top_bg_col1}>
														<div className="div-logo">
															<img src={this.state.giftdet.gift_logo} alt="logo"></img>
														</div>
														<div className="div-card-header">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 10) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-ten">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-template-footer" style={styletmp.top_bg_col1}>
														<div className="row">
															<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="col-md-6 text-right">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 11) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-eleven">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
															<div className="div-left" style={styletmp.br_top_275}></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 12) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twelve">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
															<div className="div-logo" style={styletmp.top_bg_col1}>
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
															<div className="div-right" style={styletmp.br_top_170}></div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 13) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirteen">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-template-child" style={styletmp.top_bg_col199}>
														<div className="div-logo">
															<img src={this.state.giftdet.gift_logo} alt="logo"></img>
														</div>
														<div className="div-card-header">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 14) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourteen">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pt10" style={styletmp.top_bg_col1}>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
															<div className="div-points">
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 15) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fifteen">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-8 col-md-8 col-sm-8 col-8">
															<div className="div-left" style={styletmp.br_top_275}></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 16) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixteen">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0" style={styletmp.top_bg_col1}>
															<div className="div-points">
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 17) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventeen">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
															<div className="row">
																<div className="col-xl-8 col-md-8 col-sm-8 col-8" style={styletmp.top_bg_col1}>
																	<div className="div-logo">
																		<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<p style={styletmp.top_text_col}>{this.state.giftdet.name}</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 18) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-eightteen">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pt10">
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<p style={styletmp.top_text_col}>{this.state.giftdet.name}</p>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 19) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-ninteen">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0 pl0" style={styletmp.top_bg_col1}>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 20) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twenty">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-parent" style={styletmp.top_bg_col1}>
														<div className="div-logo">
															<img src={this.state.giftdet.gift_logo} alt="logo"></img>
														</div>
														<div className="div-card-header">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 21) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twentyone">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-parent bg_col1">
														<div className="div-logo" style={styletmp.top_bg_col1}>
															<img src={this.state.giftdet.gift_logo} alt="logo"></img>
														</div>
														<div className="div-card-header" style={styletmp.top_bg_col1}>
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 22) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twentytwo">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0 pl0" style={styletmp.top_bg_col1}>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 23) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twentythree">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-circle" style={styletmp.top_bg_col1}>
													</div>
													<div className="div-logo">
														<img src={this.state.giftdet.gift_logo} alt="logo"></img>
													</div>
													<div className="div-card-header">
														<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 24) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twentyfour">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
															<div className="div-left" style={styletmp.br_top_275}></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 25) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twentyfive">
												<div className="div-gift-template m_bot5 bg_box" style={styletmp.bg_box}>
													<div className="div-template-footer" style={styletmp.top_bg_col1}>
														<div className="row">
															<div className="col-md-6">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
															<div className="col-md-6 text-right">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 26) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twentysix">
												<div className="div-gift-template m_bot5 bg_box" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0">
															<div className="triangle-right" style={styletmp.br_top_bot_100}></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 27) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twentyseven">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">
															<div className="triangle-right"></div>
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5" style={styletmp.top_bg_col1}>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>

													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 28) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twentyeight">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-4 col-md-4 col-sm-4 col-4 pr0" style={styletmp.top_bg_col1}>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-1 col-md-1 col-sm-1 col-1 pl0">
															<div className="triangle-left"></div>
														</div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 29) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-twentynine">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 div-twentynine-content">
															<div className="row">
																<div className="col-xl-8 col-md-8 col-sm-8 col-8">
																	<div className="div-logo">
																		<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																	</div>
																</div>
																<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
																	<div className="semicircle-left semicircle-left-curve"></div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 30) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirty">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
															<div className="div-left"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 31) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirtyone">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="col-md-6 text-right">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 32) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirtytwo">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-template-header">
														<img src={this.state.giftdet.gift_logo} alt="logo"></img>
													</div>
													<div className="div-template-footer">
														<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 33) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirtythree">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-2 col-md-2 col-sm-2 col-2">
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
															<div className="row">
																<div  style={styletmp.top_bg_col1} className="col-xl-8 col-md-8 col-sm-8 col-8 pr0 bg_col1">
																	<div className="div-logo">
																		<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 34) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirtyfour">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-5 col-md-5 col-sm-5 col-5">
															<div className="div-left"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />

										</div>
									</div>
								</div>
							) : (this.state.template_id === 35) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirtyfive">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-12">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 36) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirtysix">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
															<div style={styletmp.br_left_150} className="triangle-left"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-re-points">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 37) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirtyseven">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-5 col-md-5 col-sm-5 col-5">
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
															<div className="triangle-right"></div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 38) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirtyeight">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-5 col-5" style={styletmp.top_bg_col1}>
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
															<div className="col-md-7 col-7">
																<div style={styletmp.top_bg_col1}>
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 39) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-thirtynine">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5" style={styletmp.top_bg_col1}>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 40) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourty">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-5 col-md-5 col-sm-5 col-5"></div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 div-twentynine-content">
															<div className="row">
																<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
																	<div className="semicircle-right semicircle-right-curve"></div>
																</div>
																<div className="col-xl-8 col-md-8 col-sm-8 col-8">
																	<div className="div-logo">
																		<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 41) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourtyone">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="col-md-6 text-right">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 42) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourtytwo">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="col-md-6 text-right">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 43) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourtythree">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">                   
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0 pl0">
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 44) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourtyfour">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">
														</div>          
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0">
															<div className="triangle-right"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 45) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourtyfive">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}>
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">
														</div>                  
														<div className="col-xl-5 col-md-5 col-sm-5 col-5">                      
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>  
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 46) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourtysix">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">           
														<div className="col-xl-4 col-md-4 col-sm-4 col-4 pr0">
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-1 col-md-1 col-sm-1 col-1 pl0">
															<div className="triangle-left"></div>
														</div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">
														</div>
													</div> 
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 47) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourtyseven">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">           
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
															<div className="half-circle"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div> 
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 48) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourtyeight">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0 bg_col1">                
															<div className="div-points">
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
															</div>
															<div className="div-card-header">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">                      
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 49) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fourtynine">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="div-diamond"></div>                               
														<div className="div-items text-center">
															<div className="div-points">
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 50) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fifty">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 

													<div className="row">                                        
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0">
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-1 col-md-1 col-sm-1 col-1 pl0">
															<div className="triangle-left"></div>
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 51) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fiftyone">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pt10 bg_col1">
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>                  
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 52) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fiftytwo">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="col-md-6 text-right">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
	
							) : (this.state.template_id === 53) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fiftythree">
												<div  className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-header">
														<img src={this.state.giftdet.gift_logo} alt="logo"></img>
													</div>
													<div className="div-template-footer">
														<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 54) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fiftyfour">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-2 col-md-2 col-sm-2 col-2">
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
															<div className="row">
																<div className="col-xl-8 col-md-8 col-sm-8 col-8 pr0 bg_col1">
																	<div className="div-logo">
																		<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																	</div>
																</div>
															</div>
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 55) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fiftyfive">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-12">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img> 
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 56) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fiftysix">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="row">                   
															<div className="col-xl-7 col-md-7 col-sm-7 col-7">
															<div className="div-left"></div>
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
																<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																</div>
															</div>
															<div className="col-xl-7 col-md-7 col-sm-7 col-6 pl0">
															</div>                  
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 57) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fiftyseven">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="row">
															<div className="col-xl-5 col-md-5 col-sm-5 col-5">                      
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
																<div className="div-card-header">
																	<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																</div>
															</div>
															<div className="col-xl-7 col-md-7 col-sm-7 col-7 pl0">                  
																<div className="triangle-right"></div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 58) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fiftyeight">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-5 col-5">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
															<div className="col-md-7 col-7">
																<div>
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 59) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-fiftynine">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="col-md-6 text-right">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 60) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixty">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 div-sixty-content">
															<div className="row">
																<div className="col-xl-8 col-md-8 col-sm-8 col-8">
																	<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																	</div>
																</div>
																<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
																	<div className="semicircle-left semicircle-left-curve"></div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>

							) : (this.state.template_id === 61) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixtyone">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">                  
															<div className="triangle-right"></div>
														</div>                  
														<div className="col-xl-5 col-md-5 col-sm-5 col-5">                      
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>   
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 62) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixtytwo">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
															<div className="div-left"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 63) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixtythree">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
															<div className="row">
																<div className="col-xl-8 col-md-8 col-sm-8 col-8 pr0 bg_col1">
																	<div className="div-logo">
																		<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 64) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixtyfour">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">                                        
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
															<div className="triangle-left"></div>
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
															<div className="div-re-points">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>                                        
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 65) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixtyfive">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-circle bg_col1">
													</div>
													<div className="div-logo">
														<img src={this.state.giftdet.gift_logo} alt="logo"></img>
													</div>
													<div className="div-card-header">
														<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 66) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixtysix">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
														</div>
														<div className="col-xl-8 col-md-8 col-sm-8 col-8">
															<div className="div-left"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 67) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixtyseven">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-circle bg_col1">
													</div>
													<div className="div-logo">
														<img src={this.state.giftdet.gift_logo} alt="logo"></img>
													</div>
													<div className="div-card-header">
														<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 68) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixtyeight">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-5 col-md-5 col-sm-5 col-5"></div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 div-twentynine-content">
															<div className="row">
																<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
																	<div className="semicircle-right semicircle-right-curve"></div>
																</div>
																<div className="col-xl-8 col-md-8 col-sm-8 col-8">
																	<div className="div-logo">
																		<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 69) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-sixtynine">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
															<div className="div-left"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 70) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventy">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-header">
														<img src={this.state.giftdet.gift_logo} alt="logo"></img>
													</div>
													<div className="div-template-footer">
														<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 71) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventyone">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">                   
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
														<div className="div-left"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
														</div>                  
													</div> 
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 72) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventytwo">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">
														</div>
														<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0 bg_col1">
															<div className="row">
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
																<div className="div-card-header">
																	<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 73) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventythree">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-5 col-md-5 col-sm-5 col-5"></div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 div-twentynine-content">
															<div className="row">
																<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
																	<div className="semicircle-right semicircle-right-curve"></div>
																</div>
																<div className="col-xl-8 col-md-8 col-sm-8 col-8">
																	<div className="div-logo">
																		<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																	</div>
																</div>
																
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 74) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventyfour">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">                   
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
															<div className="half-circle"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 75) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventyfive">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">                                                                             
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
															<div className="half-circle"></div>
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 76) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventysix">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">             
														<div className="col-xl-5 col-md-5 col-sm-5 col-5">                     
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-7 col-md-7 col-sm-7 col-7 pdlft">              
															<div className="triangle-right"></div>
														</div> 
													</div> 
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 77) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventyseven">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-7 col-md-7 col-sm-7 col-7">                  
															<div className="triangle-right"></div>
														</div>                  
														<div className="col-xl-5 col-md-5 col-sm-5 col-5">                      
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>   
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 78) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventyeight">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="row">
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
															<div className="div-left"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 79) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-seventynine">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
												<div className="row">
													<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
														</div>
														<div className="col-xl-6 col-md-6 col-sm-6 col-6">
															<div className="div-left"></div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 80) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-eighty">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="col-md-6 text-right">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 81) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-eightyone">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="row">
															<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="col-md-6 text-right">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>													
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 82) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-eightytwo">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="row">                                                            
															<div className="col-xl-8 col-md-8 col-sm-8 col-8">
																<div className="div-left"></div>
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
																<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																</div>
															</div>
															<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 83) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-eightythree">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="row">
															<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
															</div>
															<div className="col-xl-8 col-md-8 col-sm-8 col-8">
																<div className="div-left"></div>
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
																<div className="div-card-header">
																	<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 84) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-eightyfour">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">														 
														<div className="div-circle bg_col1">
														</div>
														<div className="div-logo">
															<img src={this.state.giftdet.gift_logo} alt="logo"></img>
														</div>
														<div className="div-card-header">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 85) ? (
								<div className="row m_top2 m0">
									<div className="col-md-12 pl0 pr02">
										<div className="div_template_parent m_bot3 m_rl15">
											<div style={styletmp.mid_bg_col} className="div-gift-template-eightyfive">
												<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<div className="div-template-footer">
														<div className="div-circle bg_col1">
														</div>
														<div className="div-logo">
															<img src={this.state.giftdet.gift_logo} alt="logo"></img>
														</div>
														<div className="div-card-header">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
														</div>
													</div>
												</div>
												<div className="col-md-12 bb">
													<div className="div-footer-description">
														<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
													</div>
												</div>
											</div>
											<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
										</div>
									</div>
								</div>
							) : (this.state.template_id === 86) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-eightysix">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="row">                   
															<div className="col-xl-5 col-md-5 col-sm-5 col-5 pr0">
																<div className="triangle-right"></div>
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
																<div className="div-card-header">
																	<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																</div>
															</div>
															<div className="col-xl-7 col-md-7 col-sm-7 col-7">
															</div>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 87) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-eightyseven">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="row">
															<div className="col-xl-6 col-md-6 col-sm-6 col-6">
															</div>          
															<div className="col-xl-6 col-md-6 col-sm-6 col-6 pr0">
																<div className="triangle-right"></div>
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
																<div className="div-card-header">
																	<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																</div>
															</div>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 88) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-eightyeight">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="row">
															<div className="col-xl-5 col-md-5 col-sm-5 col-5"></div>
															<div className="col-xl-7 col-md-7 col-sm-7 col-7 div-twentynine-content">
																<div className="row">
																	<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
																		<div className="semicircle-right semicircle-right-curve"></div>
																	</div>
																	<div className="col-xl-8 col-md-8 col-sm-8 col-8">
																		<div className="div-logo">
																			<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																		</div>
																		<div className="div-card-header">
																			<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																		</div>
																	</div>
																	
																</div>
															</div>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 89) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-eightynine">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="row">
															<div className="col-xl-7 col-md-7 col-sm-7 col-7 div-sixty-content">
																<div className="row">
																	<div className="col-xl-8 col-md-8 col-sm-8 col-8">
																		<div className="div-logo">
																			<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																		</div>
																		<div className="div-card-header">
																			<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																		</div>
																	</div>
																	<div className="col-xl-4 col-md-4 col-sm-4 col-4 pl0">
																		<div className="semicircle-left semicircle-left-curve"></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 90) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-ninety">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														
														<div className="div-circle bg_col1">
														</div>
														<div className="div-logo">
															<img src={this.state.giftdet.gift_logo} alt="logo"></img>
														</div>
														<div className="div-card-header">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
														</div>
														
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 91) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-ninetyone">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														
														<div className="div-circle bg_col1">
														</div>
														<div className="div-logo">
															<img src={this.state.giftdet.gift_logo} alt="logo"></img>
														</div>
														<div className="div-card-header">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
														</div>
														
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 92) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-ninetytwo">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="row">
															<div className="col-xl-7 col-md-7 col-sm-7 col-7">
															</div>
															<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0 bg_col1">
																<div className="row">
																	<div className="div-logo">
																		<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 93) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-ninetythree">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="row">
															<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0 bg_col1">
																<div className="row">
																	<div className="div-logo">
																		<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																	</div>
																	<div className="div-card-header">
																		<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																	</div>
																</div>
															</div>
															<div className="col-xl-7 col-md-7 col-sm-7 col-7">
															</div>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 94) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-ninetyfour">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="div-template-footer">
															<div className="div-circle bg_col1">
															</div>
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 95) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-ninetyfive">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="row">                   
															<div className="col-xl-6 col-md-6 col-sm-6 col-6">
															<div className="div-left"></div>
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
																<div className="div-card-header">
																	<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																</div>
															</div>
															<div className="col-xl-6 col-md-6 col-sm-6 col-6 pl0">
															</div>                  
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 96) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-ninetysix">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
													<	div className="row">                   
															<div className="col-xl-7 col-md-7 col-sm-7 col-7 pr0">
																<div className="div-logo">
																	<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
															</div>
															<div className="col-xl-5 col-md-5 col-sm-5 col-5 pl0">
																<div className="div-right"></div>
																<div className="div-card-header">
																	<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																</div>
															</div>                  
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 97) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-ninetyseven">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="div-parent">
															<div className="div-logo bg_col1">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header bg_col1">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 98) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-ninetyeight">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="div-parent bg_col1">
															<div className="div-logo">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
															</div>
															<div className="div-card-header">
																<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
															</div>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 99) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-ninetynine">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="div-template-footer">
															<div className="row">
																<div className="col-md-6">
																<img src={this.state.giftdet.gift_logo} alt="logo"></img>
																</div>
																<div className="col-md-6 text-right">
																	<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
																</div>
															</div>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>
								) : (this.state.template_id === 100) ? (
									<div className="row m_top2 m0">
										<div className="col-md-12 pl0 pr02">
											<div className="div_template_parent m_bot3 m_rl15">
												<div style={styletmp.mid_bg_col} className="div-gift-template-hundred">
													<div className="div-gift-template m_bot5" style={styletmp.bg_box}> 
														<div className="div-circle bg_col1">
														</div>
														<div className="div-logo">
															<img src={this.state.giftdet.gift_logo} alt="logo"></img>
														</div>
														<div className="div-card-header">
															<span style={styletmp.top_text_col}>{this.state.giftdet.name}</span>
														</div>
													</div>
													<div className="col-md-12 bb">
														<div className="div-footer-description">
															<p style={styletmp.mid_text_col}>{this.state.giftdet.short_description}</p>
														</div>
													</div>
												</div>
												<Template1 giftdet={this.state.giftdet} lang={this.state.lang} styletmp={styletmp} gift_id={this.state.gift_id} />
											</div>
										</div>
									</div>






															


							) : (
																																																<div></div>
																																															)

						}
					</div>
				) : (this.state.res_status === 202) ? (

					<div>
						<span className="curr_unavail">{this.state.lang.curr_unavail_gift}</span>
						<center><img alt="noimage" style={{width: '80%'}} src={api_url+"/public/img/empty.png"} /></center>
					
					</div>

				) : (
					<div></div>
				)}

			</div>


		);
	}
}


export default Giftpage;

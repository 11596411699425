import React from "react";
//import { connect } from 'react-redux';
import { api_url } from '../config';
import '../css/prodtemplate.css';
import Menu from '../components/Menu';
import MyProductSub from '../components/MyProductSub';
class MyProduct extends React.Component {
	constructor(props) {
		super(props);
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const loginmode_sess = localStorage.getItem('loginmode');
		var loginmode;
		if(loginmode_sess === null){
			loginmode = 'no';
		}else{
			loginmode = loginmode_sess;
		}
		this.props.logincheck('islogin');
		this.props.updateRetarget(store_id, 'reward');
		this.state = {
			store_id: store_id,
			prod_id: prod_id,
			loginmode:loginmode,
			products:[],
			rstatus:'',
			active_menu: 'myproducts',
		
		}	

		//this.child = React.createRef();
		this.goBack = this.goBack.bind(this);
		
		

	}
	goBack() {
		this.props.history.goBack();
	} 
	
	
	componentDidMount() {
		const category_id = this.props.match.params.category_id;
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("prod_id", prod_id);
		postdata.append("fk_member_id", fk_member_id);
		if( typeof category_id !== "undefined"){
			postdata.append("category_id", category_id);
		}
		let api_url1;
		api_url1 = api_url + "/api/myproduct";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
		.then(res => res.json())
		.then(
			(result) => {
				if (result.status === 200) {
					  this.setState({
						  products: result.products,
						  rstatus: result.status,
					  })
					 // document.body.style.backgroundColor = result.body_color;
				} else {
					
				}


			},
			(error) => {

			}

		)
		

	}
	
	
	
	render() {
		const langv = JSON.parse(localStorage.getItem('langv'));
		
		
		 
		/* const media_s =  this.state.galleries.map(function(gallery, i) {

			if(gallery.media_type === 1){
			return (<div className="col-md-4 col-4 m_top2 media_box_cls" key={"gallery_"+media.id}>
						<div className="media_box">	
							<img src={gallery.media_content}  className="img-fluid" />
						</div>
					</div>)
			}
			
		}.bind(this)); */
		const themecolor = this.props.themecolor;
		const stylesv2 = {
			category_bg: {
				backgroundColor: themecolor.category_bg_color
			},
			noresult_cls: {
				color: themecolor.text_color,
				fontSize:"23px",
				textAlign:'Center',
				marginTop: '15%'
			},
			ms_textcol: {
				color: themecolor.text_color,
			},
			
		};	
		
		return (
			<div>
				
				<div>
					<Menu themecolor={this.props.themecolor} active_menu ={this.state.active_menu} logincheck={this.props.logincheck} />
				
				</div>
				<div className="container">
					
					<span onClick={this.goBack} style={stylesv2.ms_textcol} className="back_cls prod_theme"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp; {langv.back}</span>

					
					{(this.state.products.length === 0 && this.state.rstatus === 200) &&
					
							<p style={stylesv2.noresult_cls}>{langv.no_data_found}</p>
					
					}
					{this.state.products.length > 0 &&
						<MyProductSub themecolor={this.props.themecolor} products={this.state.products}  />
					}
				</div>	
				
			</div>

		);
	}

}


export default MyProduct;

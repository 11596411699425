import React from "react";
import { api_url } from '../config';
import '../css/checkout.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckToSlot} from '@fortawesome/free-solid-svg-icons';
import 'font-awesome/css/font-awesome.min.css';
import Menu from '../components/Menu';

class Myorders extends React.Component {
	constructor(props) {

		super(props);
		const store_id = localStorage.getItem("store_id");
		this.props.logincheck('islogin');
		this.props.updateRetarget(store_id, 'reward');
		const loginmode_sess = localStorage.getItem('loginmode');

		var loginmode;
		if (loginmode_sess === null) {
			loginmode = 'no';
		} else {
			loginmode = loginmode_sess;
		}
		this.state = {
			loginmode: loginmode,
			cartarr: [],
			accordin: '',
			rstatus: '',
			active_menu: 'myorders',

		}
		this.accordinClick = this.accordinClick.bind(this);

	}
	accordinClick(phid) {
		let accordin = this.state.accordin;
		if (accordin !== phid) {
			this.setState({
				accordin: phid
			})
		} else {
			this.setState({
				accordin: ''
			})

		}
	}
	componentDidMount() {
		const store_id = localStorage.getItem("store_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("fk_member_id", fk_member_id);
		let api_url1;
		api_url1 = api_url + "/api/myorders";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (result.status === 200) {
						this.setState({
							cartarr: result.cartarr,
							rstatus: result.status
						})

					} else {

					}


				},
				(error) => {

				}

			)


	}




	render() {
		const langv = JSON.parse(localStorage.getItem('langv'));
		const themecolor = this.props.themecolor;
		const stylesv2 = {

			noresult_cls: {
				color: themecolor.text_color,
				fontSize: "23px",
				textAlign: 'Center',
				marginTop: '15%'
			},
			accord_col: {
				border: "1px solid " + themecolor.menu_bg_color,
				backgroundColor: themecolor.menu_bg_color,
				color: themecolor.menu_text_color,
				borderRadius: '8px'

			},
			ms_textcol: {
				color: themecolor.text_color,
			},
			ms_textcolb: {
				color: themecolor.text_color,
				fontWeight: 'bold'
			},
			card: {
				border: 'none',
				borderRadius: '8px'
			},
			ms_textcol_heading: {
				color: themecolor.text_color,
				fontSize:"22px"
			},

		};

		return (
			<div>


				<div>
					<Menu themecolor={this.props.themecolor} active_menu={this.state.active_menu} logincheck={this.props.logincheck} />

				</div>
				<div className="container">
					
					<div className="col-md-12 col-12 text_center">
						<label style={stylesv2.ms_textcol_heading}><FontAwesomeIcon icon={faCheckToSlot} /> {langv.my_orders}</label>
					</div>
					{(this.state.cartarr.length === 0 && this.state.rstatus === 200) &&
						<div className="col-md-12 col-12 text_center">
						<p style={stylesv2.noresult_cls}>{langv.no_data_found}</p>
						</div>
					}
					<div className="row m_top2">
						<div className="col-md-12 col-12 div-order-items">
							{this.state.cartarr.map(phdet => (
								<div style={stylesv2.card} key={"ph_" + phdet.id} className="card m_bot1">
									<div className="collapsed" style={stylesv2.accord_col} onClick={(e) => this.accordinClick(phdet.id)} id={"heading_" + phdet.id} data-toggle="collapse" data-target={"#collapse_" + phdet.id} aria-expanded="false" aria-controls={"#collapse_" + phdet.id}>
										<div className="row mob-m0">
											<div className="col-md-3 col-8">
												<div className="div-col">{phdet.trans_id}</div>
											</div>
											<div className="col-md-3 d-none-mob"><div className="div-col"></div></div>
											<div className="col-md-3 col-2"><div className="div-col"><img src={phdet.pay_img} alt="noimage" /></div></div>
											<div className="col-md-2 d-none-mob"><div className="div-col"><i className="fa fa-calendar"></i> {phdet.created_at}</div></div>
											<div className="col-md-1 col-2 div-arrow-align">
												<i style={{ marginTop: '40%' }} className="fa fa-angle-down" ></i>
											</div>
										</div>

									</div>
									<div id={"collapse_" + phdet.id} key={"serf_" + phdet.id} className={this.state.accordin !== phdet.id ? 'collapse' : ''} aria-labelledby={"heading_" + phdet.id} data-parent="#accordion">
										<div className="card-body">
											{phdet.servicedet.map((pdet, i) => (
												<div key={"ser1_" + pdet.id}>
													<div style={stylesv2.ms_textcol} className="row m_top2">
														<div className="col-md-5 col-5 div-img-name">

															<img src={pdet.product_image} alt="noimage" />
															<div className="div-name-variation">
																<p>{pdet.name}</p>
															</div>

														</div>
														<div className="col-md-3 col-3">
															<p>{pdet.quantity} Qty</p>
														</div>
														<div className="col-md-4 col-4">
															<p>{pdet.total_amount}</p>
														</div>
													</div>


												</div>
											))}
											<div className="row m_top2">
												<div className="col-md-9"></div>
												<div className="col-md-3"><p className="myordpamt" style={stylesv2.ms_textcolb}>Purchase Amount: {phdet.amt}</p></div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>

			</div>
		);
	}
}


export default Myorders;

import React from "react";
import { api_url } from '../config';
import '../css/navbar.css';
//import { library } from '@fortawesome/fontawesome-svg-core';
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward,faBagShopping,faCheckToSlot } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Style from 'style-it'
class Menu extends React.Component {
	constructor(props) {
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		super(props);
		const screenWidth = window.innerWidth;

		//this.props.logincheck('islogin');
		this.state = {
			store_det: '',
			store_id: store_id,
			prod_id: prod_id,
			navbtn: false,
			screenWidth: screenWidth,

		}
		this.navbtnClick = this.navbtnClick.bind(this);
		

	}
	
	navbtnClick() {

		this.setState(prevState => ({
			navbtn: !prevState.navbtn
		}));
	}
	
	
	
	componentDidMount() {
		const postdata = new FormData();
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		postdata.append("fk_store_id", store_id);
		postdata.append("prod_id", prod_id);
		postdata.append("fk_member_id", fk_member_id);
		var login_url = localStorage.getItem("login_url");
		let api_url1;
		api_url1 = api_url + "/api/femenudet";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (result.status === 200) {

						this.setState({ store_det: result });
						//document.body.style.backgroundColor = this.props.themecolor.body_color;

					}else if (result.status === 401) {
						 this.setState({ loginmode: 'no' });
						 localStorage.setItem('loginmode','no');
						 window.top.location.href=login_url;
					}


				},
				(error) => {

				}

			)


	}




	render() {
		  
		const themecolor = this.props.themecolor;
		const active_menu = this.props.active_menu;
		const langv = JSON.parse(localStorage.getItem('langv'));
		var login_url = localStorage.getItem("login_url");
		
		 
		
		const styles = {
			barbg: {
				border: "1px solid " + themecolor.menu_bg_color,
				backgroundColor: themecolor.menu_bg_color,
				color: themecolor.menu_text_color
			},
			menutxcol: {
				color: themecolor.menu_text_color,
				'&:hover': {
					backgroundColor: themecolor.menu_text_color,
				},
			},
			
			menutxcol_active: {
				backgroundColor: themecolor.menu_text_color,
				color:themecolor.menu_bg_color,
				padding: '15px 10px',
				borderRadius: '5px 5px 0 0',
				
			}
		};
		
		console.log(active_menu);
  //library.add(faCoffee)
		return (
			<Style>
			{`
			  .itemsa:hover  {
				background:${ themecolor.menu_text_color } !important;
				color:${ themecolor.menu_bg_color } !important;
				padding: 13px 6px;
				border-radius: 5px 5px 0 0;
			  },
			 
			  .li-avatar a:hover{
				background:${ themecolor.menu_bg_color } !important;
				color:${ themecolor.menu_text_color } !important;
				
			 }
		   `}
			<div className="nav_bot">
		
				<nav style={styles.barbg}>
					{this.state.store_det.logo &&
						<a style={{ float: 'left' }} href={login_url}>
							<img alt="logo" src={this.state.store_det.logo} style={{ maxWidth: '150px', maxHeight: '40px', marginLeft: '12px', marginTop: '6px' }} />
						</a>
					}
					{(this.state.navbtn || this.state.screenWidth > 500) &&
						<div>

							
							<ul style={styles.barbg} className="list">

								<li className="items">
								
									<a className="itemsa" style={active_menu === 'home' ? styles.menutxcol_active : styles.menutxcol} href={login_url}><i className="fa fa-home"></i> {langv.home}</a>
									
									
								
								</li>

								<li className="items">
									<Link className="itemsa" style={active_menu === 'myrewards' ? styles.menutxcol_active : styles.menutxcol} to={process.env.PUBLIC_URL + '/viewrewards/' + this.state.store_id + '/' + this.state.prod_id}><FontAwesomeIcon icon={faAward} /> {langv.rewards}</Link>
									
								</li>

								<li className="items">
									<Link className="itemsa" style={active_menu === 'myproducts' ? styles.menutxcol_active : styles.menutxcol} to={process.env.PUBLIC_URL + '/category/' + this.state.store_id + '/' + this.state.prod_id}><i className="fa fa-shopping-cart"></i> {langv.products}</Link>
									
									
								</li>
								

								{this.state.store_det.reward_cash_menu === 1 &&
									
									<li className="items d-none-desk">
										<Link style={active_menu === 'rewardcash' ? styles.menutxcol_active : styles.menutxcol} className="itemsa"  to={process.env.PUBLIC_URL + '/rewardcash/' + this.state.store_id + '/' + this.state.prod_id}><i className="fa fa-money"></i> {langv.reward_cash}</Link>
									</li>

								 }
								
								<li className="items d-none-desk">
								<Link style={active_menu === 'myorders' ? styles.menutxcol_active : styles.menutxcol} className="itemsa"  to={process.env.PUBLIC_URL + '/myorders/' + this.state.store_id + '/' + this.state.prod_id}><FontAwesomeIcon icon={faBagShopping} /> {langv.my_orders}</Link>
								</li>

								<li className="items d-none-desk">
									<Link style={active_menu === 'myprofile' ? styles.menutxcol_active : styles.menutxcol} className="itemsa"  to={process.env.PUBLIC_URL + '/myprofile/' + this.state.store_id + '/' + this.state.prod_id}><i className="fa fa-user"></i> {langv.my_profile}</Link>
								</li>

								<li className="items d-none-desk">
									<a className="itemsa" style={styles.menutxcol} href='#!' onClick={(e) => this.props.logincheck('logout')}><i className="fa fa-lock"></i> {langv.logout}</a>
								</li>

								<li className="li-avatar">
									<Dropdown>
										<Dropdown.Toggle  className="btn_frst_ltr" variant="as" >{this.state.store_det.fstchar}</Dropdown.Toggle>
										<Dropdown.Menu>
										  <Dropdown.Item as={Link}  to={process.env.PUBLIC_URL + '/myprofile/' + this.state.store_id + '/' + this.state.prod_id}><i className="fa fa-user"></i>{langv.my_profile}</Dropdown.Item>
										  
										  <Dropdown.Item as={Link} to={process.env.PUBLIC_URL + '/myorders/' + this.state.store_id + '/' + this.state.prod_id}><FontAwesomeIcon icon={faCheckToSlot} />  {langv.my_orders}</Dropdown.Item>
										  
										  {this.state.store_det.reward_cash_menu === 1 &&
											<Dropdown.Item as={Link} to={process.env.PUBLIC_URL + '/rewardcash/' + this.state.store_id + '/' + this.state.prod_id}><i className="fa fa-money"></i>{langv.reward_cash}</Dropdown.Item>
										  }	

										  <Dropdown.Item onClick={(e) => this.props.logincheck('logout')} href="#!"><i className="fa fa-sign-out"></i>{langv.logout}</Dropdown.Item>

										  
										</Dropdown.Menu>
									  </Dropdown>
								</li>

							</ul>
							{(this.state.profileicon &&
							<div className="div-dropdown">
								<ul>
									<li className="items"><a style={styles.menutxcol} href={process.env.PUBLIC_URL + '/myorders/' + this.state.store_id + '/' + this.state.prod_id}>{langv.my_orders}</a></li>
									<li className="items"><a style={styles.menutxcol} href={process.env.PUBLIC_URL + '/myprofile/' + this.state.store_id + '/' + this.state.prod_id}>{langv.my_profile}</a></li>
									<li className="items"><a style={styles.menutxcol} href='#!' onClick={(e) => this.props.logincheck('logout')}>{langv.logout}</a></li>
								</ul>
							</div>
							)}
						</div>
					}
					<button style={styles.menutxcol} onClick={this.navbtnClick} className="btn_nav"><i className="fa fa-bars"></i></button>
				</nav>

			</div>
			</Style>


		);
	}
}


export default Menu;

import React from "react";
//import { connect } from 'react-redux';
import { api_url } from '../config';
import '../css/popup.css';
import Cartbox from '../components/Cartbox';
import Template1 from './template_product/Template1';
import Modal from 'react-modal';
class MyProductSub extends React.Component {
	constructor(props) {
		super(props);
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const loginmode_sess = localStorage.getItem('loginmode');

		var loginmode;

		if (loginmode_sess === null) {
			loginmode = 'no';
		} else {
			loginmode = loginmode_sess;
		}

		var cartlistlocal;
		var cartlistlocal_sess = localStorage.getItem('cartlistlocal');
		if (cartlistlocal_sess === null || typeof cartlistlocal_sess === 'undefined') {
			cartlistlocal = [{ item: 0, id: 0, name: 0, quantity: 0, keygen: 0, amt_symbol: 0, amt: 0, amt_qty_total: 0 }];
		} else {
			cartlistlocal = JSON.parse(cartlistlocal_sess);
		}

		this.state = {
			store_id: store_id,
			prod_id: prod_id,
			loginmode: loginmode,
			show_cartbox: 0,
			cart: cartlistlocal,
			products: [],
			modaltitle: '',
			modaltext: '',
			reviews: [],
			galleries: [],
			faqs: [],
			customize_type: '',
			media_html: '',
			media_pop: 0,
			points: 0,
			rewarddet: [],
			lang: '',


		}
		this.addCart = this.addCart.bind(this);
		this.child = React.createRef();

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.customizelink = this.customizelink.bind(this);
		this.openMedia = this.openMedia.bind(this);
		this.increment = this.increment.bind(this);
		this.child = React.createRef();

	}
	customizelink(type) {

		this.setState({
			customize_type: type
		})


	}
	closeMedia() {
		this.setState({
			media_pop: 0
		})

	}
	openMedia(media_id) {

		const store_id = localStorage.getItem("store_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("media_id", media_id);
		postdata.append("fk_member_id", fk_member_id);
		let api_url1;
		api_url1 = api_url + "/api/openmedia";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
			.then(res => res.text())
			.then(
				(result) => {
					this.setState({
						media_html: result,
						media_pop: 1
					})



				},
				(error) => {

				}

			)


	}
	openModal(prod_id) {
		const store_id = localStorage.getItem("store_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("fk_service_id", prod_id);
		postdata.append("fk_member_id", fk_member_id);
		let api_url1;
		api_url1 = api_url + "/api/productdetails";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (result.status === 200) {
						this.setState({
							galleries: result.gallery,
							reviews: result.review,
							faqs: result.faq,
							points: result.points,
							fk_reward_id: result.fk_reward_id,
							modalIsOpen: true,
							lang: result.lang,
							rewarddet: result.rewarddet,
						})

						if (this.state.galleries.length > 0) {
							this.setState({ customize_type: 'gallery' })
						} else if (this.state.faqs.length > 0) {
							this.setState({ customize_type: 'faq' })
						} else if (this.state.faqs.reviews > 0) {

							this.setState({ customize_type: 'gallery' })
						} else if (this.state.points > 0) {

							this.setState({ customize_type: 'points' })
						}

					} else {

					}



				},
				(error) => {

				}

			)


	}
	closeModal() {

		this.setState({
			modalIsOpen: false,

		});

	}



	addCart(prod) {


		const postdata = new FormData();
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		postdata.append("fk_store_id", store_id);
		postdata.append("prod_id", prod_id);
		postdata.append("fk_member_id", fk_member_id);
		postdata.append("fk_service_id", prod.id);
		const cart = this.state.cart;
		/* 
		this.setState({ 
			products: [...this.state.products, 0:{id:1,name:'karthick'}] 
		})
		console.log(this.state.products);
		 */


		let index = cart.findIndex(el => el.item === prod.id);
		if (index === -1) {
			console.log("item new to cart");
			this.setState(prevState => ({
				cart: [
					...prevState.cart,
					{
						item: prod.id,
						id: prod.id,
						name: prod.name,
						quantity: 1,
						keygen: prod.id,
						amt_symbol: prod.amount.amt_symbol,
						amt: prod.amount.amount,
						amt_qty_total: prod.amount.amount,
					}
				]
			}));
			// this.addtotalamt();
			//this.addtotalamt();


		} else {
			/* this.setState(prevState => ({
			quantity: (prevState.cart[index].quantity += 1)
		   })); */
			// this.cartbox.increment(prod);
			this.increment(prod);
		}
		console.log(JSON.stringify(this.state.cart));
		// localStorage.setItem('cartlistlocal',JSON.stringify(this.state.cart));

		// const cartlistlocal = localStorage.getItem('cartlistlocal');
		//console.log("cartlistlocal "+cartlistlocal);

	}
	increment(prod) {

		const cart = this.state.cart;
		let index = cart.findIndex(el => el.item === prod.id);
		cart[index].quantity += 1
		cart[index].amt_qty_total = cart[index].quantity * cart[index].amt;
		this.setState({ cart: cart })
		//this.addproductamt(index);

	}
	componentDidMount() {
		/* const category_id = this.props.match.params.category_id;
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		const loginmode = localStorage.getItem("loginmode");
		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("prod_id", prod_id);
		postdata.append("fk_member_id", fk_member_id);
		if( typeof category_id !== "undefined"){
			postdata.append("category_id", category_id);
		}
		let api_url1;
		api_url1 = api_url + "/api/myproduct";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
		.then(res => res.json())
		.then(
			(result) => {
				if (result.status === 200) {
					  this.setState({
						  products: result.products
					  })
					  document.body.style.backgroundColor = result.body_color;
				} else {
					
				}


			},
			(error) => {

			}

		) */


	}



	render() {
		const themecolor = this.props.themecolor;
		const langv = JSON.parse(localStorage.getItem('langv'));
		const customStyles = {
			content: {
				top: '35%',
				left: '50%',
				right: 'auto',
				bottom: 'auto',
				marginRight: '-50%',
				transform: 'translate(-50%, -50%)',
				width: '650px',
				maxHeight: '550px',
				backgroundColor: themecolor.body_color,
				color:themecolor.text_color,
				border: '2px solid'+ themecolor.text_color,
				
			},
		
			
		};
		const stylesv2 = {
			
			ms_textcol: {
				color: themecolor.text_color,
			},
			vpopbg: {
				backgroundColor: themecolor.body_color,
				borderRadius: '10px',
				border: '2px solid'+ themecolor.text_color,
				
			},
			media_box:{
				border: '1px solid'+ themecolor.text_color,
				
			}
		};




		return (
			<div>

				{this.state.media_pop === 1 &&
					<div  id="vpop">
						<div id="exampleModal" style={stylesv2.vpopbg} className="reveal-modal">
							<div dangerouslySetInnerHTML={{ __html: this.state.media_html }}></div>
							<a href="#!" style ={stylesv2.ms_textcol} onClick={() => { this.closeMedia() }} className="reveal-close">×</a>
						</div>
					</div>
				}
				<Modal isOpen={this.state.modalIsOpen} Class="modalpop" ariaHideApp={false} onRequestClose={this.state.closeModal} style={customStyles} >
					<h5>{langv.product_info}</h5>
					<button onClick={(e) => this.closeModal()} type="button" style ={stylesv2.ms_textcol}  className="close_modal"><span>×</span></ button>
					<div className="modal-body">
						<ul className="nav">
							{(this.state.galleries != null && this.state.galleries.length > 0) &&
								<li className="nav-item">
									<a href="#!" style ={stylesv2.ms_textcol} onClick={() => { this.customizelink("gallery") }} className={"nav-link nav-link-mob " + (this.state.customize_type === 'gallery' ? 'active' : '')} aria-current="page">{langv.gallery}</a>
								</li>
							}

							{(this.state.faqs != null && this.state.faqs.length > 0) &&
								<li className="nav-item">
									<a href="#!" style ={stylesv2.ms_textcol} onClick={() => { this.customizelink("faq") }} className={"nav-link nav-link-mob " + (this.state.customize_type === 'faq' ? 'active' : '')} >{langv.faq}</a>
								</li>
							}

							{(this.state.reviews != null && this.state.reviews.length > 0) &&
								<li className="nav-item">
									<a href="#!" style ={stylesv2.ms_textcol} onClick={() => { this.customizelink("review") }} className={"nav-link nav-link-mob " + (this.state.customize_type === 'review' ? 'active' : '')} >{langv.reviews}</a>
								</li>
							}

							{this.state.points !== 0 &&
								<li className="nav-item">
									<a href="#!" style ={stylesv2.ms_textcol} onClick={() => { this.customizelink("points") }} className={"nav-link nav-link-mob " + (this.state.customize_type === 'points' ? 'active' : '')}>{langv.reward_points}</a>
								</li>
							}
							
							
							{this.state.rewarddet.fk_reward_id  &&
							<li className="nav-item">
									<a href="#!" style ={stylesv2.ms_textcol} onClick={() => { this.customizelink("rewards") }} className={"nav-link nav-link-mob " + (this.state.customize_type === 'rewards' ? 'active' : '')}>{langv.rewards}</a>
							</li>
							}
						</ul>
						{this.state.customize_type === 'gallery' &&
							<div className="row div-mystore-gal">
								{this.state.galleries.map(gallery => (
									<div className="col-md-4 col-4 m_top1 media_box_cls" key={"media_" + gallery.id}>
										<div style ={stylesv2.media_box} className="media_box">
											{gallery.media_type === '1' ? (
												<a href="#!"><img onClick={() => { this.openMedia(gallery.id) }} src={gallery.image} className="img-fluid" alt="noimage" /></a>
											) : (
												<div>
													<img onClick={() => { this.openMedia(gallery.id) }} src={gallery.image} className="img-fluid" alt="noimage" />
													<div onClick={() => { this.openMedia(gallery.id) }} style={{ cursor: "pointer" }} className="div-play">
														<i className="fa fa-play"></i>
													</div>
												</div>
											)}
										</div>
									</div>
								))}

							</div>
						}
						{this.state.customize_type === 'faq' &&
							<div className="col-md-12 m_top1 div-mystore-faq">
								{this.state.faqs.map(faq => (
									<div key={"faq_" + faq.id}>
										<h5>{faq.question}</h5>
										<p>{faq.answer}</p>

									</div>
								))}
							</div>
						}

						{this.state.customize_type === 'review' &&
							<div>
								{(this.state.reviews != null && this.state.reviews.length > 0) &&
									<div className="col-md-12 div-mystore-faq m_top1">
										{this.state.reviews.map(review => (
											<div key={"review_" + review.id}>
												<h5>{review.cust_name}</h5>
												<p>{review.description}</p>
											</div>
										))}
									</div>
								}
							</div>
						}

						{this.state.customize_type === 'points' &&
							<div>

								<div className="div-mystore-faq">
									<p>{this.state.lang.earn_ponts_md_lang}</p>
								</div>

							</div>
						}
						
						{this.state.customize_type === 'rewards' &&
							<div>

								<div className="div-mystore-faq">
									<p>{langv.rewards_assign_text}</p>
								</div>
								<div className="col-md-4 col-4 m_top1 media_box_cls pl0">
									<p className="mbot5">{this.state.rewarddet.reward_name}</p>
									<div style ={stylesv2.media_box} className="media_box">
										<img src={this.state.rewarddet.reward_image} className="img-fluid" alt="noimage" />	
									</div>
								</div>

							</div>
						}

					</div>
				</Modal>
				<div className="col-md-12 col-12 div-fe-mob-prod">
				<div className="row m_top2">
					
					{this.props.products.map(product => (
						<React.Fragment key={product.keygen}>
							<Template1 openModal={this.openModal} cart={this.state.cart} addCart={this.addCart} product={product} />
						</React.Fragment>
					))}


				</div>
				</div>

				<div>

					<Cartbox ref={this.child} themecolor={this.props.themecolor} cartpass={this.state.cart} />
				</div>


			</div>

		);
	}

}


export default MyProductSub;

import React from "react";
import { api_url } from '../config';
import  '../css/cartbox.css';
import  '../css/navbar.css';
import { Link } from "react-router-dom";
class Cartbox extends React.Component {
	constructor(props) {
		const store_id = localStorage.getItem("store_id");
		const prod_id = localStorage.getItem("prod_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		super(props);
		
		
		
		this.state = {
			store_id: store_id,
			prod_id: prod_id,
			fk_member_id: fk_member_id,
			show_cartbox:0,
			symbol_left:'',
			symbol_right:'',
			lang:{},
			header_bg_color: '',
			header_text_color: '',
			
			
		}
		this.showcartbox = this.showcartbox.bind(this); 
		this.hidecartbox = this.hidecartbox.bind(this); 
		this.increment = this.increment.bind(this); 
		this.decrement = this.decrement.bind(this); 
		this.remove = this.remove.bind(this); 
	//	this.checkout = this.checkout.bind(this); 


	}
	componentDidMount() {
		const store_id = localStorage.getItem("store_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("fk_member_id", fk_member_id);
		let api_url1;
		api_url1 = api_url + "/api/cartboxload";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
		.then(res => res.json())
		.then(
			(result) => {
				if (result.status === 200) {
					  this.setState({
						  symbol_left: result.currency_det.symbol_left,
						  symbol_right: result.currency_det.symbol_right,
						  header_bg_color: result.header_bg_color,
						  header_text_color: result.header_text_color,
						  lang: result.lang
					  })
					  
				} else {
					
				}


			},
			(error) => {

			}

		) 
		

	}
	
	remove(prod){
		const cart = this.props.cartpass;
		let index = cart.findIndex(el => el.item === prod.id);
		cart.splice(index, 1);
		this.setState({cart: cart})
	}
	increment(prod) {
		
		const cart = this.props.cartpass;
		let index = cart.findIndex(el => el.item === prod.id);
		cart[index].quantity += 1
		cart[index].amt_qty_total =cart[index].quantity * cart[index].amt;
		this.setState({cart: cart})
		//this.addproductamt(index);

	}
	
	decrement(prod) {
		
		const cart = this.props.cartpass;
		let index = cart.findIndex(el => el.item === prod.id);
		cart[index].quantity -= 1
		const quantity = cart[index].quantity;
		cart[index].amt_qty_total =cart[index].quantity * cart[index].amt;
		
		if(quantity === 0){
			
			 cart.splice(index, 1);
		}
		this.setState({cart: cart})
		

	}
	showcartbox() {

		this.setState({ 
			show_cartbox: 1,
			
		 });

	}
	hidecartbox(){
		
		this.setState({ 
			show_cartbox: 0,
			
		 });
		
	}
	
	

	
	render() {
		const cart = this.props.cartpass;
		const themecolor = this.props.themecolor;
		const langv = JSON.parse(localStorage.getItem('langv'));
		let sum = cart.reduce(function(prev, current) {
		 // if(typeof current.amt !== 'undefined' && typeof current.quantity !== 'undefined' && typeof prev !== 'undefined'){
			  let amtv = current.amt * current.quantity;
			  return prev + amtv
		 // }
		}, 0); 
		localStorage.setItem('cartlistlocal',JSON.stringify(cart));
		//const getcartlocal = localStorage.getItem('cartlistlocal');
		
		
		const stylesv2 = {
			
			ms_btn: {
				backgroundColor: themecolor.btn_bg_color,
				color: themecolor.btn_text_color,
				border:"1px solid "+themecolor.btn_bg_color
			},
			ms_textcol: {
				color: themecolor.text_color,
			},
			cart_icon_bg: {
				color: themecolor.menu_text_color,
			},
			cart_btn_bg: {
				backgroundColor: themecolor.menu_bg_color,
				cursor:"pointer",
				
			},
			cart_ball_guest:{
				backgroundColor: themecolor.menu_text_color,
				color: themecolor.menu_bg_color,
				
			},
			closestyle:{
				fontSize:"20px",
				color: themecolor.text_color,
				
			}
		};	
			
		return (
		<div>	
			<div>	
			<div id="cart_ball_box" className="div-parent-cart ">
				<div style={stylesv2.cart_btn_bg} className="prod_theme_bg pr">
					<a href="#!"  onClick={()=>{this.showcartbox()}} >
						<i style ={stylesv2.cart_icon_bg} className="fa fa-shopping-cart" aria-hidden="true"></i> <span></span>
					</a>
					{this.props.cartpass.length > 1 &&	
					<div style ={stylesv2.cart_ball_guest} className="cart_ball_guest prod_theme" id="cart_ball">{this.props.cartpass.length-1}</div>
					}
				</div>
			</div>
			{this.state.show_cartbox === 1 &&
			<div className="div-right-cart-popup">

					<button type="button" className="close close_modal"></button>
					<div id="cartpopup_box" className="modal-body modal-add-product">
					
						<div style ={stylesv2.ms_textcol} className="div-product-name prod_theme">
							<span>{langv.cart}</span>
							 <button onClick={()=>{this.hidecartbox()}} style ={stylesv2.closestyle} type="button" className="close close_modal"><i className="fa fa-times-circle-o"></i></button>
						</div>
						
						{this.props.cartpass.length > 1 &&
							this.props.cartpass.map((carts,index) => (
							carts.id !== 0 ? (
							<div key={carts.id}>
							<div className="row m_top2">
									<div className="col-md-12 col-12">
										<div className="row">
											<div className="col-md-5 col-5 div-cart-item">
												<div className="df">
													<p style ={stylesv2.ms_textcol} className="form-check-label" htmlFor="vt_7702">{carts.name}</p>
												</div>
												
											</div>

											<div className="col-md-4 col-4 text-left div-cart-count">
												<div style ={stylesv2.ms_textcol} c> 
													<button style ={stylesv2.ms_btn} onClick={()=>{this.increment(carts)}} className="btn_round prod_theme_bg" type="button">+</button>
													<span  style={{ textAlign: "center", border: "none",marginLeft:"8px",marginRight:"8px", }} >{carts.quantity}</span>
													<button style ={stylesv2.ms_btn} onClick={()=>{this.decrement(carts)}} className="btn_round prod_theme_bg" type="button">-</button>
												</div>
											</div>
											<div style ={stylesv2.ms_textcol} className="col-md-2 col-2 text-left">
												<span className="td-themecolor fw-700 cprod_amt_120">{this.state.symbol_left}{carts.amt_qty_total}{this.state.symbol_right}</span>
											</div>
											<div style ={stylesv2.ms_textcol} className="col-md-1 col-1 text-left pl0">
												<span><i style={{ cursor: "pointer" }} onClick={()=>{this.remove(carts)}} className="fa fa-trash"></i></span>
											</div>	
										</div>
								</div>
							</div>
							</div>
							) : (
								<div key="nope_cart"></div>		

							)
						
							))
						}
						
						{this.props.cartpass.length <= 1 &&
						
								<div style={{ textAlign: "center" }}>
										<div className="col-md-12 col-12">
													<img alt="nom" src={api_url+"/img/empty-cartimage.png"} className="img-cart" />
										</div>
										
										<p tyle ={stylesv2.ms_textcol} className="cartempty">{langv.cart_box_empty}</p>
								</div>		
						}

						{this.props.cartpass.length > 1 &&
							<div style={{ textAlign: "center" }} className="m_top2 col-md-12 col-12 p0">
								<Link  to={process.env.PUBLIC_URL +'/checkout/'+this.state.store_id+'/'+this.state.prod_id} style ={stylesv2.ms_btn}  className="btn btn-myfront-add-cart prod_theme_bg_btn">
									<i  className="fa fa-shopping-cart" aria-hidden="true"></i> {langv.checkout} <span className="fw-800" id="cartlist_amt">{this.state.symbol_left}{sum}{this.state.symbol_right}</span>
									
								</Link>
							</div>
						}


					</div>


				</div>
				}
			</div>
		
		</div>
	



		);
	}
}


export default Cartbox;

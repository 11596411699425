import React from "react";
import '../../css/prodtemplate.css';

class Template1 extends React.Component {
	constructor(props) {

		super(props);
		console.log(props);
		this.state = {

			showinfov: '',
			
			
		};

	}
	
	showinfo(id) {

		this.setState({ showinfov: id })

	}
	closeinfo() {

		this.setState({ showinfov: '' })

	}
	
	render() {
		const prod = this.props.product;
		const langv = JSON.parse(localStorage.getItem('langv'));
		const styletmp = {
			bg_box: {
				backgroundColor: prod.box_bg_color
			},
			bg_img: {
				backgroundImage: "url('"+prod.product_image+"')",
			},
			product_name_col: {

				color: prod.prod_name_color
			},
			btn_col: {

				backgroundColor: prod.prod_btn_color,
				color: prod.prod_btntxt_color,
				border: "1px solid " + prod.prod_btnborder_color
			},
			btn_col_info: {

				
				color: prod.prod_name_color,
				
			},
			offer_text_col: {

				color: prod.offer_text_color,
				backgroundColor:prod.offer_bg_color
			},
			
			rating_color: {
	
				border: "1px solid " + prod.rating_color,
				color: prod.rating_color
			},
			amt_color: {
	
				
				color: prod.amt_color
			},
			desc_color: {
	
				
				color: prod.prod_desc_color
			},
			b_top_col: {   
					borderTop: prod.box_bg_color ? '180px solid '+ prod.box_bg_color: '',
			},
			triangleright: {
				borderLeft: '30px solid '+ prod.box_bg_color,
			},
			triangleleft: {
				borderRight: '30px solid '+ prod.box_bg_color,
			},
			triangleright29:{
				
				borderLeft: '50px solid '+ prod.box_bg_color,
			},
			triangleleft30:{
				
				borderRight: '50px solid '+ prod.box_bg_color,
			},
			triangleright31:{
				
				borderLeft: '30px solid '+ prod.box_bg_color,
			},
			triangleleft32:{
				
				borderRight: '30px solid '+ prod.box_bg_color,
			},
			
		};

		return (
			
				
			<div className="col-md-4 col-12"  key={prod.keygen}>
			{(prod.template_id === 1) ? (

				
						<div style={styletmp.bg_box} className="div-mystore-template1 div-template-check">
							{prod.offer_text !== null &&
								<div style={styletmp.offer_text_col} className="div-offer-txt offer_text">{prod.offer_text}</div>
							}
							<div className="row">
								<div className="col-md-8 col-8">
									<div className="df">
										<div className="div-vegnonveg div-nonveg">
											<div></div>
										</div>
										<h3 title={prod.product_name}  style={styletmp.product_name_col}>{prod.name} 
											{prod.moreinfo_status === 1 &&
												<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
											}
										</h3>
									</div>									
									<p style={styletmp.desc_color}>{prod.description}</p>
									<div className="row">
										<div className="col-md-9 col-9">
											<span className="prod_amt" style={styletmp.amt_color}>{prod.amount.amt_symbol}</span>
										</div>
										<div className="col-md-3 col-3 pl0">
											{prod.star_rating !== null &&
											<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
											}
										</div>
									</div>
								</div>
								<div className="col-md-4 col-4">
									<div>
										<img className="img-fluid" src={prod.product_image} alt="noimage"/>
									</div>
									<div>
										<button onClick={() => this.props.addCart(prod)} style={styletmp.btn_col} className="btn-myfront-add">{langv.add_cart}</button>
									</div>
								</div>
							</div>
							
						</div>
					
				

			) : (prod.template_id === 2) ? (

				
				  <div style={styletmp.bg_box} className="div-mystore-template2 box_bg">
					<div className="row">
					  <div className="col-md-8 col-8">
						<div className="df">
						 <h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
							{prod.moreinfo_status === 1 &&
								<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
							}
						</h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						
						<div className="row">
						  <div className="col-md-6 col-6">
							<div>
							  <button onClick={() => this.props.addCart(prod)}  style={styletmp.btn_col} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							   
							</div>
						  </div>
						  
						  <div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
								<div style={styletmp.rating_color} className="star_box rating_bg">
								  <i className="fa fa-star" ></i> {prod.star_rating}
								</div>
							}
						  </div>
						  
						  <div className="col-md-3 col-3">
							<div>
							  <span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
							</div>
						  </div>
						</div>
						{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col}  className="div-offer-txt offer_text">
								{prod.offer_text}
						</div>
						}
					  </div>
					  <div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
					  </div>
					</div>
				  </div>
				

			) : (prod.template_id === 3) ? (
				
				
				  <div style={styletmp.bg_box} className="div-mystore-template3 box_bg">
					<div className="row">
					  <div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
					   </div>
					  <div className="col-md-8 col-8">
						{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
						}
						<div className="df">
							 <h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
								{prod.moreinfo_status === 1 &&
									<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
								}
							</h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						
						<div className="row">
						   <div className="col-md-3 col-3 pr-0">
							<div>
							  <span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
							</div>
						  </div>
						  <div className="col-md-3 col-3">
							{prod.star_rating !== null &&
								<div style={styletmp.rating_color} className="star_box rating_bg">
								  <i className="fa fa-star" ></i> {prod.star_rating}
								</div>
							}
						  </div>
						  <div className="col-md-6 col-6 pr-0">
							<div>
							  <button onClick={() => this.props.addCart(prod)}  style={styletmp.btn_col} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							  
							</div>
						  </div>
						</div>
						
					  </div>
					  
					</div>
				  </div>
				
				
			) : (prod.template_id === 4) ? (
					<div style={styletmp.bg_box} className="div-mystore-template4 box_bg">
							{prod.offer_text !== null &&
								<div style={styletmp.offer_text_col} className="div-offer-txt offer_text">{prod.offer_text}</div>
							}
							
							  <div className="row">
								<div className="col-md-4 col-4">
									<div>			
										<img className="img-fluid" src={prod.product_image} alt="noimage"/>
									</div>
									<div>
										<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
										
									</div>
								</div>
								<div className="col-md-8 col-8">
									<div className="df">
										<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
											{prod.moreinfo_status === 1 &&
												<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
											}
										</h3>
									</div>
									
									<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>													
									<div className="row">
										<div className="col-md-3 col-3 pl0">
											{prod.star_rating !== null &&
												<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
											}
										</div> 		
																									
										<div className="col-md-9 col-9 text-right">
											<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
										</div>
									</div>
								</div>                                                    
							</div>
					</div>

			) : (prod.template_id === 5) ? (
				<div style={styletmp.bg_box} className="div-mystore-template5 box_bg">
					{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					}
					<div className="row mr0">
						<div className="col-md-8 col-8">
							<div className="df">
								<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
									{prod.moreinfo_status === 1 &&
										<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
									}
								</h3>
							</div>
							
							<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
							<div className="row">
								<div className="col-md-6 col-6">
									<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
									
								</div>
								<div className="col-md-3 col-3 pl0">
									{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
									}
								</div>
																							
								<div className="col-md-3 col-3 pl0">
									<div>
										<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-4" style={styletmp.bg_img}>
							<div style={styletmp.b_top_col} className="div-left b_top_col"></div>
							<div className="div-img">
								
							</div>
						</div>
					</div>
				</div>
			) : (prod.template_id === 6) ? (

						<div style={styletmp.bg_box} className="div-mystore-template6 box_bg">
							{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
							}
							<div className="row">												
									<div className="col-md-4 col-4" style={styletmp.bg_img}>
										<div className="div-img"></div>
										<div style={styletmp.b_top_col} className="div-right b_top_col"></div>
									</div>
									<div className="col-md-8 col-8">
										<div className="df">
											<h3 title={prod.product_name} className="header_txt6" style={styletmp.product_name_col}>{prod.name} 
												{prod.moreinfo_status === 1 &&
													<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
												}
											</h3>
										</div>
										
										<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
										<div className="row">
											<div className="col-md-3 col-3 pl-1">
												<div>
													<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
												</div>
											</div>
											<div className="col-md-3 col-3 pl0">
												{prod.star_rating !== null &&
													<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
												}														
											</div>
											<div className="col-md-6 col-6 pl0">
												<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
												
											</div>
										</div>
									</div>
								
							</div>
							
						</div>

			) : (prod.template_id === 7) ? (

					<div style={styletmp.bg_box} className="div-mystore-template7 box_bg">
						{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
						}
						<div className="row">
							<div className="col-md-8 col-8">
								<div className="df">
									<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
									</h3>
								</div>
								
								<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
								<div className="row">
									<div className="col-md-6 col-6">
										<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
										
									</div>
									<div className="col-md-3 col-3 pl0">
										{prod.star_rating !== null &&
											<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
										}														
									</div>
									<div className="col-md-3 col-3 pl0">
										<div>
											<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-4">
								<div style={styletmp.bg_img}></div>
							</div>
						</div>
					</div>

			) : (prod.template_id === 8) ? (
					<div style={styletmp.bg_box} className="div-mystore-template8 box_bg">
							{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
							}
							<div className="row">
								<div className="col-md-4 col-4">
									<div style={styletmp.bg_img}></div>
								</div>
								<div className="col-md-8 col-8">
									<div className="df">
										<h3 title={prod.product_name} className="header_txt8" style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
										</h3>
									</div>
									
									<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
									<div className="row">
										<div className="col-md-3 col-3 pl0">
											<div>
												<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
											</div>
										</div>
										<div className="col-md-3 col-3 pr0">
											{prod.star_rating !== null &&
												<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
											}														
										</div>
										<div className="col-md-6 col-6 pr0">
											<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
											
										</div>
									</div>
								</div>
							</div>
						</div>
			) : (prod.template_id === 9) ? (

					<div style={styletmp.bg_box} className="div-mystore-template9 box_bg">											
						<div className="row">
							<div className="col-md-8 col-8">
								<div className="df">
									<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
									</h3>
								</div>
								
								<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
								<div className="row">
									<div className="col-md-6 col-6">
										<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
										
									</div>
									<div className="col-md-3 col-3 pl0">
										{prod.star_rating !== null &&
											<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
										}														
									</div>
									<div className="col-md-3 col-3">
										<div>
											<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-4 col-4">
								<div style={styletmp.bg_img}></div>
							</div>
							{prod.offer_text !== null &&
							<div className="col-md-12 col-12">
								<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
							</div>
							}
						</div>
					</div>

		) : (prod.template_id === 10) ? (
			
			<div style={styletmp.bg_box} className="div-mystore-template10 box_bg">	
					{prod.offer_text !== null &&
							<div className="col-md-12 col-12">
								<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
							</div>
					}
					<div className="row">
						<div className="col-md-8 col-8">
							<div className="df">
								
								<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
								</h3>
							</div>
							
							<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
							<div className="row">
								<div className="col-md-6 col-6">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
								</div>
								<div className="col-md-3 col-3 pl0">
								{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
								}
								</div>
								<div className="col-md-3 col-3 pl0">
									<div>
									<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
						</div>
					</div>
				</div>
				
				) : (prod.template_id === 11) ? (
			
					<div style={styletmp.bg_box} className="div-mystore-template11 box_bg">
					{prod.offer_text !== null &&
						<div className="col-md-12 col-12">
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
						</div>
					}
					<div className="row">
						<div className="col-md-8 col-8">
							<div className="df">
								
								<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
								</h3>
							</div>
							
							<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>													
							<div className="row">
								<div className="col-md-6 col-6">
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
								<div className="col-md-6 col-6">
								{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
								}
								</div>
							</div>
						</div>
						<div className="col-md-4 col-4">
						<div><img className="img-fluid" src={prod.product_image} alt="noimage"/></div>
							<div>
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			
			) : (prod.template_id === 12) ? (
			
				<div style={styletmp.bg_box} className="div-mystore-template12 box_bg">
				{prod.offer_text !== null &&
						<div className="col-md-12 col-12">
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
						</div>
				}
				<div className="row">
					<div className="col-md-4 col-4 pr0">
					<div><img src={prod.product_image} alt="noimage"/></div>
						<div>
						<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
						</div>
					</div>
					<div className="col-md-8 col-8">
						<div className="df">
							
							<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3">
							{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-6 col-6 text-right">
							<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
							</div>
						</div>
					</div>                                                    
				</div>
			</div>

			) : (prod.template_id === 13) ? (
						
				<div style={styletmp.bg_box} className="div-mystore-template13 box_bg">
				{prod.offer_text !== null &&
						<div className="col-md-12 col-12">
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
						</div>
				}
				<div className="row">
					<div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
					</div>
					<div className="col-md-8 col-8">
						<div className="df">
							
							<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div> 
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3">
								<div>
									<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
							<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-6 col-6">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 14) ? (
									
				<div style={styletmp.bg_box} className="div-mystore-template14 box_bg">
					<div className="row">
						<div className="col-md-4 col-4">
							<div style={styletmp.bg_img}></div>
						</div>
						<div className="col-md-8 col-8">
							<div className="df">
								
								<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
							</div> 
							
							<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
							<div className="row">
								<div className="col-md-3 col-3">
									<div>
										<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
									</div>
								</div>
								<div className="col-md-3 col-3 pr0">
								{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
								}
								</div>
								<div className="col-md-6 col-6">
									<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
								</div>
							</div>
						</div>
						<div className="col-md-12 col-12">
						{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
						}
						</div>
					</div>
				</div>

		) : (prod.template_id === 15) ? (
											
			<div style={styletmp.bg_box} className="div-mystore-template15 box_bg">
					{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					}
					<div className="row mr0">
						<div className="col-md-8 col-8">
							<div className="df">
								
								<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
							</div>
							
							<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
							<div className="row">
								<div className="col-md-6 col-6 pl0">
									<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
								</div>
								<div className="col-md-3 col-3 pl0">
								{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
								}
								</div> 
								<div className="col-md-3 col-3 pl0">
									<div>
									<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
									</div>
								</div>
							</div>
						</div>
						<div style={styletmp.bg_img} className="col-md-4 col-4">
							<div style={styletmp.b_top_col} className="div-left"></div>
							<div className="div-img">
							</div>
						</div>
					</div>
				</div>
			) : (prod.template_id === 16) ? (
											
				<div style={styletmp.bg_box} className="div-mystore-template16 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div style={styletmp.bg_img} className="col-md-4 col-4">
						<div className="div-img">	</div>
						<div style={styletmp.b_top_col} className="div-right"></div>
					</div>
					<div className="col-md-8 col-8">
						<div className="df">
							
							<h3 title={prod.product_name} className ="header_txt16" style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>                                                       
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
							<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div> 
							<div className="col-md-6 col-6 pr0">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 17) ? (
														
				<div style={styletmp.bg_box} className="div-mystore-template17 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							
							<h3 title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>                                                        
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-3 col-3">
								<div>
									<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 18) ? (
																	
				<div style={styletmp.bg_box} className="div-mystore-template18 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
					</div>
					<div className="col-md-8 col-8">
						<div className="df">
							
							<h3 title={prod.product_name} className="header_txt18" style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3">
								<div>
									<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
							<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-6 col-6 pr0">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 19) ? (
																				
				<div style={styletmp.bg_box} className="div-mystore-template19 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							
							<h3 title={prod.product_name} className="header_txt19" style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>                                                        
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-3 col-3">
								<div>
									<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 20) ? (
																							
				<div style={styletmp.bg_box} className="div-mystore-template20 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
					</div>
					<div className="col-md-8 col-8">
						<div className="df">
							
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div> 
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3">
								<div>
									<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
							<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-6 col-6">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			) : (prod.template_id === 21) ? (
																							
				<div style={styletmp.bg_box} className="div-mystore-template21 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-veg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>                                                        
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-3 col-3 pl0">
								<div>
									<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 22) ? (
																										
				<div style={styletmp.bg_box} className="div-mystore-template22 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
					</div>
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-nonveg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div> 
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3">
								<div>
									<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
							<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-6 col-6">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 23) ? (
																													
				<div style={styletmp.bg_box} className="div-mystore-template23 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-veg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>                                                        
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-3 col-3 pl0">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-4">
					<div style={styletmp.bg_img}></div>
					</div>
				</div>
				</div>

			) : (prod.template_id === 24) ? (
																																
				<div style={styletmp.bg_box} className="div-mystore-template24 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-4 col-4">
						<div style={styletmp.bg_img}></div>
					</div>
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-nonveg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div> 
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
							<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-6 col-6">
								<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 25) ? (
																																			
				<div style={styletmp.bg_box} className="div-mystore-template25 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-nonveg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-9 col-9">
							<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
						</div>
					</div>
					<div className="col-md-4 col-4">
					<div><img src={prod.product_image} alt="noimage"/></div>
						<div>
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
						</div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 26) ? (
																																						
				<div style={styletmp.bg_box} className="div-mystore-template26 box_bg">
				{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-4 col-4 pr0">
					<div><img src={prod.product_image} alt="noimage"/></div>
						<div>
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
						</div>
					</div>
					<div className="col-md-8 col-8">
						<div className="df">
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-9 col-9 text-right">
							<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
							</div>
						</div>
					</div>
				</div>
				</div>

		) : (prod.template_id === 27) ? (
																																								
			<div style={styletmp.bg_box} className="div-mystore-template27 box_bg">
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-veg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6">
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-3 col-3 pl0">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
						{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
						}
					</div>
					<div className="col-md-4 col-4">
						<div style={styletmp.triangleright} className="triangle-right"></div>
						<div className="div-back-img" style={styletmp.bg_img}></div>													
					</div>
				</div>
			 </div>

			) : (prod.template_id === 28) ? (

			<div style={styletmp.bg_box} className="div-mystore-template28 box_bg">
				<div className="row">
					<div className="col-md-4 col-4">
						<div style={styletmp.triangleleft} className="triangle-left"></div>
						<div className="div-back-img" style={styletmp.bg_img}></div>
					</div>
					<div className="col-md-8 col-8">
					{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					}
						<div className="df">
							
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
							<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-6 col-6 pr0">
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 29) ? (

				<div style={styletmp.bg_box} className="div-mystore-template29 box_bg">
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-veg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
										{prod.moreinfo_status === 1 &&
											<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
										}
							</h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6">
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
										<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-3 col-3 pl0">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
						{prod.offer_text !== null &&
							<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					    }
					</div>
					<div className="col-md-4 col-4">
						<div className="triangle-right" style={styletmp.triangleright29}></div>
						<div className="div-back-img" style={styletmp.bg_img}></div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 30) ? (

			<div style={styletmp.bg_box} className="div-mystore-template30 box_bg">
			<div className="row">
				<div className="col-md-4 col-4">
					<div className="triangle-left"  style={styletmp.triangleleft30}></div>
					<div className="div-back-img" style={styletmp.bg_img}></div>
				</div>
				<div className="col-md-8 col-8">
				{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					}
					<div className="df">
						<div className="div-vegnonveg div-nonveg">
							<div></div>
						</div>
						<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
									{prod.moreinfo_status === 1 &&
										<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
									}
						</h3>
					</div>
					
					<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
					<div className="row">
						<div className="col-md-3 col-3 pl0">
							<div>
							<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
							</div>
						</div>
						<div className="col-md-3 col-3 pl0">
						{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
						}
						</div>
						<div className="col-md-6 col-6 pl0">
						<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
						</div>
					</div>
				</div>
				</div>
			</div>

		) : (prod.template_id === 31) ? (
																																				
			<div style={styletmp.bg_box} className="div-mystore-template31 box_bg">
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">

							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
									{prod.moreinfo_status === 1 &&
										<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
									}
						</h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6 pl0">
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-3 col-3 pl0">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
						{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					}
					</div>
					<div className="col-md-4 col-4">
						<div style={styletmp.triangleright31} className="triangle-right"></div>
						<div className="div-back-img" style={styletmp.bg_img}></div>
					</div>
				</div>
			</div>

		) : (prod.template_id === 32) ? (
																																						
			<div style={styletmp.bg_box} className="div-mystore-template32 box_bg">
				<div className="row">
					<div className="col-md-4 col-4">
						<div style={styletmp.triangleleft32} className="triangle-left"></div>
						<div className="div-back-img" style={styletmp.bg_img}></div>
					</div>
					<div className="col-md-8 col-8">
					{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					}
						<div className="df">
							<div className="div-vegnonveg div-nonveg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
								{prod.moreinfo_status === 1 &&
									<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
								}
						   </h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3 pl0">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-6 col-6 pl0">
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

		) : (prod.template_id === 33) ? (
																																								
			<div style={styletmp.bg_box} className="div-mystore-template33 box_bg">
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-veg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
								{prod.moreinfo_status === 1 &&
									<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
								}
						   </h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6">
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-3 col-3 pl0">
								<div>
									<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
						{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					}
					</div>
					<div className="col-md-4 col-4">
						<div style={styletmp.triangleright31} className="triangle-right"></div>
						<div className="div-back-img" style={styletmp.bg_img}></div>
					</div>
				</div>
			</div>

		) : (prod.template_id === 34) ? (
																																										
			<div style={styletmp.bg_box} className="div-mystore-template34 box_bg">
			<div className="row">
				<div className="col-md-4 col-4">
					<div style={styletmp.triangleleft32} className="triangle-left"></div>
					<div className="div-back-img" style={styletmp.bg_img}></div>
				</div>
				<div className="col-md-8 col-8">
				{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					}
					<div className="df">
						<div className="div-vegnonveg div-nonveg">
							<div></div>
						</div>
						<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
								{prod.moreinfo_status === 1 &&
									<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
								}
							</h3>
					</div>
					<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
					<div className="row">
						<div className="col-md-3 col-3 pl0">
							<div>
							<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
							</div>
						</div>
						<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
						</div>
						<div className="col-md-6 col-6 pr0">
						<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
						</div>
					</div>
				</div>
			</div>
			</div>

		) : (prod.template_id === 35) ? (
																																												
			<div style={styletmp.bg_box} className="div-mystore-template35 box_bg">
				{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					}
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-nonveg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
								{prod.moreinfo_status === 1 &&
									<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
								}
							</h3>
						</div>
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6">
							<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
							</div>
							<div className="col-md-3 col-3">
							{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
						</div>
					</div>
					<div className="col-md-4 col-4">
					<div><img src={prod.product_image} alt="noimage"/></div>
						<div>
						<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
						</div>
					</div>
				</div>
			</div>

			) : (prod.template_id === 36) ? (
																																															
				<div style={styletmp.bg_box} className="div-mystore-template36 box_bg">
					{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
					}
					<div className="row">
						<div className="col-md-4 col-4 pl0">
						<div><img src={prod.product_image} alt="noimage"/></div>
							<div>
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
						<div className="col-md-8 col-8">
							<div className="df">
								<div className="div-vegnonveg div-nonveg">
									<div></div>
								</div>
								<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
								{prod.moreinfo_status === 1 &&
									<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
								}
							</h3>
							</div>
							
							<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
							<div className="row">
								<div className="col-md-3 col-3 pl0">
								{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
								}
								</div>
								<div className="col-md-9 col-9 text-right">
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
					</div>
				</div>

		) : (prod.template_id === 37) ? (
																																																	
			<div style={styletmp.bg_box} className="div-mystore-template37 box_bg">
				{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-veg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
								{prod.moreinfo_status === 1 &&
									<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
								}
							</h3>
						</div>                                                        
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6">
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
							<div className="col-md-3 col-3 pl0">
								{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
								}
							</div>
							<div className="col-md-3 col-3 pl0">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-4">
					<div className="div-back-img" style={styletmp.bg_img}></div>
					</div>
				</div>
			</div>

		) : (prod.template_id === 38) ? (
																																																			
			<div style={styletmp.bg_box} className="div-mystore-template38 box_bg">
				{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-4 col-4">
					<div className="div-back-img" style={styletmp.bg_img}></div>
					</div>
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-nonveg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
								{prod.moreinfo_status === 1 &&
									<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
								}
							</h3>
						</div> 
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
							<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-6 col-6">
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

		) : (prod.template_id === 39) ? (
																																																					
			<div style={styletmp.bg_box} className="div-mystore-template39 box_bg">
				{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-veg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
								{prod.moreinfo_status === 1 &&
									<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
								}
							</h3>
						</div>                                                        
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-6 col-6">
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
							<div className="col-md-3 col-3 pl0">
							{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-3 col-3 pl0">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-4 col-4">
					<div className="div-back-img" style={styletmp.bg_img}></div>
					</div>
				</div>
			</div>

		) : (prod.template_id === 40) ? (
																																																							
			<div style={styletmp.bg_box} className="div-mystore-template40 box_bg">
				{prod.offer_text !== null &&
						<div style={styletmp.offer_text_col} className="div-offer-txt offer_text"> {prod.offer_text}</div>
				}
				<div className="row">
					<div className="col-md-4 col-4">
					<div className="div-back-img" style={styletmp.bg_img}></div>
					</div>
					<div className="col-md-8 col-8">
						<div className="df">
							<div className="div-vegnonveg div-nonveg">
								<div></div>
							</div>
							<h3 className="header_txt20" title={prod.product_name} style={styletmp.product_name_col}>{prod.name} 
								{prod.moreinfo_status === 1 &&
									<button type="button" style={styletmp.btn_col_info} onClick={(e) => this.props.openModal(prod.id)} className="info"><i className="fa fa-info-circle"></i></button>
								}
							</h3>
						</div> 
						
						<p style={styletmp.desc_color} className="prod_desc">{prod.description}</p>
						<div className="row">
							<div className="col-md-3 col-3">
								<div>
								<span style={styletmp.amt_color} className="prod_amt">{prod.amount.amt_symbol}</span>
								</div>
							</div>
							<div className="col-md-3 col-3 pr0">
							{prod.star_rating !== null &&
									<div style={styletmp.rating_color} className="star_box rating_bg"><i className="fa fa-star" ></i> {prod.star_rating}</div>
							}
							</div>
							<div className="col-md-6 col-6">
							<button style={styletmp.btn_col} onClick={() => this.props.addCart(prod)} className="btn-myfront-add prod_btn">{langv.add_cart}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			) : (

				<div></div>

			)
			}
			</div>
	
		);
	}
}


export default Template1;
import React from 'react';
import {render} from 'react-dom';
//import Main from './components/Main';
import Passcode from './components/Passcode';
import PasscodeCookie from './components/PasscodeCookie';
import ViewRewards from './components/ViewRewards';
import Giftpage from './components/Giftpage';
import MyProfile from './components/MyProfile';
import MyProduct from './components/MyProduct';
import Category from './components/Category';
import Checkout from './components/Checkout';
import Myorders from './components/Myorders';
import RewardCash from './components/RewardCash';
import GiftList from './components/GiftList';
/* import ChildCategory from './components/ChildCategory'; */

//import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store';
//import {connect} from 'react-redux';
//import { BrowserRouter, Route, Link,Redirect } from "react-router-dom";
import { BrowserRouter,Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css';
//import './css/qrcode.css';
import {Helmet} from "react-helmet";
//import Script from "react-inline-script"
//import {connect} from 'react-redux';
//var QRCode = require('qrcode.react');
import InnerHTML from 'dangerously-set-html-content';
import { api_url } from './config';
//import 'font-awesome/css/font-awesome.min.css';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class App extends React.Component {
	 
  constructor() {
      super();
      this.state = {
        storename:"",
        storeid:'',
        retar:'',
        themecolor:'',
        langv1:''
		

      };
	  this.updateStoreName = this.updateStoreName.bind(this);
	  this.updateRetarget = this.updateRetarget.bind(this);
	  //this.StoreNameChange('test');
  }
  
  componentDidMount() {
		   //const store_id = this.props.match.params.id;
		 //console.log(this.props);
		 
		 
  
   }
  changeUsername(newName){
		const token ='Karthick';
	    localStorage.setItem('token', token);
		this.setState({
            strorename: newName
        });
  }
  updateStoreName(storename){
		this.setState({
				storename:storename
		});
	
  }
  logincheck(type){
	   
	 var loginmode = localStorage.getItem('loginmode');
	 var login_url = localStorage.getItem("login_url");
	
	   if(type === 'islogin'){
		   if (loginmode === 'no' || loginmode === null) {
			  // const qrcode_url = this.fetchstoreurl(store_id,prod_id,type);
			   if(login_url !== null){
					window.top.location.href=login_url;
			   }else{
				    const postdata = new FormData();
					const store_id = this.match.params.store_id;
					const prod_id = this.match.params.prod_id;
					postdata.append("store_id", store_id);
					postdata.append("type", type);
					postdata.append("prod_id", prod_id);
					  fetch(api_url+"/api/fetchstoreurl",{
							method: "POST",
							body: postdata
					  })
					  .then(res => res.json())
					  .then(
						(result) => {
						  localStorage.setItem('loginmode','no');
						 if(result.qrcode_url !== ''){  
							window.top.location.href=result.qrcode_url;
						 } 
							
						},
						
						(error) => {
						  
						}
					  )
				   
				   
			   }	
			   
			   
		   }
	  }else if(type === 'logout'){
		  //alert(login_url);
		  localStorage.setItem('loginmode','no');
		   window.top.location.href=login_url;
	  }	   
		   
	  
  }	   
  fetchstoreurl(store_id,prod_id,type){
	  
	const postdata = new FormData();
	postdata.append("store_id", store_id);
	postdata.append("type", type);
	postdata.append("prod_id", prod_id);
	  fetch(api_url+"/api/fetchstoreurl",{
			method: "POST",
			body: postdata
	  })
	  .then(res => res.json())
	  .then(
		(result) => {
		 
		 return result.qrcode_url;
		/* if(result.qrcode_url !== ''){  
			window.top.location.href=result.qrcode_url;
		} */
			
		},
		
		(error) => {
		  
		}
	  )
	  
  } 
  
  
  updateRetarget(id,type){
		/* this.setState({
				storeid:store_id,
				load_script:'<script>alert("OK");</script>'
		});
		console.log("mrk_ttet"+store_id); */
		var loginmode = localStorage.getItem('loginmode'); 
		var langv = localStorage.getItem('langv');
		var lmode ='';
		if(loginmode === 'yes'){
			if(langv === null || langv === '' || typeof langv === 'undefined'){
				lmode ='no';
			}else{
				lmode ='yes';
			}
		}else{
			lmode ='no';
		}
		if(loginmode === null){
			loginmode = 'no';
		}	
		const postdata = new FormData();
		postdata.append("id", id);
		postdata.append("type", type);
		postdata.append("loginmode", lmode);
		  fetch(api_url+"/api/fetchretargeting",{
				method: "POST",
				body: postdata
		  })
		  .then(res => res.json())
		  .then(
			(result) => {
			 
			  this.setState({
				//storeid:store_id,
				retar:result,
				themecolor:JSON.parse(result.theme),
				
			  });
			  //console.log(result.theme);
			 //console.log(this.state.themecolor.login_body_color);
			
			if(loginmode === 'yes'){
				document.body.style.backgroundColor = this.state.themecolor.body_color;  
			}else{
				if(result.lang !== ''){
					localStorage.setItem('langv', result.lang);
					this.setState({langv1:JSON.parse(result.lang) })
				}
				document.body.style.backgroundColor = this.state.themecolor.login_body_color; 
			}
			if(result.sbet !== ''){  
				var script = document.createElement("script");
				script.type="text/javascript";
				script.innerHTML=result.siht;
				document.getElementsByTagName('head')[0].appendChild(script);
			}
				
			},
			
			(error) => {
			  
			}
		  )
		
  }
  handleChange(value) {
	    
		/* store.dispatch({
			  type:'L',
			  payload:100
		});  */
		this.setState({
            //isPasscode: store.getState().isPasscode
            isPasscode: 1
        });
   }	
  render(){
	 // const storename =this.state.storename;
	 // const storename = store.getState();
	 //console.log(storename);
	  //let storename =''
	  
	 //const langv = JSON.parse(localStorage.getItem('langv'));
	  //library.add(faCoffee);
      return ( 
		
		<BrowserRouter>
		
	    <Provider store={store}>
		<div>
			<Helmet>
                <meta charSet="utf-8" />
				{this.state.retar.tab_title && <title>{this.state.retar.tab_title}</title> }
              
				<script src="//ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js" type="text/javascript"></script>
				
				{this.state.retar.favicon &&
					<link rel="icon" type="image/x-icon" href={this.state.retar.favicon} />
				}
				
				{this.state.retar.ogtitle && <meta name="og:title" content={this.state.retar.ogtitle} />}
				{this.state.retar.ogtitle && <meta itemprop="name" content={this.state.retar.ogtitle} />}
           
				{this.state.retar.ogdes  && <meta property="og:description" content={this.state.retar.ogdes} />}
				{this.state.retar.ogdes  && <meta itemprop="description" content={this.state.retar.ogdes} />}

				{this.state.retar.ogimage  && <meta itemprop="image" content={this.state.retar.ogimage} />}
				{this.state.retar.ogimage  && <meta property="og:image" content={this.state.retar.ogimage} />}
				
				
            </Helmet>	
			{this.state.retar.sbbt  && <InnerHTML html={this.state.retar.sbbt} />}
			 
			<Route exact path={`${process.env.PUBLIC_URL}/:id`} render={(props) => <Passcode  {...props} updateRetarget={this.updateRetarget} updateStoreName={this.updateStoreName} /> } />
			

			
			<Route exact path={`${process.env.PUBLIC_URL}/product/:id/:prod_id?`} render={(props) => <Passcode  {...props} updateRetarget={this.updateRetarget}  updateStoreName={this.updateStoreName} /> } />
			
			
			
			
			<Route path={`${process.env.PUBLIC_URL}/login/:store_id/:prod_id/:storename/:rkey`} render={(props) => <PasscodeCookie  {...props}  updateRetarget={this.updateRetarget} langv ={this.state.langv1} themecolor={this.state.themecolor} logincheck={this.logincheck} /> } />
			
			<Route  exact path={`${process.env.PUBLIC_URL}/myprofile/:prod_id/:store_id`}   render={(props) => <MyProfile  {...props}  updateRetarget={this.updateRetarget} themecolor={this.state.themecolor} logincheck={this.logincheck} /> } />
			
			<Route  exact path={`${process.env.PUBLIC_URL}/category/:store_id/:prod_id/:category_id?`}   render={(props) => <Category  {...props}  updateRetarget={this.updateRetarget} themecolor={this.state.themecolor} logincheck={this.logincheck} /> } />
			
			<Route  exact path={`${process.env.PUBLIC_URL}/myproduct/:store_id/:prod_id/:category_id?`}   render={(props) => <MyProduct  {...props}  updateRetarget={this.updateRetarget} themecolor={this.state.themecolor} logincheck={this.logincheck} /> } />
			
			<Route  exact path={`${process.env.PUBLIC_URL}/checkout/:store_id/:prod_id`}   render={(props) => <Checkout  {...props}  updateRetarget={this.updateRetarget} themecolor={this.state.themecolor} logincheck={this.logincheck} /> } />
			<Route  exact path={`${process.env.PUBLIC_URL}/myorders/:store_id/:prod_id`}   render={(props) => <Myorders  {...props}  updateRetarget={this.updateRetarget} themecolor={this.state.themecolor} logincheck={this.logincheck} /> } />

			<Route  exact path={`${process.env.PUBLIC_URL}/gifts/:store_id/:prod_id`}   render={(props) => <GiftList  {...props}  updateRetarget={this.updateRetarget} themecolor={this.state.themecolor} logincheck={this.logincheck} /> } />
			
			<Route  exact path={`${process.env.PUBLIC_URL}/rewardcash/:prod_id/:store_id`}   render={(props) => <RewardCash  {...props}  updateRetarget={this.updateRetarget} themecolor={this.state.themecolor} logincheck={this.logincheck} /> } />
			<Route exact path={`${process.env.PUBLIC_URL}/viewrewards/:store_id/:prod_id`}   render={(props) => <ViewRewards  {...props}  updateRetarget={this.updateRetarget} themecolor={this.state.themecolor} logincheck={this.logincheck} /> } />
			
			<Route exact path={`${process.env.PUBLIC_URL}/g/:gift_id`} render={(props) => <Giftpage  {...props} updateRetarget={this.updateRetarget} /> } />
			
			
			
			{this.state.retar.sbet && <InnerHTML html={this.state.retar.sbet} />}
		
		</div>
		</Provider>
		</BrowserRouter>
		
			
      );

  }

}



/* 
<Route exact path={`${process.env.PUBLIC_URL}/samtest`} component={Samtest}></Route>
<Route exact path="/:id" render={(props) => <Passcode  {...props} updateStoreName={this.updateStoreName} /> } />

const mapStateToProps = (state) => {
	return {
		storename:state.passcode.storename
		
	}	
	
}
const mapDispatchToProps = (dispatch) => {
	return {
		StoreNameChange:(storename)=>{
			dispatch({type:'storename',storename})
		},	
		
	}	
	
}
export default connect(mapStateToProps,mapDispatchToProps)(App);  */

/*  const mapStateToProps = (state) => {
	return {
		passcodeMode:state.passcode.passcodeMode
	}	
	
}

export default connect(mapStateToProps,null)(App); */ 

/* 
{props.isPasscode == 1 ? (
				<Qrcode  />
			  ) : (
				<Passcode />
			  )}
 <span>isLoggedIn {store.getState().isLoggedIn}</span>
<button onClick={() => this.namechange()}>+Add</button>
<button style={{display:'none'}} onClick={() => this.handleChange(1)}>+Add</button>
<QRCode value="http://facebook.github.io/react/" />,
<Route path="/main" render={(props) => getToken() ? <Main {...props} /> : <Redirect to={{ pathname: '/test' }} />} />
<Route
      {...rest}
      render={(props) => !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/dashboard' }} />}
    /> */
//https://stackblitz.com/edit/login-app-reactjs?file=Utils%2FPublicRoute.js	
/* 
  <Main   changeUsername={this.changeUsername.bind(this)} />
ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
); */
render(<App />, window.document.getElementById('root'));
export default App;
 
// import { createStore } from 'redux';
// const initialState={
  // result:1,
  // lastValue:[]

// }
// const reducer = (state=initialState,action) => {

  // switch (action.type) {
    // case 'ADD':
      // state={
          // ...state,
          // result : state.result + action.payload,
          // lastValue:[...state.lastValue,action.payload]
      // }
     
      // break;
    // case 'SUBTRACT':
      // state={
        // ...state,
        // result : state.result - action.payload,
        // lastValue:[...state.lastValue,action.payload]
      // }
       // break;  
    
  // }
  // return state;

// };

// const store=createStore(reducer);
// store.subscribe(() => {

  // console.log("Store Updates",store.getState());

// });
// store.dispatch({
  
  // type:'ADD',
  // payload:100
  
// });
// store.dispatch({
  
  // type:'ADD',
  // payload:22
  
// });
// store.dispatch({
  
  // type:'SUBTRACT',
  // payload:80
  
// });
import React from "react";
import { api_url } from '../config';
import  '../css/checkout.css';
import 'font-awesome/css/font-awesome.min.css';
import Menu from '../components/Menu';
class Checkout extends React.Component {
	constructor(props) {
		
		super(props);
		this.props.logincheck('islogin');
		const store_id = localStorage.getItem("store_id");
		const loginmode_sess = localStorage.getItem('loginmode');
		var cartlistlocal_sess = localStorage.getItem('cartlistlocal');
		var cartlistlocal = JSON.parse(cartlistlocal_sess);
		var loginmode;
		if(loginmode_sess === null){
			loginmode = 'no';
		}else{
			loginmode = loginmode_sess;
		}
		this.props.updateRetarget(store_id, 'reward');
		this.state = {
			loginmode:loginmode,
			cart:cartlistlocal,
			purchase_amt:[],
			payment_type: '',
			buynow_mode:true,
			integration: [],
			my_vat:'' ,
			vat_perc:'' ,
			vat_perc_amt:'' ,
			total_amt:[] ,
			sum:'',
			wallet_amt:[],
			redeem_checked: false,
			balacneamt: 0,
			
		}
		this.paymentType = this.paymentType.bind(this);
		this.buynow = this.buynow.bind(this);
		this.addPointsRedeem =  this.addPointsRedeem.bind(this);
		this.goBack = this.goBack.bind(this);
		//cartlistlocal = JSON.parse(cartlistlocal_sess);

	}
	addPointsRedeem(){
		const store_id = localStorage.getItem("store_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		let api_url1;
		const redeem_checked = !this.state.redeem_checked
		this.setState({redeem_checked: redeem_checked})
		
		const cart = this.state.cart;
		let sum = cart.reduce(function(prev, current) {
		 // if(typeof current.amt !== 'undefined' && typeof current.quantity !== 'undefined' && typeof prev !== 'undefined'){
			  let amtv = current.amt * current.quantity;
			  return prev + amtv
		 // }
		}, 0);
		api_url1 = api_url + "/api/addptsredeem";
		fetch(api_url1, {
			 method: 'POST',
			 body: JSON.stringify({
				fk_store_id: store_id,
				fk_member_id: fk_member_id,
				sum: sum,
				redeem_checked: redeem_checked,
				reward_cash: this.state.wallet_amt.amount,
			 }),
			 headers: {
				'Content-type': 'application/json; charset=UTF-8',
			 },
		})
		.then(res => res.json())
		.then(
			(result) => {
				if (result.status === 200) {
					this.setState({
						 total_amt: result.total_amt,
						 purchase_amt:result.purchase_amt,
						 used_pts:result.used_pts,
						 balance_amt:result.balance_amt,
					}) 
					
					 if (typeof result.my_vat !== "undefined" && typeof result.vat_perc !== 'undefined'){
						 
						   this.setState({
							 my_vat: result.my_vat,
							 vat_perc: result.vat_perc,
							 vat_perc_amt: result.vat_perc_amt,
						 })   
						
					 }
					
				} 
			},
			(error) => {

			}

		) 
	}	
	buynow(cartlocal){
		
		const store_id = localStorage.getItem("store_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		var login_url = localStorage.getItem("login_url");
		//const postdata = new FormData();
		//postdata.append("fk_store_id", store_id);
		//postdata.append("fk_member_id", fk_member_id);
		//postdata.append("cartlocal", cartlocal);
		/* const purchase_amt =this.state.purchase_amt;
		purchase_amt.amt_symbol ="$10";
		this.setState({  purchase_amt: purchase_amt, })
		return; */		
	
		   
		let api_url1;
		console.log(1);
		api_url1 = api_url + "/api/buynow";
		fetch(api_url1, {
			 method: 'POST',
			 body: JSON.stringify({
				cart: cartlocal,
				fk_store_id: store_id,
				fk_member_id: fk_member_id,
				payment_type: this.state.payment_type,
				purchase_amt: this.state.purchase_amt.amount,
				rurl: login_url,
				balance_amt: this.state.balance_amt
				
			 }),
			 headers: {
				'Content-type': 'application/json; charset=UTF-8',
			 },
		})
		.then(res => res.json())
		.then(
			(result) => {
				if (result.status === 200) {
					  localStorage.removeItem('cartlistlocal');
					window.top.location.href=result.payment_url;
				} else {
					
				}
			},
			(error) => {

			}

		) 
	}
	paymentType(paymenttype){
		this.setState({
		  payment_type: paymenttype,
		  buynow_mode: false,
	  })
	}
	goBack() {
		this.props.history.goBack();
	} 
	componentDidMount() {
		const store_id = localStorage.getItem("store_id");
		const fk_member_id = localStorage.getItem("fk_member_id");
		const postdata = new FormData();
		var login_url = localStorage.getItem("login_url");
		const cart = this.state.cart;
		let sum;
		if(cart !== '' && typeof cart !== "undefined" && cart !== null){
			sum = cart.reduce(function(prev, current) {
		 // if(typeof current.amt !== 'undefined' && typeof current.quantity !== 'undefined' && typeof prev !== 'undefined'){
			  let amtv = current.amt * current.quantity;
			  return prev + amtv
		 // }
			}, 0);
		}else{
			
			sum = 0;
			window.top.location.href=login_url;
		}
		
		this.setState({
			sum: sum,
		})

		postdata.append("fk_store_id", store_id);
		postdata.append("fk_member_id", fk_member_id);
		postdata.append("sum", sum);
		let api_url1;
		api_url1 = api_url + "/api/checkout_page";
		fetch(api_url1, {
			method: "POST",
			body: postdata
		})
		.then(res => res.json())
		.then(
			(result) => {
				if (result.status === 200) {
					  this.setState({
						  integration: result.integration,
						  total_amt: result.total_amt,
						  purchase_amt: result.purchase_amt,
						  wallet_amt: result.wallet_amt,
						  my_vat:0
					  })

					  if (typeof result.my_vat !== "undefined" && typeof result.vat_perc !== 'undefined') {
						   this.setState({
							     my_vat: result.my_vat,
							     vat_perc: result.vat_perc,
							     vat_perc_amt: result.vat_perc_amt,
						})   
						
					  } 
					  console.log(this.state.my_vat);
					  
				} else {
					
				}


			},
			(error) => {

			}

		) 
		

	}
	
	

	
	render() {
		const cart = this.state.cart;
		const getcartlocal = localStorage.getItem('cartlistlocal');
		const themecolor = this.props.themecolor;
		/* let sum = cart.reduce(function(prev, current) {
		 // if(typeof current.amt !== 'undefined' && typeof current.quantity !== 'undefined' && typeof prev !== 'undefined'){
			  let amtv = current.amt * current.quantity;
			  return prev + amtv
		 // }
		}, 0);  */
		//alert(cart)
		
		const langv = JSON.parse(localStorage.getItem('langv'));
		let cartlen = 0;
		if(cart !== '' && typeof cart !== "undefined" && cart !== null){
			cartlen  = cart.length - 1;
		}
		
		
		const stylesv2 = {
			ms_btn: {
				backgroundColor: themecolor.btn_bg_color,
				color: themecolor.btn_text_color,
				border: "1px solid " + themecolor.btn_bg_color,
			},
			ms_textcol: {
				color: themecolor.text_color,
			},
		
			bshadow: {
				border: "1px solid " +themecolor.text_color,
				boxShadow: "0px 0px 5px 0px "+themecolor.text_color,
			},
			plan_content:{
				background:"#fff",
				border: "1px solid #fff"
			},
		};	
		return (
		<div>
			
					
					<div>
						<Menu themecolor={this.props.themecolor} logincheck={this.props.logincheck} />
					
					</div>
					
			<div className="container">
			<span onClick={this.goBack} style={stylesv2.ms_textcol} className="back_cls prod_theme"><i className="fa fa-angle-double-left" aria-hidden="true"></i>&nbsp;{langv.back}</span>
			<div className="row m_top2">
				<div className="col-md-4">
					<div style={stylesv2.bshadow} className="div-card">
						<div className="row">
							<div className="col-md-4  col-4 mauto">
								<img src={api_url+"/public/img/integfe/shield.png"} alt="noimage" className="img-cart" />
							</div>
							<div className="col-md-8 div-desc">
								<h4 style={stylesv2.ms_textcol}>{langv.secure_processing}</h4>
								<p style={stylesv2.ms_textcol}>{langv.bit_encrption}</p>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-8">
					<div style={stylesv2.bshadow} className="div-right-card">
					
					    {this.state.my_vat === 0 &&
							<div className="row">
								<div className="col-md-12">
									<h4 style={stylesv2.ms_textcol}>{langv.payment_details}</h4>
								</div>
								
								
								<div className="col-md-7">
									<strong style={stylesv2.ms_textcol}>{langv.total} ( <span>{cartlen} {langv.items} )</span></strong>
								</div>
								
								<div style={stylesv2.ms_textcol} className="col-md-5 text-right">
									<strong>{this.state.purchase_amt.amt_symbol}</strong>
								</div>
								
							</div>
						}

						{this.state.my_vat !== 0 &&
							<div className="row">
								<div className="col-md-12">
									<h4 style={stylesv2.ms_textcol}>{langv.payment_details}</h4>
								</div>
								
								
								<div className="col-md-7">
									<strong style={stylesv2.ms_textcol}>{langv.price} ( <span>{cartlen} {langv.items} )</span></strong>
								</div>
								
								<div style={stylesv2.ms_textcol} className="col-md-5 text-right">
									<strong>{this.state.total_amt.amt_symbol}</strong>
								</div>
								
								
								<div className="col-md-7">
									<strong  style={stylesv2.ms_textcol}>{langv.vat}<span> ({this.state.vat_perc}%)</span></strong>
									 
								</div>
								<div className="col-md-5 text-right">
									<strong style={stylesv2.ms_textcol}>{this.state.vat_perc_amt}</strong>
								</div>
								
								<div className="col-md-7">
									<strong style={stylesv2.ms_textcol}><span>{langv.total}</span></strong>
								</div>
								
								<div style={stylesv2.ms_textcol} className="col-md-5 text-right">
									<strong>{this.state.purchase_amt.amt_symbol}</strong>
								</div>
								
								
							</div>
						}
						{this.state.wallet_amt.amount !== 0 &&
						<div className="col-md-12 m_top2 nopadd">
							<div className="form-check">
								  <input onChange={() => this.addPointsRedeem()}   defaultChecked ={this.state.redeem_checked} className="form-check-input" type="checkbox" value="1" id="flexCheckDefault" />
								  <label style={stylesv2.ms_textcol} className="form-check-label" htmlFor="flexCheckDefault">
									{langv.use_reward_cash} {this.state.wallet_amt.amt_symbol}
								  </label>
								 
							</div>
							 <p style={stylesv2.ms_textcol} className="free_checkout">({langv.free_checkout_not_allowed})</p>
						</div>
						}
						
						<div className="row plans">
							<div className="col-md-12">
								<h4 style={stylesv2.ms_textcol}>{langv.payment_options}</h4>
							</div>
								{this.state.integration.map((integ,index) => (
								<div className="col-md-6 m_top1" key={"paymennt_"+index}>
									<label className="plan complete-plan mb-2">
										<input onClick={()=>{this.paymentType(integ.api_type)}} type="radio" value={integ.api_type} className="pay_btn_cls" id="sc-chk-st" name="plan" />
										<div style={stylesv2.plan_content} className="plan-content">
											<img src={api_url+"/public/img/integfe/"+integ.api_type+'.png'} alt="noimage" className="img-fluid" />
										</div>
									</label>

								</div>
								))}
								
								<div className="col-md-12 m_top2">
									<button className="btn-payment" onClick={()=>{this.buynow(getcartlocal)}} disabled={this.state.buynow_mode} style={stylesv2.ms_btn} type="button">{langv.buy_now}</button>
								</div>
							
							
							
						</div>
					</div>
				</div>
			</div>
			</div>

		</div>



		);
	}
}


export default Checkout;
